import { Box } from "@mui/material";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition } from "tabulator-tables";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type TableAlertsProps = {
  autoResize: boolean;
  limitRow?: number;
  alerts: any;
  timeframe: any;
};

const nameFormatter = (cell) => {
  const data = cell.getData();

  return `${data.name} (${data.ticker})`;
};

const moversFormatter = (cell, field: "moverUp" | "moverDown") => {
  const cssClassMoversDown = "tTableLists-moversDown";
  const cssClassMoversUp = "tTableLists-moversUp";
  const data = cell.getData();
  const mover = data["mv"];

  if (mover != null && mover !== 0) {
    if (
      (field === "moverUp" && mover < 0) ||
      (field === "moverDown" && mover > 0)
    ) {
      return "";
    } else {
      if (mover < 0) {
        return (
          '<span class="i-triangle-down ' + cssClassMoversDown + '"></span>'
        );
      } else
        return '<span class="i-triangle-up ' + cssClassMoversUp + '"></span>';
    }
  }

  return "";
};

const TIMEFRAME_DICT = {
  daily: 0,
  weekly: 4,
  monthly: 19,
};

const upgradesDowngradesFormatter = (
  cell,
  formatter,
  field: "up" | "down",
  timeframe
) => {
  const data = cell.getData();

  let formatAsRating = true;

  if (data["rrr"] && data["rc"]) {
    const isUpgrade =
      data?.["rrr"] < data?.["rc"] && data.lr <= TIMEFRAME_DICT[timeframe];
    const isDowngrade =
      data?.["rrr"] > data?.["rc"] && data.lr <= TIMEFRAME_DICT[timeframe];

    if ((field === "up" && !isUpgrade) || (field === "down" && !isDowngrade)) {
      formatAsRating = false;
    }
  } else {
    return "";
  }

  if (formatAsRating) {
    const tcr = [
      formatter.custom("rating", {
        options: {
          notAvailable: {
            input: 0,
            output: "-",
          },
        },
        output: "HTML",
        value: data["rrr"],
        valueHelper: {
          rateChange: null,
          rateDate: null,
          ratePrev: null,
        },
      }),
      '<span class="i-arrow-r"></span>',
      formatter.custom("rating", {
        options: {
          notAvailable: {
            input: 0,
            output: "-",
          },
        },
        output: "HTML",
        value: data["rc"],
        valueHelper: {
          rateChange: null,
          rateDate: null,
          ratePrev: null,
        },
      }),
    ].join(" ");

    return tcr;
  }

  return "";
};

const tcrSorter = (aRow, bRow, timeframe, field: "down" | "up") => {
  const aData = aRow.getData();
  const bData = bRow.getData();
  const aRC = aData?.rc ?? -9999;
  const bRC = bData?.rc ?? -9999;
  let ratingOrder = field === "up" ? 1 : -1;

  const isAlertA =
    (field === "up" ? aRC > aData.rrr : aRC < aData.rrr) &&
    aData.lr <= TIMEFRAME_DICT[timeframe];
  const isAlertB =
    (field === "up" ? bRC > bData.rrr : bRC < bData.rrr) &&
    bData.lr <= TIMEFRAME_DICT[timeframe];

  if (isAlertA && !isAlertB) {
    return 1;
  }

  if (isAlertB && !isAlertA) {
    return -1;
  }

  if (isAlertA) {
    if (aRC === bRC) {
      return aData.lr < bData.lr ? 1 : -1;
    } else {
      return aRC > bRC ? ratingOrder : -ratingOrder;
    }
  } else {
    return aRC > bRC ? ratingOrder : -ratingOrder;
  }
};

const moverSorter = (a, b, field: "up" | "down") => {
  const order = field === "up" ? 1 : -1;

  return a > b ? order : -order;
};

const TableAlertsComponent = memo(function TableAlertsComponent({
  autoResize,
  limitRow,
  alerts,
  timeframe,
}: TableAlertsProps) {
  const { t } = useTranslation();
  const formatter = useFormatter();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(alerts);
  }, [alerts]);

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const columnsDefinition: ColumnDefinition[] = useMemo(
    () => [
      {
        field: "name",
        title: t("name"),
        resizable: false,
        widthGrow: 3,
        headerHozAlign: "left",
        hozAlign: "left",
        cssClass: "no-col-borders",
        formatter: nameFormatter,
        sorter: "string",
      },
      {
        field: "rc",
        cssClass: "no-col-borders clickable",
        formatter: (cell) =>
          upgradesDowngradesFormatter(cell, formatter, "up", timeframe),
        title: t("upgrades"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        sorter: (a, b, aRow, bRow) => tcrSorter(aRow, bRow, timeframe, "up"),
      },
      {
        field: "rc",
        cssClass: "no-col-borders clickable",
        formatter: (cell) =>
          upgradesDowngradesFormatter(cell, formatter, "down", timeframe),
        title: t("downgrades"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        sorter: (a, b, aRow, bRow) => tcrSorter(aRow, bRow, timeframe, "down"),
      },
      {
        headerHozAlign: "center",
        cssClass: "no-col-borders",
        hozAlign: "center",
        formatter: (cell) => moversFormatter(cell, "moverUp"),
        resizable: false,
        title: "Movers up",
        field: "mv",
        sorter: (a, b) => moverSorter(a, b, "up"),
      },
      {
        headerHozAlign: "center",
        cssClass: "no-col-borders clickable",
        hozAlign: "center",
        field: "mv",
        formatter: (cell) => moversFormatter(cell, "moverDown"),
        resizable: false,
        title: "Movers down",
        sorter: (a, b) => moverSorter(a, b, "down"),
      },
    ],
    [formatter, t, timeframe]
  );
  const tableData = useMemo(() => {
    if (limitRow != null && limitRow !== 0) {
      return data?.slice(0, limitRow) ?? null;
    } else {
      return data;
    }
  }, [data, limitRow]);
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
        movableColumns: false,
        columns: columnsDefinition,
        defaultSort: { field: "name", direction: "asc" },
      },
      tableTooltipParams: {
        enabled: true,
      },
    };
  }, [columnsDefinition]);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(tableData);
    }
  }, [tableData, tableReady]);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
    }),
    []
  );
  //#endregion

  return (
    <>
      {tableData ? (
        <>
          {tableData.length ? (
            <Box display={"flex"} height={"100%"} minHeight={0}>
              <TrendratingTableV2
                rowTooltipFormatter
                ref={tableRef}
                tableEvents={tableEvents}
                tableOptions={tableOptions}
              />
            </Box>
          ) : null}
        </>
      ) : (
        <p>No relevant events were found for the selected period</p>
      )}
      {limitRow != null && data?.length > limitRow ? (
        <p className="see-more-alerts-portfolio-analysis">See more</p>
      ) : null}
    </>
  );
});

export { TableAlertsComponent };
