/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/widgets/app-infrastructure/workflowBar/actions/export/Export
 * @summary Workflow action: generate a CSV with the given content
 */

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import {
  extractForDataIngestion,
  extractSymbols,
} from "../../../../../../../api/compute/commons";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import { Rankings } from "../../../../../../../api/compute/Rankings";
import Modal from "../../../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Formatter } from "../../../../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../../../../types/Defaults";
import { CSV } from "../../../../../utils/SingletonCSV";

type ExportProps = {
  label?: string;
  title?: string;
  widgets: any;
  list?: any;
  fileName: string;
  rankingCache: any;
  logFunction: Function;
};

type UseExportProps = {
  label?: string;
  title?: string;
  widgets: any;
  list?: any;
  fileName: string;
  rankingCache: any;
  logFunction: Function;
};

export class ExportAction {
  widgets: any;
  list: any;
  rankingCache: any;
  fileName: any;
  environment: AppEnvironment;
  formatter: Formatter;

  constructor({
    widgets,
    list,
    rankingCache,
    fileName,
    environment,
  }: {
    widgets: any;
    list?: any;
    rankingCache?: any;
    fileName: any;
    environment: AppEnvironment;
  }) {
    this.widgets = widgets;
    this.list = list;
    this.rankingCache = rankingCache;
    this.fileName = fileName;
    this.environment = environment;
    this.formatter = new Formatter(environment);
  }

  prepareParams() {
    var params = {
      constraints: this.widgets["table"].get("constraints"),
      list: this.list,
      pagination: this.widgets["table"].get("pagination"),
      sortBy: this.widgets["table"].get("sortBy"),
      avgRow: this.widgets["table"].get("avgRow"),
    };

    var properties: any = [];
    var columns = this.widgets["table"].get("columns");
    for (let i = 0, length = columns.length; i < length; i++) {
      properties.push({
        date: null,
        property: columns[i]["property"],
      });
    }

    var prepared = {
      params: params,
      properties: properties,
    };

    return prepared;
  }

  async csv(response) {
    var againstList =
      this.rankingCache != null
        ? this.rankingCache["rankingParams"]["againstList"]
        : null;
    var data = response["data"];
    var datum: any = null;
    var properties = this.widgets["table"].get("columns");
    var propertiesRanking = {};
    var propertiesRankingCounter = 0;
    var property: any = null;
    var columnProperties: any = [];
    var line: any = [];
    var lines: any = [];
    var rankingRules =
      this.rankingCache != null
        ? this.rankingCache["rankingParams"]["rules"]
        : null;

    var propertyRank = RANKING_PROPERTY;
    var propertyRankDelta = RANKING_PROPERTY_DELTA;
    var propertyRankFromDate = RANKING_PROPERTY_FROM_DATE;
    var propertyRankList = RANKING_PROPERTY_LIST;

    // header
    for (let i = 0, length = properties.length; i < length; i++) {
      property = properties[i];
      columnProperties.push(property["property"]);
      line.push('"' + property["label"] + '"');
      // map to help to match which rule generate this result
      if (/^rankValue/.test(property["property"])) {
        propertiesRanking[property["property"]] = propertiesRankingCounter;
        propertiesRankingCounter++;
      }
    }
    lines.push(line);
    // data
    for (let i = 0, lengthI = data.length; i < lengthI; i++) {
      datum = data[i];
      line = [];
      for (var j = 0, lengthJ = columnProperties.length; j < lengthJ; j++) {
        property = columnProperties[j];

        if (/^rank/.test(property)) {
          if (datum[property] != null) {
            if (property === propertyRank) {
              line.push(
                '"' +
                  this.formatter.rankingRule(property, null, datum, "TEXT") +
                  '"'
              );
            } else if (property === propertyRankDelta) {
              line.push(
                '"' +
                  this.formatter.rankingRule(property, null, datum, "TEXT") +
                  '"'
              );
            } else if (property === propertyRankFromDate) {
              const value = String(datum[propertyRankFromDate] + 1);
              line.push('"' + value + '"');
            } else if (property === propertyRankList) {
              let value = "";
              if (datum[property] != null) {
                const weightInAgainstList =
                  againstList?.positionsToday?.[datum?.symbol]?.weight;
                var rank = datum[propertyRank] + 1;
                var weight = this.formatter.text(
                  "weight",
                  "weight",
                  weightInAgainstList,
                  null,
                  datum["type"]
                );

                if (
                  againstList != null &&
                  againstList["type"] === "PORTFOLIO"
                ) {
                  value = [rank, " (", weight, ")"].join("");
                } else {
                  value = String(rank);
                }
              }
              line.push('"' + value + '"');
            } else {
              line.push(
                '"' +
                  this.formatter.rankingRule(
                    property,
                    rankingRules[propertiesRanking[property]],
                    datum,
                    "TEXT"
                  ) +
                  '"'
              );
            }
          } else {
            line.push('""');
          }
        } else {
          // 2019-01-28 because of import
          if (property === "country" && datum[property].length === 2) {
            line.push('"' + datum[property] + '"');
          } else {
            line.push(
              '"' +
                this.formatter.text(
                  property,
                  "table",
                  datum[property],
                  datum,
                  datum?.["type"] ?? "stock"
                ) +
                '"'
            );
          }
          // 2019-01-28 because of import
        }
      }
      lines.push(line);
    }

    var _fileName = this.fileName == null ? DEFAULT_FILE_NAME : this.fileName;
    await CSV.create(lines, _fileName);

    //   //********************** USAGE *************************
    //   var usage = window.App.usage;
    //   var info = {
    //     action: "EXPORT",
    //     actionParam: null,
    //     function: "SCREENING",
    //   };
    //   usage.record(info);
    //   //********************** USAGE *************************
  }

  addAvgRow(params, fields) {
    const avgRow = params?.avgRow ?? null;
    let rowToAdd: any = {};

    if (avgRow) {
      for (const col of fields) {
        if (col.property in avgRow) {
          rowToAdd[col.property] = avgRow[col.property];
        } else {
          rowToAdd[col.property] = undefined;
        }
      }
      return rowToAdd;
    }
    return undefined;
  }

  async exportAction() {
    try {
      const preparedParams = this.prepareParams();

      const exportCollector = new ExportUtility(this.environment);

      const data = await exportCollector.dataExport(
        preparedParams["params"],
        preparedParams["properties"],
        this.rankingCache
      );

      const rowToAdd = this.addAvgRow(
        preparedParams["params"],
        preparedParams["properties"]
      );

      if (rowToAdd) {
        data["data"].push(rowToAdd);
      }

      await this.csv(data);
    } catch (error) {
      console.log(error);
    }
  }
}

export const useExport = ({
  widgets,
  list,
  rankingCache,
  fileName,
  logFunction,
}: UseExportProps) => {
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);

  const exportBuilder = useMemo(
    () =>
      new ExportAction({
        widgets,
        list,
        rankingCache,
        fileName,
        environment: setup,
      }),
    [fileName, list, rankingCache, setup, widgets]
  );

  const exportAction = useCallback(async () => {
    try {
      setWarning(false);
      setLoading(true);

      await exportBuilder.exportAction();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [exportBuilder]);

  const doAction = useCallback(() => {
    if (logFunction) {
      logFunction();
    }

    var dataTotalCount = widgets["table"].get("dataTotalCount");
    if (dataTotalCount > RANKING_CARDINALITY) {
      setWarning(true);
    } else {
      exportAction();
    }
  }, [exportAction, logFunction, widgets]);

  return { doAction, warning, setWarning, loading, exportAction };
};

const DEFAULT_FILE_NAME = "trendrating-export.csv";
const RANKING_CARDINALITY = 3000;
const RANKING_PROPERTY = "rank";
const RANKING_PROPERTY_DELTA = "rankDelta";
const RANKING_PROPERTY_FROM_DATE = "rankFromDate";
const RANKING_PROPERTY_LIST = "rankList";

export function Export({
  label = "Export",
  title = "Export to MS Excel",
  widgets,
  list,
  rankingCache,
  fileName,
  logFunction,
}: ExportProps) {
  const { doAction, warning, setWarning, loading, exportAction } = useExport({
    widgets,
    list,
    rankingCache,
    fileName,
    logFunction,
  });

  const closeWarnModal = useCallback(() => {
    setWarning(false);
  }, [setWarning]);

  return (
    <>
      <ExportActionLoader show={loading} />
      <WarningExportModal
        show={warning}
        onClose={closeWarnModal}
        exportCallback={exportAction}
      />
      <li className="menu__item" title={title} onClick={doAction}>
        {label}
      </li>
    </>
  );
}

class ExportUtility {
  environment: AppEnvironment;
  listsAPI: Lists;
  rankingsAPI: Rankings;
  instrumnetsAPI: Instruments;

  constructor(environment: AppEnvironment) {
    this.environment = environment;
    this.listsAPI = new Lists(environment);
    this.rankingsAPI = new Rankings(environment);
    this.instrumnetsAPI = new Instruments(environment);
  }

  /**
   * Like dataGet but alterate pagination to rows
   *
   * http["rankings"]["RANKING_CARDINALITY"]
   */
  async dataExport(params, properties, ranking) {
    var _params = deepClone(params);
    _params["pagination"]["page"] = 1;

    // Export is forced to 3000 maximum
    _params["pagination"]["rows"] = 3000;

    return this.dataGet(_params, properties, ranking);
  }

  /**
   * Get instruments belong to the ranking
   *
   * IMPORTANT: at the time of writing (2020-04-01)
   *
   * params.constraints and params.list are mutually exclusive
   *
   * @param {object}   params - instruments params
   * @param {object}   params.constraints - the ranking target instruments
   *      constraints. It is null if params.list != null
   * @param {object}   params.list - the ranking target list. It is null
   *      if params.constraints != null
   *
   * @param {object}   params.pagination - pagination
   * @param {number}   params.pagination.page - the page to retrieve
   * @param {number}   params.pagination.rows - the number of items per
   *      page
   *
   * @param {object}   params.sortBy - sorting criteria
   * @param {boolean}  params.sortBy.descending - is sorting descending
   * @param {string}   params.sortBy.property - sorting property
   *
   * @param {object[]} properties - properties to retrieve
   * @param {number}   properties.date - date. Used for point in time
   *      purposes.
   * @param {string}   params.properties.property - property
   *
   * @param {object}   rankingCache - the ranking cache
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async dataGet(params, properties, rankingCache) {
    var constraints = deepClone(params["constraints"]);
    var list = deepClone(params["list"]);
    var pagination = deepClone(params["pagination"]);
    var sortBy = deepClone(params["sortBy"]);

    // If there is no sort set, use rank as default
    // Below logic will manage rank in case there is an existing rankingCache, or not
    // rank does also have an automatic fallback for existing columns
    if (sortBy == null) {
      sortBy = {
        property: "rank",
        descending: false,
      };
    }

    var sortProperty = sortBy["property"];

    var _params = constraints == null ? {} : constraints;
    if (list != null) {
      // Check for back compatibility with old system because the posisitons were expected to be already in params
      if (!("positions" in list)) {
        const listId = list.id;
        const apiLists = this.listsAPI;
        const listPositionsResponse = await apiLists.portfolioFetch(
          [listId],
          ["positionsToday"]
        );

        list["positions"] = listPositionsResponse?.[0]?.positionsToday ?? [];

        const positionsIndexMap = list["positions"].reduce(
          (prev, current, index) => {
            prev[current.symbol] = index;

            return prev;
          },
          {}
        );

        list["positionsIndex"] = positionsIndexMap;
      }

      var symbolsHoldings = extractSymbols(list["positions"]);

      if (!("filters" in _params)) {
        _params["filters"] = [];
      }

      _params["filters"].push({
        dimension: "symbol",
        segments: symbolsHoldings,
      });
    }

    _params["page"] = pagination;
    _params["sort"] = [
      {
        dimension: sortProperty,
        rev: sortBy["descending"],
      },
    ];

    // Add a second sort level
    if (sortProperty !== "marketcap" && sortProperty !== "rank") {
      _params["sort"].push({ dimension: "marketcap", rev: true });
    }

    if (params["date"] != null) {
      _params["date"] = params["date"];
    }

    var sortPropertyId: any = null;

    if (rankingCache != null) {
      //
      // 2020-06-03 Dirty patch for justInTimeTops
      //
      // if the ranking cache is enable, justInTimeTops MUST BE removed
      // It only need at the first stage of ranking process, when the
      // domain has to definined
      //
      if (constraints != null && "justInTimeTops" in constraints) {
        delete constraints["justInTimeTops"];
      }
      //
      // 2020-06-03 end
      //
      var data = rankingCache["rankingResult"]["data"];
      var symbols = extractSymbols(data);

      if (!("filters" in _params)) {
        _params["filters"] = [];
      }

      // universe is the ranking
      _params["filters"].push({
        dimension: "symbol",
        segments: symbols,
      });
      // #region ------------------------------------------------ sort
      _params["sort"] = [
        {
          dimension: sortProperty,
          rev: sortBy["descending"],
        },
      ];
      var symbolsAndRank: any = null;
      var symbolsAndWeight: any = null;

      // multisort: ranking as second dimension
      if (!/^rank/.test(sortProperty) && sortProperty !== "rank") {
        symbolsAndRank = extractForDataIngestion(data, "symbol", "rank");

        sortPropertyId = "rank" + new Date().getTime() + ":rank";

        _params["sort"].push({
          dimension: sortPropertyId,
          rev: false,
        });

        _params["injestion"] = {
          data: symbolsAndRank,
          field: sortPropertyId,
          type: "number",
        };
      }

      // sort by ranking columns
      if (/^rank/.test(sortProperty)) {
        symbolsAndRank = extractForDataIngestion(data, "symbol", sortProperty);

        sortPropertyId = "rank" + new Date().getTime() + ":" + sortProperty;

        _params["sort"][0]["dimension"] = sortPropertyId;

        // Add MarketCap as second sort level if any sort was selected
        _params.sort.push({ dimension: "marketcap", rev: true });

        _params["injestion"] = {
          data: symbolsAndRank,
          field: sortPropertyId,
          type: "number",
        };
      }

      // sort by aginstList
      if (sortProperty === this.rankingsAPI["RANKING_PROPERTY_LIST"]) {
        symbolsAndRank = extractForDataIngestion(data, "symbol", "rank");

        var datum: any = null;
        symbolsAndWeight = [];

        for (var i = 0, length = symbolsAndRank.length; i < length; i++) {
          datum = symbolsAndRank[i];
          if (
            !(
              datum["symbol"] in
              rankingCache["rankingParams"]["againstList"]["positionsIndex"]
            )
          ) {
            // enables grouping while sorting
            datum["value"] = "100000";
          }
          symbolsAndWeight.push(datum);
        }

        sortPropertyId = "rank" + new Date().getTime() + ":" + sortProperty;

        _params["sort"][0]["dimension"] = sortPropertyId;

        _params["injestion"] = {
          data: symbolsAndWeight,
          field: sortPropertyId,
          type: "number",
        };
      }
      // #endregion ------------------------------------------------------
    } else {
      // No ranking data, adjust input data if ranking values are used
      // For example remove rank sort if there is no ranking
      // #region ---------------------------------------------------- sort
      if (/^rank/.test(sortProperty)) {
        // Fallback to marketcap if available
        for (let i = 0; i < properties.length; i++) {
          var property = properties[i];
          if (property["property"] === "marketcap") {
            _params["sort"] = [
              {
                dimension: "marketcap",
                rev: true,
              },
            ];
          }
        }
        // If there is no marketcap, don't send sort parameter
        delete _params["sort"];
      }
      // #endregion ------------------------------------------------------
    }

    // manging weight for lists
    //
    // 2020-09-01
    // contribution and performance for systematic portfolio holdings
    //
    if (
      list != null &&
      (sortProperty === "contribution" ||
        sortProperty === "contributionCurrency" ||
        sortProperty === "contributionMarket" ||
        sortProperty === "performance" ||
        sortProperty === "performanceCurrency" ||
        sortProperty === "weight")
    ) {
      sortPropertyId =
        list["id"] + ":" + new Date().getTime() + ":" + sortProperty;

      _params["sort"] = [
        {
          dimension: sortPropertyId,
          rev: sortBy["descending"],
        },
      ];

      symbolsAndWeight = extractForDataIngestion(
        list["positions"],
        "symbol",
        sortProperty
      );
      _params["injestion"] = {
        data: symbolsAndWeight,
        field: sortPropertyId,
        type: "number",
      };
    }

    try {
      const response = await this.instrumnetsAPI.newFilterAndFetch(
        _params,
        "security",
        properties
      );
      // Update list to insert postions and positions index key if are added during data get
      params.list = list;
      return this.rankingDataGet(params, rankingCache, response);
    } catch (error) {
      return console.log(error);
    }
  }

  rankingDataGet(params, rankingCache, response) {
    var index: any = null;
    var instrument: any = null;

    var list = params["list"];
    if (list != null) {
      // merging weights
      var holdings = list["positions"];
      var holdingsIndex = list["positionsIndex"];

      for (var i = 0, length = response["data"].length; i < length; i++) {
        instrument = response["data"][i];
        index = holdingsIndex[instrument["symbol"]];
        instrument["weight"] = holdings[index]["weight"];

        // Merge additional contribution/performance
        // Property must exists, set as null on instrument, and available on holdings to set
        if (
          instrument["contribution"] === null &&
          holdings[index]["contribution"] != null
        ) {
          instrument["contribution"] = holdings[index]["contribution"];
        }
        if (
          instrument["contributionCurrency"] === null &&
          holdings[index]["contributionCurrency"] != null
        ) {
          instrument["contributionCurrency"] =
            holdings[index]["contributionCurrency"];
        }
        if (
          instrument["contributionMarket"] === null &&
          holdings[index]["contributionMarket"] != null
        ) {
          instrument["contributionMarket"] =
            holdings[index]["contributionMarket"];
        }
        if (
          instrument["performance"] === null &&
          holdings[index]["performance"] != null
        ) {
          instrument["performance"] = holdings[index]["performance"];
        }
        if (
          instrument["performanceCurrency"] === null &&
          holdings[index]["performanceCurrency"] != null
        ) {
          instrument["performanceCurrency"] =
            holdings[index]["performanceCurrency"];
        }
      }

      // ...
      // can be merged other properties, e.g. point in time and
      // strategy contributions
    }

    if (rankingCache != null) {
      // merging ranking properties
      var ranking = rankingCache["rankingResult"];
      const indexMap = ranking.data.reduce((prev, current, index) => {
        prev[current.symbol] = index;

        return prev;
      }, {});
      for (let i = 0; i < response["data"].length; i++) {
        const instrument = response["data"][i];
        const index = indexMap[instrument["symbol"]];
        const augmentation = ranking["data"][index];

        for (var key in augmentation) {
          instrument[key] = augmentation[key];
        }
      }
    }

    return response;
  }
}

export class TableWrapper {
  filter: any;
  table: any;
  tools: any;

  constructor(params) {
    this.filter = params["filter"];
    this.table = params["grid"];
    this.tools = params["tools"];
  }

  get(property) {
    switch (property) {
      case "columns": {
        var _column: any = null;
        var _columns: any = this.table.get("columns");
        var column: any = null;
        var columns: any = [];

        for (let i = 0, length = _columns.length; i < length; i++) {
          _column = _columns[i];
          if (_column["field"] !== undefined && _column["field"] != null) {
            column = {
              label: _column["label"]
                .replace(/<br\/>/gi, " - ")
                .replace(/<br>/gi, " "),
              property: _column["field"],
            };
            columns.push(column);
          }
        }

        return columns;
      }
      case "filter" /* DEPRECATED: use constraints */:
      case "constraints": {
        return this.filter != null ? this.filter.get("value") : null;
      }
      case "dataTotalCount": {
        return this.tools.paginator.get("total");
      }
      case "page" /* DEPRECATED: use pagination */:
      case "pagination": {
        return this.tools.get("page");
      }
      case "sort" /* DEPRECATED: use sortBy */:
      case "sortBy": {
        return this.table.get("sortCriteria");
      } // no default
    }
  }
}

export const ExportActionLoader = ({ show }) => {
  return show ? (
    <Modal closeIcon={false}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        p={1}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <CircularProgress sx={{ color: "#2a7090" }} />
          <Typography>Generating export file</Typography>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};

export const WarningExportModal = ({
  show,
  onClose,
  exportCallback,
  hasExportBtn = true,
}: {
  show: boolean;
  onClose: () => void;
  exportCallback?: () => void;
  hasExportBtn?: boolean;
}) => {
  return show ? (
    <Modal
      headerConfig={{ hasBackground: true, headerContent: "Warning" }}
      onClose={onClose}
      closeIcon={false}
    >
      <Box p={1}>
        <Typography>
          Will be exported only the first {RANKING_CARDINALITY} securities.
        </Typography>
      </Box>
      <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
        {hasExportBtn && (
          <Button variant="contained" onClick={exportCallback}>
            Export
          </Button>
        )}
        <Button variant="tr_button_cancel" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
