type StateType = {
  basic: {
    autoRebalance: boolean;
    benchmark: any;
    currency: any;
    name: any;
    strategyId: any;
    expenseRatio: any;
    orderThreshold: any;
    granularity: string;
  };
  id: any;
  priceLevel: {
    initialValue: number;
    isBacktestingInherited: boolean;
    isBacktestingInheritedDate: any;
  };
  todayList: {
    cash: any;
    list: any;
    strategy: any;
  };
};
export const initState: StateType = {
  basic: {
    autoRebalance: false,
    benchmark: null,
    currency: null,
    name: null,
    strategyId: null,
    expenseRatio: null,
    orderThreshold: null,
    granularity: "MONTHLY",
  },
  id: null,
  priceLevel: {
    initialValue: 0,
    isBacktestingInherited: true,
    isBacktestingInheritedDate: null,
  },
  todayList: {
    cash: null,
    list: null,
    strategy: null,
  },
};

type ActionType = {
  type:
    | "INIT"
    | "SET_NAME"
    | "SET_BENCHMARK"
    | "SET_CURRENCY"
    | "SET_EXPENSE_RATIO"
    | "SET_ORDER_THRESHOLD"
    | "SET_STRATEGY"
    | "SET_AUTOREBALANCE";
  value: any;
};

export const editContentReducer = (draft: StateType, action: ActionType) => {
  switch (action.type) {
    case "INIT":
      const product = action.value;
      draft.basic.name = product.name;
      draft.basic.benchmark = product.benchmark;
      draft.basic.currency = product.currency;
      draft.basic.strategyId = product.strategyId;
      draft.basic.expenseRatio = product.expenseRatio;
      draft.basic.orderThreshold = product.orderThreshold;
      draft.basic.autoRebalance = product.autorebalance;
      draft.id = product.id;
      draft.priceLevel.initialValue = product.inceptionValue;
      break;
    case "SET_NAME":
      draft.basic.name = action.value;
      break;
    case "SET_CURRENCY":
      draft.basic.currency = action.value;
      break;
    case "SET_EXPENSE_RATIO":
      draft.basic.expenseRatio = action.value;
      break;
    case "SET_ORDER_THRESHOLD":
      draft.basic.orderThreshold = action.value;
      break;
    case "SET_BENCHMARK":
      draft.basic.benchmark = action.value;
      break;
    case "SET_STRATEGY":
      draft.basic.strategyId = action.value;
      break;
    case "SET_AUTOREBALANCE":
      draft.basic.autoRebalance = action.value;
      break;
  }
};
