import { Mutex } from "../../../Utility/Mutex";
import { Analytics } from "../../../api/compute/Analytics/Analytics";
import { Lists } from "../../../api/compute/Lists";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { Formatter } from "../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../types/Defaults";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import { widgetsConfiguration } from "../widgets/widgetsConfiguration";
import {
  CombineEntitiesEngine,
  CombinedStrategiesStorage,
  LoadingCallbacks,
} from "./CombinedStrategiesStorage";

export class CombinedProductsStorage
  extends CombinedStrategiesStorage
  implements CombineEntitiesEngine
{
  smsApi: SystematicProducts;
  listsAPI: Lists;
  formatter: Formatter;
  mutex: Mutex;
  products: any;

  constructor(
    environment: AppEnvironment,
    loadingBehaviours: LoadingCallbacks
  ) {
    super(environment, loadingBehaviours);

    const appSetup = environment;
    this.smsApi = new SystematicProducts(appSetup);
    this.listsAPI = new Lists(appSetup);
    this.formatter = new Formatter(appSetup);
    this.mutex = new Mutex();
    this.products = undefined;

    this.ITEM_TYPE = "COMBINED_PRODUCT";
  }

  async getFinestGranularity() {
    const ids: any = [];

    for (const en of this.entities) {
      ids.push(en.id);
    }

    let granularities = await this.smsApi.fetch({
      ids,
      properties: ["reviewGranularity"],
    });

    const GRANULARITY_ENCODER = {
      WEEKLY: 1,
      MONTHLY: 2,
      QUARTERLY: 3,
    };

    const GRANULARITY_RESOLVER = {
      1: "WEEKLY",
      2: "MONTHLY",
      3: "QUARTERLY",
    };

    granularities = granularities.map(
      (result) => GRANULARITY_ENCODER[result["reviewGranularity"]]
    );

    const granularityId = Math.min(...granularities);

    const granularity = GRANULARITY_RESOLVER[granularityId];

    return granularity;
  }

  public async prepareEntitiesForCombine() {
    const weightsMap = {};
    const systematicProducts: { type: "SMS"; entity: any }[] = [];
    this.products = [];

    for (const [i, productInfo] of this.entities.entries()) {
      if (productInfo.id) {
        weightsMap[i] = productInfo.weight;
      }

      try {
        const systematicProduct = await this.smsApi.getById(productInfo.id);

        if (systematicProduct) {
          this.products.push(systematicProduct);
          systematicProducts.push({
            type: "SMS",
            entity: systematicProduct,
          });
        }
      } catch (error) {
        console.error(error);
        const errorDetails = {
          status: 500,
          errorDetails: { code: "UNIVERSE_EMPTY" },
        };
        throw errorDetails;
      }
    }

    const firstEntity: any = {
      type: "COMBINE_ENTITIES",
      entity: {
        entitiesToCombine: systematicProducts,
        combineParams: {
          currency: this.combineParams?.currency ?? "local",
          date: undefined,
          allocation: weightsMap,
        },
      },
      includeFromDay: undefined,
    };

    let benchmarkEntity: any = undefined;

    if (this.combineParams && this.combineParams.benchmark) {
      benchmarkEntity = await this.fromBenchmarkToEntitySMS(
        this.combineParams.benchmark
      );
    }

    this.analyticsCollector = await Analytics.initialize(
      this.environment,
      firstEntity,
      benchmarkEntity
    );
  }

  public async getAnalytics(
    tab: StrategyComputedTabType,
    startDate?,
    endDate?,
    period?
  ): Promise<any> {
    const response = await super.getAnalytics(tab, startDate, endDate, period);

    (this.loadingCallbacks as any).stopPreload();
    return response;
  }

  protected async fromBenchmarkToEntitySMS(benchmarkTag: string): Promise<any> {
    const params = this.combineParams;

    const granularity = await this.getFinestGranularity();

    if (benchmarkTag.startsWith(this.BLENDED_TAG)) {
      const explodedTag = benchmarkTag.split(":");
      const portfolioId = explodedTag[1];

      const list = await this.apiLists.get(parseInt(portfolioId));

      this.benchmarkInfo = { name: list?.name ?? "", symbol: benchmarkTag };

      const entity = {
        portfolio: list,
        params: {
          startDate: undefined,
          inceptionDay: undefined,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
        },
      };

      return { type: "LIST", entity };
    }

    try {
      const response = await this.apiInstruments.fetch({
        symbols: [benchmarkTag],
        type: "security",
        properties: [{ date: null, property: "name" }],
      });

      const instrumentName = response?.data?.[0]?.name ?? "";

      this.benchmarkInfo = { name: instrumentName, symbol: benchmarkTag };
    } catch (error) {
      console.log(error);
    }

    return {
      type: "INSTRUMENT",
      entity: {
        symbol: benchmarkTag,
        params: {
          startDate: undefined,
          inceptionDay: undefined,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
          currency: params?.currency,
        },
      },
    };
  }

  async holdings() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.combineParams?.currency ?? "local";
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const snapshot = await this.snapshot();

    const paramsAllocationAt = {
      cutoff: "last",
      date: lastAllocation?.d,
      listHistory: { POS: listHistory, currency },
      product: { id: null },
    };

    let startIndex: any = null;
    for (let i = 0, length = listHistory.length; i < length; i++) {
      if (listHistory[i]["d"] <= lastAllocation.d) {
        startIndex = i;
      }
    }
    if (startIndex == null) {
      startIndex = listHistory.length - 1;
    }

    const allocationAt = await this.smsApi.allocationAt(paramsAllocationAt);

    // snapshot holdings weight are actualized at
    // today
    const snapshotHoldingsMap = {};
    let holding: any = null;
    for (var i = 0, length = snapshot["positions"].length; i < length; i++) {
      holding = snapshot["positions"][i];
      snapshotHoldingsMap[holding["symbol"]] = holding;
    }
    // merging actualized weights with contributions
    // data
    const holdings: any = [];
    for (let i = 0; i < allocationAt["positions"].length; i++) {
      holding = allocationAt["positions"][i];
      // skip expired/unavailable
      if (holding["symbol"] in snapshotHoldingsMap) {
        holding["weight"] = snapshotHoldingsMap[holding["symbol"]]["weight"];

        holdings.push(holding);
      }
    }

    return { holdings };
  }

  async snapshot() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.combineParams?.currency ?? "local";

    let positionsToday: any = null;
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const postionsOfLastAllocation = lastAllocation.v.map((pos) => ({
      symbol: pos.S,
      weight: pos.A,
    }));
    const asOfDate = TDate.daysToIso8601(this.environment.today.today);
    const fromDate = TDate.daysToIso8601(lastAllocation.d);

    const responseWeights = await this.listsAPI._updateWeights(
      postionsOfLastAllocation,
      currency,
      fromDate,
      asOfDate
    );

    positionsToday = responseWeights.v;

    const paramsRatingAt = {
      adjustWeights: true,
      currency,
      equalWeighted: false,
      normalize: false,
      perfMetricMode: "active",
      v: positionsToday,
    };

    const response = await this.smsApi.ratingAt(paramsRatingAt);

    return response;
  }

  async info() {
    let products: any = null;
    let name = "";

    if (this.products && this.products.length) {
      products = [];

      for (const p of this.products) {
        products.push(deepClone(p));
      }

      const names = products.map((p) => p.name);

      name = names.join(" - ");
    }

    const combinedStrategy = {
      benchmark: this.combineParams?.benchmark,
      currency: this.combineParams?.currency,
      id: null,
      name,
      period: {
        type: this.combineParams?.period.period.type,
        value: this.combineParams?.period.period.value,
      },
      type: this.ITEM_TYPE,
    };

    for (let i = 0, N = this.entities.length; i < N; i++) {
      combinedStrategy[`product${i + 1}`] = this.entities[i];
    }

    return combinedStrategy;
  }

  async history() {
    const listHistory = await this.getCurve("H", "allocations");

    const data: any = {
      prepared: null,
      raw: {
        instruments: null,
        listHistory,
      },
    };

    const properties =
      widgetsConfiguration["widgets/analysis/collection/edit"]["properties"];

    let symbols: any = [];

    for (const POS of listHistory) {
      for (const allocation of POS.v) {
        symbols.push(allocation.S);
      }
    }

    // remove duplicates
    symbols = [...new Set(symbols)];

    try {
      const fetchResponse = await this.apiInstruments.fetch({
        properties: properties,
        type: "security",
        symbols: symbols,
      });

      data.raw.instruments = fetchResponse;
      const instruments = fetchResponse["data"];

      // active instruments
      let instrument: any = null;
      let patternExpired = /^Expired/gi;
      for (let i = 0, length = instruments.length; i < length; i++) {
        instrument = instruments[i];
        if (patternExpired.test(instrument["type"])) {
          instrument["status"] = "expired";
        } else {
          instrument["status"] = "active";
        }
      }

      instruments.sort(function (a, b) {
        var property = "name";

        if (a[property] > b[property]) {
          return 1;
        }

        if (a[property] < b[property]) {
          return -1;
        }

        return 0;
      });

      const storeInstruments = instruments;

      const getSync = (symbol) => {
        return storeInstruments.find((item) => item.symbol === symbol);
      };

      const addSync = (obect) => {
        storeInstruments.push(obect);
      };

      const putSync = (object) => {
        const objectIndex = storeInstruments.indexOf(object);

        storeInstruments[objectIndex] = object;
      };

      // unavailable instruments
      let unavailable: any = null;
      for (let symbol of symbols) {
        if (symbol !== "CASH" && getSync(symbol) === undefined) {
          unavailable = {
            name: symbol,
            status: "unavailable",
            symbol: symbol,
            ticker: "unavailable_" + symbol,
          };

          addSync(unavailable);
        }
      }

      const allocations = listHistory;
      let dateProperty: any = null;
      let dateProperties: any = [];
      let allocation: any = null;
      let allocationsStatus: any = {};
      // init weight value to 0
      for (let i = 0, lengthI = allocations.length; i < lengthI; i++) {
        allocation = allocations[i];
        dateProperty = "date_" + allocation["d"];
        dateProperties.push(dateProperty);
        for (var j = 0, lengthJ = instruments.length; j < lengthJ; j++) {
          instruments[j][dateProperty] = 0;
        }

        allocationsStatus[dateProperty] = {
          date: allocation["d"],
          status: "rebalanced",
        };

        if (allocation["creationDate"] !== allocation["modificationDate"]) {
          allocationsStatus[dateProperty]["status"] = "edited";
        }
      }

      // setting weights
      let symbol: any = null;
      for (let i = 0, lengthI = allocations.length; i < lengthI; i++) {
        allocation = allocations[i];
        for (let j = 0, lengthJ = allocation["v"].length; j < lengthJ; j++) {
          dateProperty = "date_" + allocations[i]["d"];
          symbol = allocation["v"][j]["S"];
          if (symbol !== "CASH") {
            instrument = getSync(symbol);

            instrument[dateProperty] = allocation["v"][j]["A"];

            putSync(instrument);
          }
        }
      }

      const prepared = {
        allocationLast:
          allocations.length > 0
            ? allocations[allocations.length - 1]["d"]
            : null,
        allocationsStatus: allocationsStatus,
        dateProperties,
        collection: storeInstruments,
        columns: null,
      };

      data["prepared"] = prepared;

      return { history: data };
    } catch (error) {
      console.log(error);
    }
  }
}
