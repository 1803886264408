import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TabTrends.module.scss";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import { widgetsConfiguration } from "../../../../../widgets/widgetsConfiguration";
import { extractSymbols } from "../../../../../../../api/compute/commons";
import { useParams } from "react-router-dom";
import { httpAll } from "../../../../../../../httpAll";
import { Chart } from "../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { scaleXY } from "../../../../../utils";
import { _rate } from "../../../../../../../trendrating/formatter/_rate";
import { v4 as uuidv4 } from "uuid";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";

type TabTrendsProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabTrends({ dataManager }: TabTrendsProps) {
  const [chartType, setChartType] = useState<
    "duration" | "magnitude" | "crossFrequences" | "trendsVsPerf"
  >("duration");
  const { t } = useTranslation();
  const environment = useEnvironment();
  const [emptyMessage, setEmptyMessage] = useState<any>();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const listsAPI = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );

  const urlParams = useParams();

  const currentPortfolioID = useMemo(() => urlParams.id, [urlParams.id]);

  const dataReady = useMemo(() => {
    const trends = data?.["trends"] ?? null;
    const positions = data?.["positions"] ?? null;

    return trends != null && positions != null;
  }, [data]);

  const positions = useCallback(async () => {
    if (portfolio.current.positionsToday != null) {
      return portfolio.current.positionsToday;
    }

    if (currentPortfolioID) {
      try {
        const response = await dataManager.get("trend");

        const positions = response.positions;

        portfolio.current.positionsToday = positions;

        return portfolio.current.positionsToday;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  }, [currentPortfolioID, dataManager]);

  const portfolio = useRef<{
    positionsToday: any[] | null;
    positions: typeof positions;
  }>({ positionsToday: null, positions });

  const menuOptions = useMemo(
    () => [
      { label: t("distribution_duration"), value: "duration" },
      { label: t("distribution_magnitude"), value: "magnitude" },
      { label: t("cross_frequencies"), value: "crossFrequencies" },
      { label: t("duration_vs_performance"), value: "trendsVsPerf" },
    ],
    [t]
  );

  const changeChatType = useCallback((value) => setChartType(value), []);

  const dataDisplay = useCallback(
    async (data) => {
      if (data) {
        if (data["abRatedCount"] === 0) {
          // if (portfolioType === "BASKET") {
          //   setEmptyMessage(
          //     `<h1 class='tAnalysisTrend-message'>${t(
          //       "feedback_the_basket_does_not_contain_securities_rated_A_or_B"
          //     )}</h1>`
          //   );
          // } else {
          setEmptyMessage(
            `<h1 class='tAnalysisTrend-message'>${t(
              "feedback_the_portfolio_does_not_contain_securities_rated_A_or_B"
            )}</h1>`
          );
          // }

          return;
        }

        setData(data);

        return;
      }

      setEmptyMessage(t("default_no_data"));
    },
    [t]
  );

  const dataPrepare = useCallback(
    async (response, what) => {
      const data: { positions: any; trends: any; abRatedCount: any } = {
        positions: null,
        trends: null,
        abRatedCount: 0,
      };
      if ("positions" in response) {
        data["positions"] = response["positions"]["data"];
      }

      if ("trends" in response) {
        data["trends"] = response["trends"]["data"];
      }

      // 2020-04-16
      //
      // If the list does not have any A/B rated holding, then
      // there are no curves to display.
      //
      // E.g. this case often happens during COVID-19 pandemic.
      //
      // The check below helps to find this case and makes the UI able
      // to show an informative message
      var abRatedCount = 0;
      var holdings = data["positions"];
      if (holdings != null) {
        for (let i = 0, length = holdings.length; i < length; i++) {
          if (holdings[i]["rc"] > 0) {
            abRatedCount = abRatedCount + 1;
          }
        }
      }

      if (abRatedCount > 0) {
        data["abRatedCount"] = abRatedCount;
      }

      await dataDisplay(data);
    },
    [dataDisplay]
  );

  const dataGet = useCallback(async () => {
    if (dataReady) {
      return;
    }

    setLoading(true);
    setEmptyMessage(undefined);

    try {
      const what = chartType;

      // if (list != null) {
      var requests = {};

      switch (what) {
        case "duration":
        case "magnitude": {
          requests["trends"] = listsAPI.distributions();

          break;
        }
        case "crossFrequences":
        case "trendsVsPerf": {
          break;
        }

        // no default
      }

      var properties =
        widgetsConfiguration["widgets/analysis/collection/duration-magnitude"][
          "properties"
        ];

      const positions = await portfolio.current.positions();

      if (positions) {
        var symbols = extractSymbols(positions);

        requests["positions"] = instrumentsAPI.fetch({
          properties: properties,
          symbols: symbols,
          type: "security",
        });

        if (Object.keys(requests).length > 0) {
          const response = await httpAll(requests);
          await dataPrepare(response, what);
        } else {
          await dataDisplay(null);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    chartType,
    dataDisplay,
    dataPrepare,
    dataReady,
    instrumentsAPI,
    listsAPI,
  ]);

  const onComponentMount = useCallback(() => {
    dataGet();
  }, [dataGet]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  const ChartComponent = withHelpers(Chart);

  return (
    <Box className={styles.main}>
      <Box className={styles.menu}>
        <ul className={styles.menu__list}>
          {menuOptions.map((item) => (
            <li
              key={uuidv4()}
              className={`${styles.menu__list__item} ${
                item.value === chartType ? styles.menu__list__item__active : ""
              }`}
              onClick={() => changeChatType(item.value)}
            >
              <Typography sx={{ fontSize: "0.8vw" }}>{item.label}</Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box className={styles.chart__box}>
        {emptyMessage != null ? (
          <div
            style={{ margin: "0 auto" }}
            dangerouslySetInnerHTML={{ __html: emptyMessage }}
          ></div>
        ) : (
          <Card className={styles.chart__box__card}>
            <CardContent className={styles.chart__box__card__content}>
              {loading ? (
                <Box
                  display={"flex"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress sx={{ color: "#2a7090" }} />
                    <Typography>Loading...</Typography>
                  </Box>
                </Box>
              ) : data != null ? (
                <ChartComponent data={data} what={chartType} />
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
}

const withHelpers = (Component) => {
  return ({ data, what }) => {
    const format = useFormatter();

    const colors = useMemo(
      () => ({
        A: "#008000",
        B: "#8BBC00",
        C: "#F48400",
        D: "#F00000",
      }),
      []
    );

    const rate = useMemo(
      () => ({
        "2": "A",
        "1": "B",
        "-1": "C",
        "-2": "D",
      }),
      []
    );

    const plotBands = useMemo(
      () => [
        {
          from: 25,
          to: 50,
          color: "rgba(68, 170, 213, 0.25)",
        },
        {
          from: 75,
          to: 100,
          color: "rgba(68, 170, 213, 0.25)",
        },
      ],
      []
    );

    const jsonToLine = useCallback((/*Array*/ entries) => {
      var serie: any = [];

      for (let i = 0, length = entries?.length; i < length; i++) {
        serie.push({
          x: entries[i].frequencyClass,
          y: entries[i].cumulativeGTPerc,
        });
      }

      serie.sort(function (a, b) {
        return a.x > b.x ? 1 : b.x > a.x ? -1 : 0;
      });

      return serie;
    }, []);

    const edgeCaseManager = useCallback((aValue, bValue) => {
      if (bValue > 95) {
        bValue = 95;
        var seed = scaleXY(Math.min(aValue, 2), 0, 2, 4, 0);
        var newValue = bValue + seed;
        return newValue;
      }
      return bValue;
    }, []);

    const pointsToflags = useCallback(
      (/*Array*/ points) => {
        var series: any = [];
        var data: any = [];
        var _colors: any = [];
        var rates: any = [];
        var point: any = null;
        var key: any = null;
        var rateLabel: any = null;
        var rate: any = null;
        for (let i = 0, length = points.length; i < length; i++) {
          point = points[i];

          if (
            point.security.rc === 0 ||
            point.security.rc == null ||
            point.x < 1
          )
            continue;

          rate = _rate.rating[point.security.rc];
          key = point.security.type + "s " + rate.label;
          if (!data[key]) {
            data[key] = [];
            rateLabel = rate.label;
            _colors[key] = colors[rateLabel];
            rates[key] = rateLabel;
          }

          data[key].push({
            x: point.x,
            y: point.y,
            name: point.security.ticker + " - " + point.security.name,
            security: point.security,
          });
        }

        var isVisible: any = null;
        let i = 10;
        for (const key in data) {
          data[key].sort(function (a, b) {
            return a.x > b.x ? 1 : b.x > a.x ? -1 : 0;
          });

          rateLabel = rates[key];
          isVisible = rateLabel === "A" || rateLabel === "B";
          series.push({
            type: "scatter",
            data: data[key],
            name: key,
            yAxis: 0,
            color: _colors[key],
            visible: isVisible,
            index: i,
          });
          i++;
        }
        return series;
      },
      [colors]
    );

    const onPointClick = useCallback((security) => {
      console.log(security);
    }, []);

    const fmtString = useCallback(
      (str: string, map: { [key: string]: string }) => {
        for (const key in map) {
          str = str.replace("${" + key + "}", map[key]);
        }

        return str;
      },
      []
    );

    /**
     * @param {object} data
     * @param {array}  data.positions - portfolio positions
     * @param {object} data.trends - trend distribution curves
     *
     * @param {object} options
     * @param {string} options.baseTrendKey - the key string to get the trend
     * @param {string} options.propertyX - property used to compute x value
     * @param {string} options.serieNameTemplateString - template string for legend
     */
    const dataPrepare = useCallback(
      (data, options) => {
        var distribution = data["trends"];
        var positions = data["positions"];

        var baseTrendKey = options["baseTrendKey"];
        var propertyX = options["propertyX"];
        var serieNameTemplateString = options["serieNameTemplateString"];

        for (const key in distribution) {
          if (!distribution[key]) {
            distribution[key] = {};
          }
          distribution[key].display = false;
        }

        var maxX = 0;
        var security: any = null;
        var key: any = null;
        var point: any = null;
        var points: any = [];
        var rateLabel: any = null;
        var x = 0;
        var y = 0;
        for (let i = 0, length = positions.length; i < length; i++) {
          security = positions[i];

          // not existing or low rating
          if (security.dc == null || security.rc <= 0) continue;

          switch (propertyX) {
            case "lt": {
              x = security[propertyX] / 20;
              y = edgeCaseManager(x, security["duration"] * 100);
              break;
            }
            case "ptx": {
              x = security[propertyX] * 100;
              y = edgeCaseManager(x, security["magnitude"] * 100);
              break;
            } // no default
          }

          point = {
            security: security,
            x: x,
            y: y,
          };
          points.push(point);

          key = baseTrendKey + security.rc + "_" + security.type;
          if (distribution[key]) {
            rateLabel = rate[security.rc];
            distribution[key].display = true;
            distribution[key].color = colors[rateLabel];
            distribution[key].rateLabel = rateLabel;
            distribution[key].rate = security.rc;
            distribution[key].type = security.type;
          }
          if (point.x > maxX) {
            maxX = Math.ceil(point.x);
          }
        }

        var series: any = [];
        var distSlice: any = null;
        var serie: any = null;
        var sTrend: any = null;
        var i = 1;
        for (const key in distribution) {
          distSlice = distribution[key];
          if (distSlice.display === true) {
            serie = jsonToLine(distSlice.distributionEntres);
            rateLabel = distSlice.rateLabel;
            sTrend = {
              type: "line",
              id: distSlice.rate + "_" + distSlice.type,
              name: fmtString(serieNameTemplateString, {
                rate: rateLabel,
                type: distSlice.type,
              }),
              data: serie,
              yAxis: 0,
              index: i,
              lineWidth: 4,
              color: distSlice.color,
              marker: { enabled: false },
              visible: rateLabel === "A" || rateLabel === "B",
            };
            series.push(sTrend);
            i++;
          }
        }

        data = series.concat(pointsToflags(points));

        return {
          maxX: maxX,
          series: data,
        };
      },
      [colors, edgeCaseManager, fmtString, jsonToLine, pointsToflags, rate]
    );

    const prepareTrendsVsPerfChartConfig = useCallback(() => {
      var charTitle = "Duration of Trends vs Performance";
      var maxX: any = 0;
      var point: any = null;
      var points: any = [];
      var securities = data["positions"];
      var security: any = null;

      for (let i = 0, length = securities.length; i < length; i++) {
        security = securities[i];

        // not existing or low rating
        if (security.dc == null || security.rc <= 0) continue;

        point = {
          security: security,
          x: security.lt / 20,
          y: security.pt * 100,
        };
        points.push(point);

        if (point.x > maxX) {
          maxX = Math.ceil(point.x);
        }
      }

      var series = pointsToflags(points);
      var Yaxys = [
        {
          title: { text: "Trend Performance" },
          type: "linear",
          labels: { format: "{value:.2f}%" },
        },
      ];
      var Xaxys = [
        {
          title: { text: "Trend Duration" },
          max: maxX,
          labels: { format: "{value}" },
        },
      ];

      return {
        chart: {
          alignTicks: false,
          zoomType: "x",
        },
        title: { text: charTitle },
        scrollbar: { enabled: false },
        credits: { enabled: false },
        rangeSelecto: { enabled: false },
        navigator: { enabled: false },
        exporting: { enabled: false },
        yAxis: Yaxys,
        xAxis: Xaxys,
        series: series,
        legend: { enabled: true },
        tooltip: {
          crosshairs: [
            { dashStyle: "dash", color: "black" },
            { dashStyle: "dash", color: "black" },
          ],
        },
        plotOptions: {
          series: { animation: false },
          scatter: {
            marker: {
              enabled: true,
              radius: 5,
              lineWidth: 2,
              fillColor: "#eeeeee",
              lineColor: null,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
            states: {
              hover: { marker: { enabled: false } },
            },
            tooltip: {
              headerFormat: "<strong>{point.key}</strong><br/>",
              pointFormat:
                "Trend Duration: {point.x:.2f} months<br/>Trend Performance: {point.y:.2f}%",
            },
            dataLabels: {
              enabled: false,
              formatter: function () {
                const self: any = this;
                return "<strong>" + self.point.security.name + "</strong>";
              },
            },
            point: {
              events: {
                click: function () {
                  const self: any = this;
                  onPointClick(self.security);
                },
              },
            },
          },
        },
      };
    }, [data, onPointClick, pointsToflags]);

    const prepareMagnitudeChartConfig = useCallback(() => {
      var charTitle = "Magnitude of trends";
      var _data = dataPrepare(data, {
        baseTrendKey: "statistics:trend_distribution:performance:",
        propertyX: "ptx",
        // eslint-disable-next-line no-template-curly-in-string
        serieNameTemplateString: "Magnitude of rating ${rate} (${type})",
      });

      var title = "magnitude greater than %";

      var Yaxys = [
        {
          title: { text: title },
          type: "linear",
          max: 100,
          min: 0,
          labels: { format: "{value}%" },
          plotBands,
        },
      ];
      var Xaxys = [
        {
          title: { text: "magnitude" },
          max: _data.maxX,
          labels: { format: "{value}" },
        },
      ];

      return {
        chart: {
          alignTicks: false,
          zoomType: "x",
        },
        title: { text: charTitle },
        scrollbar: { enabled: false },
        credits: { enabled: false },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        exporting: { enabled: false },
        yAxis: Yaxys,
        xAxis: Xaxys,
        series: _data.series,
        legend: { enabled: true },
        tooltip: {
          crosshairs: [
            { dashStyle: "dash", color: "black" },
            { dashStyle: "dash", color: "black" },
          ],
          formatter: function () {
            const self: any = this;
            var tooltip = "";
            if (self.point.series.options.type === "scatter") {
              var magnitude = self.point.security.magnitude;
              tooltip = "<strong>" + self.key + "</strong><br/>";

              if (self.point.y > 95) {
                tooltip += "95% ";
                tooltip += "probability to grow";
              } else if (self.point.y < 5) {
                tooltip += "The probability to grow is less than 5%";
              } else {
                tooltip += format.custom("number", {
                  options: {
                    isPercentage: true,
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                  },
                  output: "HTML",
                  value: magnitude,
                  valueHelper: null,
                });
                tooltip += " probability to grow";
              }
            } else {
              if (self.point.x) {
                tooltip =
                  "<strong>" +
                  self.series.name +
                  "</strong><br/>" +
                  format.custom("number", {
                    options: {
                      isPercentage: true,
                      notAvailable: {
                        input: null,
                        output: "",
                      },
                    },
                    output: "HTML",
                    value: self.point.y / 100,
                    valueHelper: null,
                  }) +
                  " of trends are greater than " +
                  format.custom("number", {
                    options: {
                      isPercentage: true,
                      notAvailable: {
                        input: null,
                        output: "",
                      },
                    },
                    output: "HTML",
                    value: self.point.x / 100,
                    valueHelper: null,
                  });
              } else {
                tooltip = "<strong>" + self.series.name + "</strong>";
              }
            }
            return tooltip;
          },
        },
        plotOptions: {
          series: { animation: false },
          scatter: {
            marker: {
              enabled: true,
              radius: 5,
              lineWidth: 2,
              fillColor: "#eeeeee",
              lineColor: null,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
            states: {
              hover: { marker: { enabled: false } },
            },
            point: {
              events: {
                click: function () {
                  const self: any = this;
                  onPointClick(self.security);
                },
              },
            },
          },
        },
      };
    }, [data, dataPrepare, format, onPointClick, plotBands]);

    const prepareDurationChartConfig = useCallback(() => {
      if (data) {
        const _data = dataPrepare(data, {
          baseTrendKey: "statistics:trend_distribution:duration:",
          propertyX: "lt",
          // eslint-disable-next-line no-template-curly-in-string
          serieNameTemplateString: "Duration of rating ${rate} (${type})",
        });
        var charTitle = "Duration of trends";
        var title = "trends longer than N months";

        var Yaxys = [
          {
            title: { text: title },
            type: "linear",
            max: 100,
            min: 0,
            labels: { format: "{value}%" },
            plotBands,
          },
        ];
        var Xaxys = [
          {
            title: { text: "months" },
            max: _data.maxX,
            labels: { format: "{value}" },
          },
        ];

        return {
          chart: {
            alignTicks: false,
            zoomType: "x",
          },
          title: { text: charTitle },
          scrollbar: { enabled: false },
          credits: { enabled: false },
          rangeSelector: { enabled: false },
          navigator: { enabled: false },
          exporting: { enabled: false },
          yAxis: Yaxys,
          xAxis: Xaxys,
          series: _data.series,
          legend: { enabled: true },
          tooltip: {
            crosshairs: [
              { dashStyle: "dash", color: "black" },
              { dashStyle: "dash", color: "black" },
            ],
            formatter: function () {
              var tooltip = "";
              const self: any = this;
              if (self?.point?.series?.options?.type === "scatter") {
                var duration = self.point.security.duration;
                tooltip = "<strong>" + self.key + "</strong><br/>";

                if (duration > 0.95) {
                  tooltip += "95% ";
                  tooltip +=
                    " probability to continue more than " +
                    self.point.x +
                    " months";
                } else if (duration < 0.05) {
                  tooltip += "The probability to continue is less than 5%";
                } else {
                  tooltip += format.custom("number", {
                    options: {
                      isPercentage: true,
                      notAvailable: {
                        input: null,
                        output: "",
                      },
                    },
                    output: "HTML",
                    value: duration,
                    valueHelper: null,
                  });

                  tooltip +=
                    " probability to continue more than " +
                    self.point.x +
                    " months";
                }
              } else {
                if (self?.point?.x) {
                  tooltip =
                    "<strong>" +
                    self.series.name +
                    "</strong><br/>" +
                    format.custom("number", {
                      options: {
                        isPercentage: true,
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: self.point.y / 100,
                      valueHelper: null,
                    }) +
                    " of trends are longer than " +
                    self.point.x +
                    " months";
                } else {
                  tooltip = "<strong>" + self.series.name + "</strong>";
                }
              }
              return tooltip;
            },
          },
          plotOptions: {
            series: { animation: false },
            scatter: {
              marker: {
                enabled: true,
                radius: 5,
                lineWidth: 2,
                fillColor: "#eeeeee",
                lineColor: null,
                symbol: "circle",
                states: {
                  hover: {
                    enabled: true,
                    lineColor: "rgb(100,100,100)",
                  },
                },
              },
              states: {
                hover: { marker: { enabled: false } },
              },
              point: {
                events: {
                  click: function () {
                    const self: any = this;
                    onPointClick(self.security);
                  },
                },
              },
            },
          },
        };
      }
    }, [data, dataPrepare, format, onPointClick, plotBands]);

    const prepareCrossFrequenciesChartConfig = useCallback(() => {
      var charTitle = "Continuation of duration vs Continuation of magnitude";
      var _data = data["positions"];
      var duration = 0;
      var magnitude = 0;
      var points: any = [];

      var security: any = null;

      for (let i = 0, length = _data.length; i < length; i++) {
        security = _data[i];

        // not existing or low rating
        if (security.dc == null || security.rc <= 0) continue;

        magnitude = security.magnitude * 100;
        duration = security.duration * 100;

        points.push({
          security: security,
          x: edgeCaseManager(duration, magnitude),
          y: edgeCaseManager(magnitude, duration),
        });
      }

      var series = pointsToflags(points);
      var Yaxys = [
        {
          title: { text: "Duration" },
          type: "linear",
          labels: { format: "{value}%" },
          max: 100,
          min: 0,
          plotLines: [
            {
              value: 50,
              color: "#1A7BC9",
              width: 2,
            },
          ],
        },
      ];
      var Xaxys = [
        {
          title: { text: "Magnitude" },
          labels: { format: "{value}%" },
          max: 100,
          min: 0,
          plotLines: [
            {
              value: 50,
              color: "#1A7BC9",
              width: 2,
            },
          ],
        },
      ];

      return {
        chart: {
          alignTicks: false,
          plotBorderColor: "#1A7BC9",
          plotBorderWidth: 2,
          zoomType: "x",
        },
        title: { text: charTitle },
        scrollbar: { enabled: false },
        credits: { enabled: false },
        rangeSelecto: { enabled: false },
        navigator: { enabled: false },
        exporting: { enabled: false },
        yAxis: Yaxys,
        xAxis: Xaxys,
        series: series,
        legend: { enabled: true },
        tooltip: {
          crosshairs: [
            { dashStyle: "dash", color: "black" },
            { dashStyle: "dash", color: "black" },
          ],
          formatter: function () {
            const self: any = this;
            var tooltip = "<strong>" + self.key + "</strong><br/>";
            if (self.point.x > 95) {
              tooltip += "Magnitude: 95%<br/>";
            } else {
              tooltip +=
                "Magnitude: " +
                format.custom("number", {
                  options: {
                    isPercentage: true,
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                  },
                  output: "HTML",
                  value: self.point.x / 100,
                  valueHelper: null,
                }) +
                "<br/>";
            }
            if (self.point.y > 95) {
              tooltip += "Duration: 95%";
            } else {
              tooltip +=
                "Duration: " +
                format.custom("number", {
                  options: {
                    isPercentage: true,
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                  },
                  output: "HTML",
                  value: self.point.y / 100,
                  valueHelper: null,
                });
            }
            return tooltip;
          },
        },
        plotOptions: {
          series: { animation: false },
          scatter: {
            marker: {
              enabled: true,
              radius: 5,
              lineWidth: 2,
              fillColor: "#eeeeee",
              lineColor: null,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
            states: {
              hover: { marker: { enabled: false } },
            },
            point: {
              events: {
                click: function () {
                  const self: any = this;
                  onPointClick(self.security);
                },
              },
            },
          },
        },
      };
    }, [data, edgeCaseManager, format, onPointClick, pointsToflags]);

    const chartSetup = useMemo(() => {
      switch (what) {
        case "duration": {
          return prepareDurationChartConfig();
        }
        case "magnitude": {
          return prepareMagnitudeChartConfig();
        }
        case "crossFrequencies": {
          return prepareCrossFrequenciesChartConfig();
        }
        case "trendsVsPerf": {
          return prepareTrendsVsPerfChartConfig();
        }
      }
    }, [
      prepareCrossFrequenciesChartConfig,
      prepareDurationChartConfig,
      prepareMagnitudeChartConfig,
      prepareTrendsVsPerfChartConfig,
      what,
    ]);

    return <Component options={chartSetup} constructorType={"chart"} />;
  };
};
