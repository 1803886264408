export type StateType = {
  basic: {
    autoRebalance: boolean;
    benchmark: any;
    currency: any;
    name: null | string;
    strategyId: null | number;
    expenseRatio: number;
    orderThreshold: any;
  };
  id: null | string;
  priceLevel: {
    fromDate: boolean; //!added to manage ui, remove in the end
    initialValue: number;
    isBacktestingInherited: boolean | undefined;
    isBacktestingInheritedDate: null | number;
  };
  todayList: {
    cash: any;
    list: any;
    strategy: any;
  };
};
export const initState: StateType = {
  basic: {
    autoRebalance: false,
    benchmark: null,
    currency: null,
    name: null,
    strategyId: null,
    expenseRatio: 0,
    orderThreshold:0,
  },
  id: null,
  priceLevel: {
    fromDate: false, //!added to manage ui, remove in the end
    initialValue: 100,
    isBacktestingInherited: true,
    isBacktestingInheritedDate: null,
  },
  todayList: {
    cash: null,
    list: null,
    strategy: { isRebalanceToday: false },
  },
};

type ActionType = {
  type:
    | "SET_NAME"
    | "SET_FEES"
    | "SET_ORDER_THRESHOLD"
    | "SET_AUTOREBALANCE"
    | "SET_STRATEGY"
    | "SET_INIT_VALUE"
    | "SET_DATE"
    | "SET_IS_FROM_DATE"
    | "SET_IS_BACKTESTING_INHERIT"
    | "SET_TODAYLISTS_CASH"
    | "SET_TODAYLISTS_STRATEGY"
    | "SET_TODAYLISTS_LIST"
    | "SET_CURRENCY"
    | "SET_BENCHMARK";

  value: any;
};
export function createContenReducer(draft: StateType, action: ActionType) {
  switch (action.type) {
    case "SET_NAME":
      draft.basic.name = action.value;
      break;
    case "SET_AUTOREBALANCE":
      draft.basic.autoRebalance = action.value;
      break;
    case "SET_FEES":
      draft.basic.expenseRatio = action.value;
      break;
    case "SET_ORDER_THRESHOLD":
      draft.basic.orderThreshold = action.value;
      break;
    case "SET_STRATEGY":
      draft.basic.strategyId = action.value.strategyID;
      draft.basic.currency = action.value.currency;
      draft.basic.benchmark = action.value.benchmark;
      break;
    case "SET_DATE":
      draft.priceLevel.isBacktestingInheritedDate = action.value;
      break;
    case "SET_INIT_VALUE":
      draft.priceLevel.initialValue = action.value;
      break;
    case "SET_IS_FROM_DATE":
      draft.priceLevel.fromDate = action.value;
      break;
    case "SET_IS_BACKTESTING_INHERIT":
      draft.priceLevel.isBacktestingInherited = action.value;
      break;
    case "SET_TODAYLISTS_CASH":
      draft.todayList.list = null;
      draft.todayList.strategy = null;
      draft.todayList.cash = action.value;
      break;
    case "SET_TODAYLISTS_STRATEGY":
      draft.todayList.list = null;
      draft.todayList.strategy = action.value;
      draft.todayList.cash = null;
      break;
    case "SET_TODAYLISTS_LIST":
      draft.todayList.list = action.value;
      draft.todayList.strategy = null;
      draft.todayList.cash = null;
      break;
    case "SET_BENCHMARK":
      draft.basic.benchmark = action.value;
      break;
    case "SET_CURRENCY":
      draft.basic.currency = action.value;
      break;
  }
}
