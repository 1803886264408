import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnDefinition } from "tabulator-tables";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { handleTitle } from "../../../../Utility/DocumentTitleHanlder";
import { ClusterAnalytics } from "../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import {
  TableEventsV2,
  TableV2,
} from "../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../deepClone";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../hooks/useFormatter";
import { Export } from "../../components/app-infrastructure/workflowBar/actions/export/Export";
import { config } from "../../config-ts";
import { DialogSaveComponent } from "../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import ReportButton from "../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { TableSkeleton } from "../analysisMarkets/detail/PeerDetail";
import { RankDialog } from "../rank/RankDialog/RankDialog";
import { RankOutput } from "../rank/Ranking";
import { ActionRank } from "../rank/actions/ActionRank";
import { ActionRankContext } from "../rank/actions/ActionRankContext/ActionRankContext";
import { FilterRowContraints } from "./FilterBar/FilterRowContraints";
import styles from "./Screening.module.scss";
import { DeleteScreeningDialog } from "./actions/ActionDeleteScreening/DeleteScreeningDialog";
import { FundamentalsTable } from "./widgets/FundamentalsTable";
import { SecurityPanel } from "./widgets/SecurityPanel";

type DispersionData = { "3_months": number; "6_months": number };

type DispersionOverviewType = {
  data?: { AB: DispersionData; CD: DispersionData; ALL: DispersionData };
  loading?: boolean;
};

const decodeTypeParam = (type) => {
  const map = {
    etf: "ETF",
    index: "Index",
    currency: "Currency",
    sector: "Sector",
    stock: "Stock",
    commodity: "Commodity",
    crypto: "Crypto",
  };

  return map?.[type] ?? "Stock";
};

const encodeTypeParam = (value) => {
  const map = {
    ETF: "etf",
    Index: "index",
    Currency: "currency",
    Sector: "sector",
    Stock: "stock",
    Commodity: "commodity",
    Crypto: "crypto",
  };

  return map?.[value] ?? "stock";
};

export function Screening() {
  const params = useParams();

  const [keyRefesh, setKeyRefresh] = useState(Date.now());

  /**
   * By using this ref at the first render the key is not changed so the component is not rerendered in an unuseful way
   */
  const tabRef = useRef(params.type);

  /**
   * This is done to avoid table problems on tab change the page is entirely rerendered on tab change
   */
  useEffect(() => {
    if (params.type !== tabRef.current) {
      tabRef.current = params.type;
      setKeyRefresh(Date.now());
    }
  }, [params.type]);

  return <ScreeningContent key={keyRefesh} params={params} />;
}

const ScreeningContent = ({ params }: any) => {
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const configuration = appSetup["configuration"];
  const configurationScreening = configuration.get("screening");
  const [activeTemplate, setActiveTemplate] = useState<{
    name: string;
    id: number;
    isReadOnly: boolean;
  }>();
  const [filters, setFilters] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [dataTotalCount, setDataTotalCount] = useState(0);
  const instrumentsApi = useMemo(() => new Instruments(appSetup), [appSetup]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [expand, setExpand] = useState(false);
  const [showTemplateSaveDialog, setShowTemplateSaveDialog] = useState(false);
  const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sorter, setSorter] = useState({ field: "marketcap", rev: true });
  const [isRankOn, setIsRankOn] = useState(false);
  const [showRankDialog, setShowRankDialog] = useState(false);
  const [avgRow, setAvgRow] = useState<any>();
  const [tableStatus, setTableStatus] = useState({
    columns: false,
    built: false,
  });
  const [foundRows, setFoundRows] = useState<string[]>([]);
  const [selectedWhitelistsNames, setSelectedWhiteListsNames] = useState<
    string[] | undefined
  >();
  const [reportWidgetInfoTrigger, setReportWidgetInfoTrigger] = useState({});
  const [tabInfoValue, setTabInfoValue] = useState(0);
  const [universeDispersion, setUniverseDispersion] = useState<{
    AB: DispersionData;
    CD: DispersionData;
    ALL: DispersionData;
  }>();
  const [loadingDispersion, setLoadingDispersion] = useState(true);

  const isSearchActive = useMemo(() => {
    return foundRows.length > 0;
  }, [foundRows]);

  const tableReady = useMemo(
    () => tableStatus.built && tableStatus.columns,
    [tableStatus.built, tableStatus.columns]
  );

  const tableRef = useRef<{
    getInstance: () => TableV2;
    refreshSearch: () => void;
  }>();

  const { rank, rankReducer, rankResults, rankingCache, getRowsFromRanking } =
    useContext(ActionRankContext);

  const setData = useCallback(async (data) => {
    const table = tableRef.current?.getInstance();

    if (table) {
      await table.insertData(data);

      const avgPlugin = table.getAvgStatus();
      const avgRow = avgPlugin?.get("avgRow");
      const hasAvgRow = avgRow != null && Object.keys(avgRow).length > 0;

      if (hasAvgRow) {
        avgPlugin?.addAvgRow();
      }
    }
  }, []);

  const clearAvgRow = useCallback(() => {
    const table = tableRef.current?.getInstance();

    const avgPlugin = table?.getAvgStatus();

    avgPlugin?.clear("avgRow");
    avgPlugin?.removeAvgRow();
    setAvgRow(undefined);

    // Refresh menu
    const columns = table?.getColumns().map((c) => c.getDefinition()) ?? [];

    for (const col of columns) {
      if (col.headerMenu != null) {
        const menu: any = col.headerMenu;

        for (const voice of menu) {
          if (voice.actionTag === "avg") {
            voice.label = "&#956; Average";

            break;
          }
        }

        table?.updateColumn(col.field!, {
          headerMenu: menu,
        } as any);
      }
    }
  }, []);

  const onFirstRankFinish = useCallback(() => {
    setIsRankOn(true);
    const table = tableRef.current?.getInstance();
    setSorter({ field: "rank", rev: false });
    table?.sort("rank", "asc");

    // *********************** USAGE ***********************
    var usage = window.App.usage;
    var info = {
      action: "RANK",
      actionParam: null,
      function: "SCREENING",
    };
    usage.record(info);
    // *********************** USAGE ***********************

    clearAvgRow();
    tableRef.current?.refreshSearch();
    setFoundRows([]);
  }, [clearAvgRow]);

  const openRankDialog = useCallback(() => {
    const table = tableRef.current?.getInstance();
    const tableColumns =
      table?.getColumns().map((col) => col.getDefinition()) ?? [];

    const columns: any[] = [];

    for (const col of tableColumns) {
      if (col.field != null && !/^rank/.test(col.field!)) {
        columns.push(col);
      }
    }
    rankReducer({ type: "SET_COLUMNS", payload: columns });

    setShowRankDialog(true);
  }, [rankReducer]);
  const closeRankDialog = useCallback(() => setShowRankDialog(false), []);

  const handleRankFinish = useCallback(
    (result: RankOutput) => {
      if (result.data && result.data.length) {
        setData(result.data as any);
      }

      setDataTotalCount(result.dataTotalCount);
      const table = tableRef.current?.getInstance();

      const sorter = table?.getSorter();

      if (result.columns && result.columns.length) {
        const columnsToInsert = result.columns.map((column) => {
          if (typeof column === "string") {
            return { field: column };
          } else {
            return column;
          }
        }, []);
        table?.insertColumns(columnsToInsert);

        const currentField = sorter?.[0]?.field;
        const currentDir = sorter?.[0]?.dir;

        if (currentDir && currentField) {
          table?.sort(currentField, currentDir);
        } else {
          table?.sort("rank", "desc");
        }
      }
    },
    [setData]
  );

  const onRank = useCallback(
    async (params) => {
      setIsLoading(true);
      await rank(params);

      setIsLoading(false);
    },
    [rank]
  );

  const tab = useMemo(() => decodeTypeParam(params.type), [params.type]);

  const navigate = useNavigate();

  const optionsList = useMemo(() => {
    const defaultOpts = [
      {
        label: "Stocks",
        value: "Stock",
      },
      {
        label: "Sectors",
        value: "Sector",
      },
      {
        label: "ETFs",
        value: "ETF",
      },
      {
        label: "Indices",
        value: "Index",
      },
      {
        label: "Currencies",
        value: "Currency",
      },
      {
        label: "Commodities",
        value: "Commodity",
      },
      { label: "Crypto", value: "Crypto" },
    ];

    const options: any = [];

    const configuration =
      environment.get("account")?.product?.configuration?.screening;

    for (const option of defaultOpts) {
      if (configuration?.tabs[option.value].enabled === true) {
        options.push(option);
      }
    }

    return options;
  }, [environment]);

  useEffect(() => {
    removeLoader();
  }, []);

  // Handle tab title
  useEffect(() => {
    handleTitle({ type: "SCREENING" });
  }, []);

  const prepareScreeningReq = useCallback(
    (filters: any) => {
      const body = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
        page: { page, rows: itemsPerPage },
        sort: [{ dimension: sorter.field, rev: sorter.rev }],
      };

      // This multisort avoid problems on fields that has n/a value in redis
      // if it's not used the server give and empty response on pages that are filled with securities
      // that has no value for the field that they were sorted by
      if (sorter.field !== "marketcap") {
        body.sort.push({ dimension: "marketcap", rev: true });
      }

      if (filters) {
        for (const filter of filters) {
          const { filter: filterValue, type } = filter;

          if (type in body) {
            body[type] = [...body[type], ...filterValue];
          } else {
            body[type] = [...filterValue];
          }
        }
      }

      return body;
    },
    [itemsPerPage, page, sorter.field, sorter.rev, tab]
  );

  const refreshAvgRow = useCallback(
    async (filters) => {
      const table = tableRef.current?.getInstance();
      const status = table?.getAvgStatus();

      const screening = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
        page: { page: 1, rows: 20000 },
        sort: [{ dimension: "marketcap", rev: false }],
      };

      if (filters) {
        for (const filter of filters) {
          const { filter: filterValue, type } = filter;

          if (type in screening) {
            screening[type] = [...screening[type], ...filterValue];
          } else {
            screening[type] = [...filterValue];
          }
        }
      }

      const avgRow = status?.getState().avgRow;

      if (!avgRow) {
        return;
      }

      if (table) {
        const columns = table.getColumns();
        let definition: any = null;
        let promises: any = [];

        for (const col of columns) {
          definition = col.getDefinition();

          if (
            definition.field in avgRow &&
            typeof avgRow[definition.field] === "number"
          ) {
            if (status) {
              const universe =
                instrumentsApi.convertToIndexBuilderSyntax(screening);

              promises.push(status.updateAvgRow(definition.field, universe));
            }
          }
        }

        await Promise.all(promises);

        const row = status!.get("avgRow");
        setAvgRow(deepClone(row));
      }
    },
    [instrumentsApi, tab]
  );

  const prepareFiltersForRank = useCallback(
    (filters: any) => {
      const body = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
      };

      if (filters) {
        for (const filter of filters) {
          const { filter: filterValue, type } = filter;

          if (type in body) {
            body[type] = [...body[type], ...filterValue];
          } else {
            body[type] = [...filterValue];
          }
        }
      }

      return body;
    },
    [tab]
  );

  const getPropertiesFromCols = useCallback(() => {
    const fields = tableRef.current?.getInstance().getFields();

    const properties =
      fields?.map((field) => ({ date: null, property: field })) ?? [];

    return properties;
  }, []);

  const getWhiteListsNames = useCallback(
    async (segments) => {
      const listsAPI = new Lists(environment.get("setup"));
      const response = await listsAPI.portfolioFetch(segments[0].domain, [
        "name",
      ]);

      const whitelistsNames: string[] = [];

      for (const res of response) {
        if (res.name) {
          whitelistsNames.push(res.name);
        }
      }

      setSelectedWhiteListsNames(whitelistsNames);
    },
    [environment]
  );

  const screen = useCallback(
    async (filters) => {
      if (!isRankOn && !isSearchActive) {
        setIsLoading(true);

        try {
          const table = tableRef.current?.getInstance();

          const relations = filters.find((f) => f.type === "relations");

          if (relations && relations.filter?.[0].domain.length) {
            getWhiteListsNames(relations.filter);
          } else {
            setSelectedWhiteListsNames(undefined);
          }

          const payload = prepareScreeningReq(filters);
          const rows = await instrumentsApi.screening(payload);

          if (table) {
            // Used by search table widget
            const clonedPayload = JSON.parse(JSON.stringify(payload));
            clonedPayload["page"] = { page: 1, rows: 20000 };

            table.updateSearchContext(clonedPayload);
          }

          const properties = getPropertiesFromCols();
          setDataTotalCount(rows.dataTotalCount);
          const fetchRows = await instrumentsApi.fetch({
            properties,
            symbols: rows?.data ?? [],
            type: "security",
          });

          setSelectedRow(fetchRows?.data?.[0]);
          setData(fetchRows?.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      getPropertiesFromCols,
      getWhiteListsNames,
      instrumentsApi,
      isRankOn,
      isSearchActive,
      prepareScreeningReq,
      setData,
    ]
  );

  const removeRankColumns = useCallback(() => {
    const table = tableRef.current?.getInstance();
    setIsRankOn(false);
    rankReducer({ type: "SET_CURRENT_TEMPLATE_ID", payload: undefined });
    rankReducer({ type: "SET_RANKING_RULES", payload: [] });
    rankReducer({
      type: "SET_RANKING_RESULT",
      payload: {
        columns: [],
        data: [],
        dataTotalCount: 0,
        universeDefinition: { filters: [] },
      },
    });

    setSorter({ field: "marketcap", rev: true });
    table?.sort("marketCap", "desc");

    const currentColumns = table
      ?.getColumns()
      .map((col) => col.getDefinition());

    const rankColumns: ColumnDefinition[] = [];

    if (currentColumns) {
      for (const col of currentColumns) {
        if (/^rank/.test(col.field!)) {
          rankColumns.push(col);
        }
      }

      table?.removeColumns(rankColumns);
    }
  }, [rankReducer]);

  const { broadcast } = useBroadcast();

  const screenSearchUniverse = useCallback(
    async ({ field, direction, page, itemsPerPage }) => {
      const symbols = [...foundRows];

      if (symbols?.length) {
        if (!isRankOn) {
          const screeningPayload = {
            constraints: [
              [{ dimension: "symbol", operator: "equals", segments: symbols }],
            ],
            sort: [{ dimension: field, rev: direction === "desc" }],
            page: { page, rows: itemsPerPage },
          };

          if (field !== "marketcap") {
            screeningPayload.sort.push({ dimension: "marketcap", rev: false });
          }

          const sortedIds = await instrumentsApi.screening(
            screeningPayload,
            true
          );
          const properties = getPropertiesFromCols();
          const response = await instrumentsApi.fetch({
            properties,
            symbols: sortedIds.data,
            type: "security",
          });

          setDataTotalCount(sortedIds.dataTotalCount);
          setSelectedRow(response.data[0]);
          await setData(response.data);
        } else {
          const { rows, total } = await getRowsFromRanking(
            symbols,
            field,
            direction,
            page,
            itemsPerPage
          );
          setDataTotalCount(total);
          setSelectedRow(rows[0]);
          await setData(rows);
        }
      }
    },
    [
      foundRows,
      getPropertiesFromCols,
      getRowsFromRanking,
      instrumentsApi,
      isRankOn,
      setData,
    ]
  );

  const onSelectItemPerPage = useCallback(
    (value) => {
      setItemsPerPage(value);
      setPage(1);

      if (isSearchActive) {
        screenSearchUniverse({
          field: sorter.field,
          direction: sorter.rev === false ? "desc" : "asc",
          page: 1,
          itemsPerPage: value,
        });
      } else if (isRankOn) {
        onRank({ itemsPerPage: value, page: 1 });
      }
    },
    [
      isRankOn,
      isSearchActive,
      onRank,
      screenSearchUniverse,
      sorter.field,
      sorter.rev,
    ]
  );

  const onChangePage = useCallback(
    (value) => {
      setPage(value);
      if (isSearchActive) {
        screenSearchUniverse({
          field: sorter.field,
          direction: sorter.rev === true ? "asc" : "desc",
          page: value,
          itemsPerPage,
        });
      } else if (isRankOn) {
        onRank({ page: value });
      }
    },
    [
      isRankOn,
      isSearchActive,
      itemsPerPage,
      onRank,
      screenSearchUniverse,
      sorter.field,
      sorter.rev,
    ]
  );

  useEffect(() => {
    if (filters != null && tableReady) {
      screen(filters);
    }
  }, [filters, screen, tableReady]);

  const handleTabChange = useCallback(
    (evt, value) => {
      removeRankColumns();
      setTableStatus({ built: false, columns: false });
      const tabUriValue = encodeTypeParam(value);
      navigate(`/app/screening/${tabUriValue}`);
    },
    [navigate, removeRankColumns]
  );

  const prepareRow = useCallback(() => {
    const filterWidgetsConf = configurationScreening["widgets"]["viewerFilter"];
    const commonFilters = filterWidgetsConf?.[1] ?? [];
    const filtersByTab = filterWidgetsConf?.[0]?.[tab] ?? [];

    return [...filtersByTab, ...commonFilters];
  }, [configurationScreening, tab]);

  const onFiltersChange = useCallback(
    (value) => {
      setFilters(value);
      setPage(1);
      refreshAvgRow(value);
      tableRef.current?.refreshSearch();
      setFoundRows([]);
      removeRankColumns();
    },
    [refreshAvgRow, removeRankColumns]
  );

  const onDataSorted = useCallback(
    ({ field, direction }) => {
      setSorter({ field, rev: direction === "desc" });
      setPage(1);

      if (isSearchActive) {
        screenSearchUniverse({ field, direction, page: 1, itemsPerPage });
      } else if (isRankOn) {
        onRank({
          rev: direction === "desc",
          sortField: field,
          page: 1,
        });
      }
    },
    [isRankOn, isSearchActive, itemsPerPage, onRank, screenSearchUniverse]
  );

  const onExpandTable = useCallback(() => {
    setExpand((current) => !current);
  }, []);

  const rowClickOverride = useCallback((e, row) => {
    const data = row.getData();
    if (data?.symbol != null) {
      setSelectedRow(data);
    }
  }, []);

  const changeRankDateFrom = useCallback(
    (value) => {
      onRank({
        rev: false,
        sortField: "rank",
        page: 1,
        fromDate: value,
      });
    },
    [onRank]
  );

  const getWidgetInfo = useCallback(
    (
      infoType:
        | "columns"
        | "constraints"
        | "pagination"
        | "sortBy"
        | "dataTotalCount"
        | "avgRow"
        | "dispersionActive"
    ) => {
      switch (infoType) {
        case "pagination":
          return {
            page,
            rows: itemsPerPage,
          };

        case "sortBy":
          return {
            property: sorter.field,
            descending: sorter.rev,
          };
        case "dataTotalCount":
          return dataTotalCount;

        case "constraints":
          return prepareScreeningReq(filters);

        case "columns": {
          const table = tableRef.current?.getInstance();

          let _column: any = null;
          let _columns: any = table
            ?.getColumns()
            .map((column) => column.getDefinition());
          let column: any = null;
          let columns: any = [];

          if (_columns) {
            for (let i = 0, length = _columns.length; i < length; i++) {
              _column = _columns[i];
              if (_column["field"] !== undefined && _column["field"] != null) {
                column = {
                  label: (_column?.["title"] ?? _column?.["label"] ?? "")
                    .replace(/<br\/>/gi, " - ")
                    .replace(/<br>/gi, " "),
                  property: _column["field"],
                };
                columns.push(column);
              }
            }
          }

          return columns;
        }

        case "avgRow": {
          if (avgRow != null) {
            return deepClone(avgRow);
          }

          return undefined;
        }

        case "dispersionActive":
          return tabInfoValue === 1;

        default:
          console.log(`Unrecognized widget type: ${infoType}`);
      }
    },
    [
      avgRow,
      dataTotalCount,
      filters,
      itemsPerPage,
      page,
      prepareScreeningReq,
      sorter.field,
      sorter.rev,
      tabInfoValue,
    ]
  );

  const onSaveTemplate = useCallback(
    async (newName?: string) => {
      const wysiwyg = prepareScreeningReq(filters) ?? null;
      const templateInfo = { ...activeTemplate };

      if (templateInfo.id && wysiwyg) {
        const payload = instrumentsApi.convertToIndexBuilderSyntax(wysiwyg);

        const screeningTemplateJSON = {
          data: payload,
          assetClass: tab ? tab.toLowerCase() : "unknown",
          name: newName && newName.length ? newName : templateInfo.name,
          id: templateInfo.id,
        };

        try {
          await instrumentsApi.update(screeningTemplateJSON, "SCREENING");

          const message = {
            from: "Routes",
            content: {
              type: "success",
              text: `<strong>${templateInfo.name}</strong> successfully saved`,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageSuccess(message.content.text);
          broadcast(channel as string, msg);
        } catch (error) {
          const message = {
            from: "Routes",
            content: {
              type: "error",
              text: `Something gone wrong cannot save <strong>${templateInfo.name}</strong> `,
            },
          };

          const [channel, msg] = messageError(message.content.text);
          broadcast(channel as string, msg);

          setShowTemplateSaveDialog(false);
        }
      }
    },
    [
      activeTemplate,
      broadcast,
      filters,
      instrumentsApi,
      prepareScreeningReq,
      tab,
    ]
  );

  const onCreateTemplate = useCallback(
    async (name: string) => {
      // wysiwyg state
      const filtersOldSyntax = prepareScreeningReq(filters) ?? null;

      if (filtersOldSyntax) {
        const newScreeningSyntaxConstraints =
          instrumentsApi.convertToIndexBuilderSyntax(filtersOldSyntax);

        const screeningTemplateJSON = {
          data: newScreeningSyntaxConstraints,
          assetClass: tab ? tab.toLowerCase() : "unknown",
          name,
          id: null,
        };

        try {
          await instrumentsApi.create(screeningTemplateJSON, "SCREENING");

          const message = {
            from: "Routes",
            content: {
              type: "success",
              text: `<strong>${name}</strong> successfully saved`,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageSuccess(message.content.text);
          broadcast(channel as string, msg);
        } catch (error) {
          const message = {
            from: "Routes",
            content: {
              type: "error",
              text: `Something gone wrong cannot save <strong>${name}</strong> `,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageError(message.content.text);
          broadcast(channel as string, msg);
        }
      }
    },
    [broadcast, filters, instrumentsApi, prepareScreeningReq, tab]
  );

  const refreshReportWidgetInfo = useCallback(() => {
    setReportWidgetInfoTrigger({});
  }, []);

  const onColumnsChange = useCallback(
    async (columns) => {
      clearAvgRow();
      refreshReportWidgetInfo();

      if (isSearchActive) {
        if (isRankOn) {
          await onRank({
            sortField: "rank",
            rev: false,
            page: 1,
            columns: columns.map((col) => col.field),
          });
        }

        screenSearchUniverse({
          field: sorter.field,
          direction: sorter.rev === false ? "desc" : "asc",
          page: 1,
          itemsPerPage,
        });
      } else if (isRankOn) {
        onRank({
          sortField: "rank",
          rev: false,
          page: 1,
          columns: columns.map((col) => col.field),
        });
      } else {
        screen(filters);
      }
    },
    [
      clearAvgRow,
      filters,
      isRankOn,
      isSearchActive,
      itemsPerPage,
      onRank,
      refreshReportWidgetInfo,
      screen,
      screenSearchUniverse,
      sorter.field,
      sorter.rev,
    ]
  );

  const getActiveTemplate = useCallback(() => activeTemplate, [activeTemplate]);

  useEffect(() => {
    let actions: any = [];
    let action: any = null;

    const exportFileName = "trendrating-screening.csv";
    let targetName = "Screening";
    const constraints = getWidgetInfo("constraints");

    if (activeTemplate) {
      targetName = activeTemplate.name;
    } else {
      if (selectedWhitelistsNames && selectedWhitelistsNames.length > 0) {
        targetName = selectedWhitelistsNames.join(", ");
      }
    }

    let target = {
      columns: getWidgetInfo("columns"),
      constraints,

      // IMPORTANT: it is just a placeholder
      // NOT GUARANTEES that the id is unique!
      //
      // Could happens that another user generate the same
      // id in exactly the same time
      //
      id: "screening_" + new Date().getTime(),

      name: targetName,
      page: getWidgetInfo("pagination"),
      sortBy: getWidgetInfo("sortBy"),
    };

    const logFn = () => {
      //   //********************** USAGE *************************
      var usage = window.App.usage;
      var info = {
        action: "EXPORT",
        actionParam: null,
        function: "SCREENING",
      };
      usage.record(info);
      //   //********************** USAGE *************************
    };

    if (isRankOn) {
      const rankingParams = rankingCache;
      action = {
        componentJSX: (
          <Export
            logFunction={logFn}
            widgets={{
              table: { get: getWidgetInfo },
            }}
            fileName={exportFileName}
            rankingCache={rankingCache}
          />
        ),
      };
      actions.push(action);

      action = {
        componentJSX: (
          <ReportButton
            page={"screening"}
            target={target}
            rankingCache={rankingParams}
            title={target.name}
            usage={window.App.usage}
            widgets={{ table: { get: getWidgetInfo } }}
          />
        ),
      };

      actions.push(action);

      action = {
        componentJSX: <ActionRank openRankDialog={openRankDialog} />,
      };

      actions.push(action);

      action = {
        componentJSX: (
          <li onClick={removeRankColumns} className="menu__item">
            Remove Ranking
          </li>
        ),
      };

      actions.push(action);
    } else {
      action = {
        componentJSX: (
          <Export
            logFunction={logFn}
            widgets={{
              table: { get: getWidgetInfo },
            }}
            fileName={exportFileName}
            rankingCache={rankingCache}
          />
        ),
      };
      actions.push(action);

      action = {
        componentJSX: (
          <ReportButton
            page={"screening"}
            target={target}
            title={target.name}
            usage={window.App.usage}
            widgets={{ table: { get: getWidgetInfo } }}
          />
        ),
      };

      actions.push(action);

      action = {
        componentJSX: <ActionRank openRankDialog={openRankDialog} />,
      };

      actions.push(action);
    }

    action = {
      componentJSX: (
        <li
          title="Save screening template"
          className="menu__item"
          onClick={() => setShowTemplateSaveDialog(true)}
        >
          Save
        </li>
      ),
    };

    actions.push(action);

    action = {
      componentJSX: (
        <li
          title="Delete screening template"
          className="menu__item menu__item--delete"
          onClick={() => setShowDeleteTemplateDialog(true)}
        >
          Delete
        </li>
      ),
    };

    if (activeTemplate && !activeTemplate.isReadOnly) {
      actions.push(action);
    }

    var message = {
      from: "screening",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);

    return () => {
      var message = {
        from: "screening",
        content: {
          actions: [],
        },
      };

      broadcast(config["channels"]["workflow"]["input"], message);
    };
  }, [
    activeTemplate,
    broadcast,
    getWidgetInfo,
    isRankOn,
    openRankDialog,
    rankingCache,
    removeRankColumns,
    selectedWhitelistsNames,
    // Useful to refresh columns
    reportWidgetInfoTrigger,
  ]);

  useEffect(() => {
    if (rankResults.columns.length) {
      handleRankFinish(rankResults);
    }
  }, [handleRankFinish, rankResults]);

  useEffect(() => {
    rankReducer({
      type: "SET_CONSTRAINTS",
      payload: prepareFiltersForRank(filters),
    });
  }, [filters, prepareFiltersForRank, rankReducer]);

  const previousAddedCols = useRef<any>({});

  const addToColumns = useCallback((value: any) => {
    const columnsToAdd: any = value.map((item) => ({ field: item.field }));
    const columnsToRemove = Object.keys(previousAddedCols.current).map(
      (item) => ({
        field: item,
      })
    );
    const table = tableRef.current?.getInstance();

    // Reset average row
    const avgPlugin = table?.getAvgStatus();

    avgPlugin?.clear("avgRow");
    avgPlugin?.removeAvgRow();
    setAvgRow(undefined);

    // Remove the old columns
    table?.removeColumns(columnsToRemove);

    // Add the new columns
    const columns = table?.getColumns().map((col) => col.getDefinition());
    const fieldsAlreadyIn: string[] = [];

    for (const colToAdd of columnsToAdd) {
      if (!columns?.some((col) => col.field === colToAdd.field)) {
        columns?.push(colToAdd);
      } else {
        fieldsAlreadyIn.push(colToAdd.field);
      }
    }

    table?.insertColumns(columns);

    previousAddedCols.current = {};

    for (const col of value) {
      if (!fieldsAlreadyIn.includes(col.field)) {
        previousAddedCols.current[col.field] = true;
      }
    }
  }, []);

  const filtersRow = useMemo(() => {
    return prepareRow();
  }, [prepareRow]);

  const FiltersRowComponent = useMemo(() => {
    return (
      <Box>
        <FilterRowContraints
          rowToRender={filtersRow}
          onRowChange={onFiltersChange}
          tabType={tab}
          updateTableColumns={addToColumns}
          setActiveTemplate={setActiveTemplate}
          getActiveTemplate={getActiveTemplate}
          uriParams={params as any}
        />
      </Box>
    );
  }, [
    addToColumns,
    filtersRow,
    getActiveTemplate,
    onFiltersChange,
    params,
    tab,
  ]);

  const handleHeaderMenuActions = useCallback(
    async (e, column, actionTag) => {
      const field = column.getField();

      switch (actionTag) {
        case "avg": {
          const table = column.getTable();
          const status = table.status;

          const handleAvg = async () => {
            let screening = prepareScreeningReq(filters);

            if (isSearchActive) {
              screening = {
                sort: screening.sort,
                page: { page: 1, rows: 20000 },
                filters: [{ dimension: "symbol", segments: [...foundRows] }],
              };
            }
            screening["page"]["rows"] = 20000;
            const universe =
              instrumentsApi.convertToIndexBuilderSyntax(screening);

            if (status != null) {
              await status.updateAvgRow(field, universe);

              const row = status.get("avgRow");
              setAvgRow(deepClone(row));
            }
          };

          const row = status.get("avgRow");

          if (row != null && field in row && row[field] != null) {
            const cells = column.getCells();
            let tableRow: any = null;

            for (const cell of cells) {
              tableRow = cell.getRow();

              if (tableRow.isFrozen()) {
                let rowCells = tableRow.getCells();
                let i = 0;
                let cellValue: any = null;

                for (const cell of rowCells) {
                  cellValue = cell.getValue();
                  if (cellValue != null && cellValue !== "Average") {
                    if (cell.getField() === field) {
                      cell.setValue(undefined);
                    } else {
                      i++;
                    }
                  }
                }

                const isRowEmpty = i === 0;

                if (isRowEmpty) {
                  tableRow.delete();
                  status.clear("avgRow");

                  setAvgRow(undefined);
                } else {
                  delete row[field];

                  status.updateState("avgRow", row);
                  setAvgRow(deepClone(row));
                }

                break;
              }
            }

            const definition = column.getDefinition();
            const menu = definition.headerMenu;

            const removeAction = menu.find((item) => item.actionTag === "avg");

            removeAction["label"] = "&#956; Average";

            column.updateDefinition({ headerMenu: menu });
          } else {
            const definition = column.getDefinition();
            const menu = definition.headerMenu;

            const removeAction = menu.find((item) => item.actionTag === "avg");

            if (removeAction) {
              removeAction["label"] = "X Remove Average";

              column.updateDefinition({ headerMenu: menu });
            }

            handleAvg();
          }

          break;
        }
        case "sortAsc": {
          const table = tableRef.current?.getInstance();

          table?.sort(field, "asc");
          onDataSorted({ field: field, direction: "asc" });
          break;
        }
        case "sortDesc": {
          const table = tableRef.current?.getInstance();

          table?.sort(field, "desc");
          onDataSorted({ field: field, direction: "desc" });
          break;
        }
      }
    },
    [
      filters,
      foundRows,
      instrumentsApi,
      isSearchActive,
      onDataSorted,
      prepareScreeningReq,
    ]
  );

  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      headerSort: onDataSorted,
      rowClick: rowClickOverride,
      menuHeaderClick: handleHeaderMenuActions,
      columnsLoaded: (columns) => {
        if (columns.length) {
          setTableStatus((current) => ({ ...current, columns: true }));
        }
      },
      onTableBuilt: () => {
        setTableStatus((current) => ({ ...current, built: true }));
      },
    }),
    [handleHeaderMenuActions, onDataSorted, rowClickOverride]
  );

  const handleSearchTitle = useCallback(
    async (symbols: string[]) => {
      setIsLoading(true);
      setFoundRows(symbols);
      clearAvgRow();

      if (!isRankOn) {
        try {
          const filterCopy: any = [];

          if (filters) {
            for (const f of filters as any) {
              filterCopy.push(f);
            }
          }

          filterCopy.push({
            type: "filters",
            filter: [{ dimension: "symbol", segments: symbols }],
          });
          const payload = prepareScreeningReq(filterCopy);
          const rows = await instrumentsApi.screening(payload);

          const properties = getPropertiesFromCols();
          setDataTotalCount(rows.dataTotalCount);
          const fetchRows = await instrumentsApi.fetch({
            properties,
            symbols: rows?.data ?? [],
            type: "security",
          });

          setSelectedRow(fetchRows?.data?.[0]);
          setData(fetchRows?.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const { rows, total } = await getRowsFromRanking(symbols);
          setDataTotalCount(total);
          setSelectedRow(rows[0]);
          setData(rows);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      clearAvgRow,
      filters,
      getPropertiesFromCols,
      getRowsFromRanking,
      instrumentsApi,
      isRankOn,
      prepareScreeningReq,
      setData,
    ]
  );

  const onClearSearch = useCallback(() => {
    setFoundRows([]);
    clearAvgRow();

    if (!isRankOn) {
      screen(filters);
    } else {
      onRank({
        page: 1,
      });
    }
  }, [clearAvgRow, filters, isRankOn, onRank, screen]);

  const toolsEvents = useMemo(
    () => ({
      onExpand: onExpandTable,
      onChangePage,
      onChangeRowNumber: onSelectItemPerPage,
      onColumnsEdit: onColumnsChange,
      onChangeRankDate: changeRankDateFrom,
      handleSearchResult: handleSearchTitle,
      onClearSearch,
      onClickCard: setSelectedRow,
    }),
    [
      changeRankDateFrom,
      handleSearchTitle,
      onChangePage,
      onClearSearch,
      onColumnsChange,
      onExpandTable,
      onSelectItemPerPage,
    ]
  );

  const retriveUniverseDispersion = useCallback(async () => {
    setLoadingDispersion(true);

    try {
      const clusterAPI = new ClusterAnalytics(environment.get("setup"));
      const analytics = ["pq#avg#false", "ps#avg#false"];

      const clusters = [
        {
          dimension: "rc",
          transform: {
            function: "ranges",
            params: { segments: [{ "<": 0 }, { ">": 0 }] },
          },
        },
      ];

      const screening = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
        page: { page: 1, rows: 20000 },
        sort: [{ dimension: "marketcap", rev: false }],
      };

      if (filters != null) {
        for (const filter of filters as any) {
          const { filter: filterValue, type } = filter;

          if (type in screening) {
            screening[type] = [...screening[type], ...filterValue];
          } else {
            screening[type] = [...filterValue];
          }
        }
      }

      const universe = instrumentsApi.convertToIndexBuilderSyntax(screening);

      let allStats: any = clusterAPI
        .createConfiguration()
        .analytics(analytics)
        .clusters([])
        .method("DRILL_DOWN")
        .universeFromConstraints(universe);

      let abCdStats: any = clusterAPI
        .createConfiguration()
        .analytics(analytics)
        .clusters(clusters)
        .method("DRILL_DOWN")
        .universeFromConstraints(universe);

      const promises = [allStats.fetchAnalytics(), abCdStats.fetchAnalytics()];
      const results = await Promise.all(promises);

      allStats = results?.[0];
      abCdStats = results?.[1];

      const dispersion = {
        AB: {
          "3_months": abCdStats?.clustersStats?.stats?.["1"]?.["pq#avg#false"],
          "6_months": abCdStats?.clustersStats?.stats?.["1"]?.["ps#avg#false"],
        },
        CD: {
          "3_months": abCdStats?.clustersStats?.stats?.["0"]?.["pq#avg#false"],
          "6_months": abCdStats?.clustersStats?.stats?.["0"]?.["ps#avg#false"],
        },
        ALL: {
          "3_months":
            allStats?.clustersStats?.stats?.ANY?.["pq#avg#false"] ?? null,
          "6_months": allStats?.clustersStats?.stats?.ANY?.["ps#avg#false"],
        },
      };

      setUniverseDispersion(dispersion);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDispersion(false);
    }
  }, [environment, filters, instrumentsApi, tab]);

  const onChangeExtraInfoTab = useCallback(async (value) => {
    setTabInfoValue(value);
  }, []);

  useEffect(() => {
    if (tabInfoValue === 1) {
      retriveUniverseDispersion();
    }
  }, [retriveUniverseDispersion, tabInfoValue, filters]);

  const toolsConfig: any = useMemo(
    () => ({
      addToButton: true,
      expandTable: { enabled: true },
      configurator: {
        hasToSkipLastApplied: false,
        defaultTemplateNameBase: "DEFAULT_SCREENING",
        configurations: configurationScreening.widgets.viewer,
        securityType: tab,
        isSaveLastUsedConfigurationColumnsEnabled: true,
      },
      search: { enabled: true },
      rank: {
        showRankTools: isRankOn,
      },
      pagination: {
        dataTotalCount,
      },
      viewAsListButton: true,
      rowsNumberSelect: {
        enabled: true,
        label: "Securities per page",
      },
      customTools: {
        children: (
          <DispersionToggle
            dataTotalCount={dataTotalCount}
            onChangeExtraInfoTab={onChangeExtraInfoTab}
          />
        ),
      },
    }),
    [
      configurationScreening.widgets.viewer,
      dataTotalCount,
      isRankOn,
      onChangeExtraInfoTab,
      tab,
    ]
  );

  return (
    <ErrorBoundary
      fallback={
        <Typography>
          An unknown error occures during page loading, please refresh the page,
          if the error persists contact our customers support
        </Typography>
      }
    >
      {showRankDialog ? (
        <RankDialog
          page={"screening"}
          closeDialog={closeRankDialog}
          onRankedCallback={onFirstRankFinish}
        />
      ) : (
        <></>
      )}
      {showTemplateSaveDialog ? (
        <DialogSaveComponent
          onSave={
            activeTemplate?.id != null && activeTemplate.isReadOnly === false
              ? onSaveTemplate
              : null
          }
          onSaveAs={onCreateTemplate}
          onRename={
            activeTemplate?.id != null && activeTemplate.isReadOnly === false
              ? onSaveTemplate
              : null
          }
          item={{ name: activeTemplate?.name ?? "" }}
          dialogType="Screening rules"
          hide={() => setShowTemplateSaveDialog(false)}
        />
      ) : (
        <></>
      )}
      {showDeleteTemplateDialog ? (
        <DeleteScreeningDialog
          item={activeTemplate!}
          onClose={() => setShowDeleteTemplateDialog(false)}
        />
      ) : (
        <></>
      )}
      <Box className={styles.mainWrapper}>
        <Tabs value={tab} onChange={handleTabChange}>
          {optionsList.map((item) => (
            <Tab key={uuidv4()} label={item.label} value={item.value} />
          ))}
        </Tabs>
        <Box className={styles.tabContent}>
          {FiltersRowComponent}
          <Box className={styles.contentWrapper}>
            <Box
              className={styles.tablePanel}
              sx={{ width: expand === false ? "80%" : "100%" }}
            >
              <Card
                sx={{ flex: 1, minHeight: 0, display: "flex", minWidth: 0 }}
              >
                <CardContent
                  sx={{ flex: 1, minHeight: 0, display: "flex", minWidth: 0 }}
                >
                  <TrendratingTableV2
                    ref={tableRef}
                    tools={toolsConfig}
                    toolsEvents={toolsEvents}
                    tableEvents={tableEvents}
                    avgMenu
                    rowTooltipFormatter
                  />
                </CardContent>
              </Card>
            </Box>
            {expand === false && (
              <Box className={styles.sidePanel}>
                {/* <Tabs value={tabInfoValue} onChange={onChangeExtraInfoTab}>
                  <Tab value={0} label={selectedRow?.name ?? "Security"} />
                  <Tab value={1} label={"Dispersion"} />
                </Tabs> */}
                {tabInfoValue === 0 ? (
                  <>
                    <Card sx={{ flex: 1, minHeight: 0, display: "flex" }}>
                      {isLoading ? (
                        <CardContent
                          sx={{ flex: 1, minHeight: 0, display: "flex" }}
                        >
                          <TableSkeleton rows={4} />
                        </CardContent>
                      ) : (
                        <CardContent
                          sx={{ flex: 1, minHeight: 0, display: "flex" }}
                        >
                          {selectedRow && (
                            <SecurityPanel symbol={selectedRow.symbol} />
                          )}
                        </CardContent>
                      )}
                    </Card>

                    {isLoading ? (
                      <Card sx={{ flex: 1, minHeight: 0, display: "flex" }}>
                        <CardContent
                          sx={{ flex: 1, minHeight: 0, display: "flex" }}
                        >
                          <TableSkeleton rows={4} />
                        </CardContent>
                      </Card>
                    ) : (
                      <>
                        {selectedRow && (
                          <FundamentalsTable
                            tab={tab}
                            data={selectedRow}
                            availableColumns={
                              configurationScreening["widgets"]["viewer"][
                                "columns_available"
                              ]
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <DispersionOverview
                    loading={loadingDispersion}
                    data={universeDispersion}
                  ></DispersionOverview>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

const DispersionOverview = ({ data, loading }: DispersionOverviewType) => {
  const fmt = useFormatter();

  const getPerc = useCallback(
    (value) => {
      return fmt.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [fmt]
  );

  return (
    <Card>
      <CardContent>
        {loading ? (
          <TableSkeleton rows={3} />
        ) : (
          <Box>
            <Box p={1} borderBottom={"1px solid #2a7090"} mb={2}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: "0.8vw",
                  color: "#2a7090",
                  marginBottom: "10px",
                }}
              >
                Average Performance
              </Typography>
            </Box>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Rating</th>
                  <th style={{ textAlign: "right" }}>3 Months</th>
                  <th style={{ textAlign: "right" }}>6 Months</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>All</strong>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.ALL?.["3_months"])}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.ALL?.["6_months"])}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="format-rate format-rate--A">A</span>
                    <span className="format-rate format-rate--B">B</span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.AB?.["3_months"])}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.AB?.["6_months"])}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="format-rate format-rate--C">C</span>
                    <span className="format-rate format-rate--D">D</span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.CD?.["3_months"])}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {getPerc(data?.CD?.["6_months"])}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const DispersionToggle = ({ dataTotalCount, onChangeExtraInfoTab }) => {
  const [active, setActive] = useState(false);

  const toggleDispersion = useCallback(() => {
    setActive(!active);
  }, [active]);

  useEffect(() => {
    if (active) {
      onChangeExtraInfoTab(1);
    } else {
      onChangeExtraInfoTab(0);
    }
  }, [active, onChangeExtraInfoTab]);

  return dataTotalCount < 5000 ? (
    <Box
      title={"Enable/disable AVERAGE PERFORMACE"}
      sx={{
        border: active ? "2px solid #2a7090" : "1px solid #2a7090",
        display: "flex",
        borderRadius: "6px",
        padding: "2px",
        cursor: "pointer",
      }}
      onClick={toggleDispersion}
    >
      <span className="format-rate format-rate--A">A</span>
      <span className="format-rate format-rate--B">B</span> |
      <span className="format-rate format-rate--C">C</span>
      <span className="format-rate format-rate--D">D</span>
    </Box>
  ) : (
    <></>
  );
};
