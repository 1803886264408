/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/account/Sessions
 * @summary Requests for sessions
 *
 */

import { endpoints } from "../endpoints";
import { _Base } from "../_Base";
import { decodeUser } from "./Decoder";

export class Sessions extends _Base {
  /**
   * Checks if the current session is valid or expired
   */
  async isValid() {
    const endPointRoot = this.getEndpointRoot(this.environment.api.account);
    const url = endPointRoot + endpoints.accounts.sessions.get;

    const response = await this.prepareGet(url, {
      testget: "check",
    });

    return decodeUser(response);
  }

  async logout() {
    const endPointRoot = this.getEndpointRoot(this.environment.api.account);
    const url = endPointRoot + endpoints.accounts.sessions.remove;

    const response = await this.prepareGet(url, null);

    return response;
  }

  async setProduct(productId: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.account);
    const url = endPointRoot + endpoints.accounts.sessions.setProduct;

    const response = await this.prepareGet(url, {
      productId: productId,
      userId: this.environment.account.user?.id,
    });

    // This is the point where keys in config are mapped into values used to evaluate "#" conditions
    const supportedVariables = {
      permissions: this.environment.account.user!.permissions,
      productCode: response.data.product.productCode,
      userType: this.environment.account.user!.group,
    };

    this.productCompile(response, supportedVariables);
    const compiledProduct = response;

    if (this.environment.account.user) {
      this.environment.account.user.product = JSON.parse(
        JSON.stringify(compiledProduct)
      );
    }

    return compiledProduct;
  }

  productCompile(response, supportedVariables) {
    let strToEval = "";

    if (Array.isArray(response)) {
      for (let item of response) {
        if (typeof item === "string" && item.startsWith("#")) {
          strToEval = item.substring(1);
          strToEval = strToEval.replace(/'/g, '"'); // Make it a valid JSON string
          let [expression, passResult, notPassResult] = JSON.parse(strToEval);

          let itemIdx = response.indexOf(item);
          response[itemIdx] = this.evaluateExpression(
            expression,
            supportedVariables
          )
            ? passResult
            : notPassResult;
        } else {
          this.productCompile(item, supportedVariables);
        }
      }
    } else if (typeof response === "object") {
      for (const key in response) {
        if (typeof response[key] === "object") {
          this.productCompile(response[key], supportedVariables);
        } else if (
          typeof response[key] === "string" &&
          response[key].startsWith("#")
        ) {
          strToEval = response[key].substring(1);
          strToEval = strToEval.replace(/'/g, '"'); // Make it a valid JSON string
          let [expression, passResult, notPassResult] = JSON.parse(strToEval);

          response[key] = this.evaluateExpression(
            expression,
            supportedVariables
          )
            ? passResult
            : notPassResult;
        }
      }
    }
  }

  evaluateExpression(expression, supportedVariables) {
    if (typeof expression === "object" && !Array.isArray(expression)) {
      const operator = Object.keys(expression)[0];
      const operands = expression[operator];
      switch (operator) {
        case "and":
          return operands.every((expr) =>
            this.evaluateExpression(expr, supportedVariables)
          );
        case "or":
          return operands.some((expr) =>
            this.evaluateExpression(expr, supportedVariables)
          );
        case "not":
          return !this.evaluateExpression(
            expression[operator],
            supportedVariables
          );
        default:
          throw new Error(`Unknown operator: ${operator}`);
      }
    } else if (typeof expression === "object" && Array.isArray(expression)) {
      const [left, operator, right] = expression;
      const leftValue = supportedVariables?.[left] ?? left;
      const rightValue = supportedVariables[right] ?? right;

      switch (operator) {
        case "in":
          return rightValue.includes(leftValue);
        case "==":
          return leftValue === rightValue;
        case "!=":
          return leftValue !== rightValue;
        default:
          throw new Error(`Unknown operator: ${operator}`);
      }
    } else {
      throw new Error("Invalid expression format");
    }
  }
}
