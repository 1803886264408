import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Menu, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Instruments } from "../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { TableV2 } from "../../TableCoreV2";
import styles from "./Search.module.scss";
import ReactDOM from "react-dom";

type SearchProps = {
  tableInstance: TableV2;
  selectRow: (symbol: string[]) => void;
  onClear: () => void;
  renderNodeId?: string;
};

const focusedCloseBtnClass = styles.closeBtn__focused;

export function Search({
  tableInstance,
  selectRow,
  onClear,
  renderNodeId,
}: SearchProps) {
  const [showInput, setShowInput] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<any>();
  const [universe, setUniverse] = useState<string[]>([]);
  const [foundRows, setFoundRows] = useState<any>([]);
  const [submitted, setSubmitted] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<string[]>([]);

  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const inputContainerRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const tableNodeRef = useRef<HTMLTableElement>(null);

  const isMenuOpen = useMemo(() => menuAnchor != null, [menuAnchor]);
  const foundedItems = useMemo(() => foundRows.length, [foundRows.length]);
  const multipleSelection = useMemo(
    () => currentSelected.length > 0,
    [currentSelected.length]
  );

  const environment = useEnvironment();

  const instrumnetsApi = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );

  const rowsToShow = useMemo(() => {
    const newRows = foundRows.slice(0, 10);

    return newRows;
  }, [foundRows]);

  const openMenu = useCallback(() => {
    if (inputContainerRef.current) {
      setMenuAnchor(inputContainerRef.current);
    }
  }, []);

  const openInput = useCallback(async () => {
    setShowInput(true);
    const input = inputRef.current;

    if (input) {
      input.focus();
    }

    const universeConstraints = tableInstance.getSearchContext();
    const screeningSyntax = "constraints" in universeConstraints;

    if (universeConstraints) {
      try {
        const symbolSet = await instrumnetsApi.screening(
          universeConstraints,
          screeningSyntax
        );

        setUniverse(symbolSet.data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [instrumnetsApi, tableInstance]);

  const blurCloseBtn = useCallback(() => {
    const btn = closeBtnRef.current;

    if (btn) {
      if (btn.classList.contains(focusedCloseBtnClass)) {
        btn.classList.remove(focusedCloseBtnClass);
      }
    }
  }, []);

  const onLoosingFocus = useCallback(() => {
    blurCloseBtn();
  }, [blurCloseBtn]);

  const focusCloseBtn = useCallback(() => {
    const btn = closeBtnRef.current;

    if (btn) {
      if (!btn.classList.contains(focusedCloseBtnClass)) {
        btn.classList.add(focusedCloseBtnClass);
      }
    }
  }, []);

  const hideAndClear = useCallback(() => {
    setShowInput(false);

    blurCloseBtn();

    const input = inputRef.current;

    if (input) {
      input.value = "";
    }

    if (submitted) {
      onClear();
    }

    setCurrentSelected([]);
  }, [blurCloseBtn, onClear, submitted]);

  const closeMenu = useCallback(() => {
    setMenuAnchor(undefined);
    hideAndClear();
  }, [hideAndClear]);

  const applyFocusOnCancelIcon = useCallback(
    (event) => {
      focusCloseBtn();
    },
    [focusCloseBtn]
  );

  const search = useCallback(
    async (query) => {
      if (universe && universe.length) {
        const payload = {
          filters: [{ dimension: "symbol", segments: universe }],
          page: { page: 1, rows: 20000 },
        };
        const serachResult = await instrumnetsApi.searchWithContext(
          { query, universe: payload },
          "security"
        );

        const properties = [
          { date: null, property: "name" },
          { date: null, property: "type" },
          { date: null, property: "ticker" },
        ];

        const rows = await instrumnetsApi.fetch({
          type: "security",
          symbols: serachResult.data,
          properties,
        });

        setFoundRows(rows.data);
      }
    },
    [instrumnetsApi, universe]
  );

  const handleChangeInput = useCallback(
    (event) => {
      const inputStr = event.target.value;

      if (inputStr.length < 3) {
        return;
      }

      if (!isMenuOpen) {
        openMenu();
      }

      search(inputStr);
    },
    [isMenuOpen, openMenu, search]
  );

  const handleSubmitResult = useCallback(
    (symbol: string) => {
      selectRow([symbol]);
      setSubmitted(true);
      setMenuAnchor(undefined);
    },
    [selectRow]
  );

  const selectAllFounded = useCallback(() => {
    if (multipleSelection) {
      selectRow([...currentSelected]);
      setSubmitted(true);
      setMenuAnchor(undefined);
      setCurrentSelected([]);
    } else if (foundRows.length) {
      selectRow(foundRows.map((r) => r.symbol));
      setSubmitted(true);
      setMenuAnchor(undefined);
    }
  }, [currentSelected, foundRows, multipleSelection, selectRow]);

  const handleInputPressEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        selectAllFounded();
      }
    },
    [selectAllFounded]
  );

  const handleRowSelection = useCallback(
    (event) => {
      let symbol = "";
      let row: any = null;

      if (event.target.tagName !== "TR") {
        let node = event.target;

        while (node.tagName !== "TR" && node.tagName !== "TABLE") {
          node = node.parentNode;
        }

        row = node;
        symbol = row.dataset.id;
      } else {
        symbol = event.target.dataset.id;
      }

      if (event.ctrlKey || event.altKey) {
        const isSelected = currentSelected.includes(symbol);

        if (isSelected) {
          setCurrentSelected((current) => current.filter((s) => s !== symbol));
        } else {
          setCurrentSelected((current) => {
            const newState = [...current];
            newState.push(symbol);

            return newState;
          });
        }
      } else {
        handleSubmitResult(symbol);
      }
    },
    [currentSelected, handleSubmitResult]
  );

  if (renderNodeId) {
    const node = document.getElementById(renderNodeId);

    if (node == null) {
      return <></>;
    }

    return ReactDOM.createPortal(
      <Box className={styles.searchBox} sx={{ width: showInput ? "100%" : 0 }}>
        <button
          className={`${styles.button} ${showInput ? styles.button__open : ""}`}
          title="Find inside results"
          onClick={openInput}
        >
          <span className="i-search"></span>
        </button>
        <Box
          ref={inputContainerRef}
          className={`${styles.searchInput} ${
            showInput ? styles.searchInput__open : ""
          }`}
        >
          <input
            title={"Press enter to select all found rows"}
            type="text"
            placeholder="Ticker, Name, ISIN"
            ref={inputRef}
            onFocus={applyFocusOnCancelIcon}
            onBlur={onLoosingFocus}
            onChange={handleChangeInput}
            onKeyDown={handleInputPressEnter}
          />
          <button
            ref={closeBtnRef}
            className={styles.closeBtn}
            onClick={hideAndClear}
          >
            <CancelIcon fontSize="small" />
          </button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchor}
          open={isMenuOpen}
          onClose={closeMenu}
          disableAutoFocus={true}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-list": {
              padding: 0,
            },
          }}
        >
          <Box minWidth={"25vw"}>
            <table className={styles.resultsTable} ref={tableNodeRef}>
              <tbody>
                {rowsToShow.map((item) => {
                  return (
                    <tr
                      data-id={item.symbol}
                      title="Click to select, Ctrl+Click to select more than one"
                      key={uuidv4()}
                      onClick={handleRowSelection}
                      className={
                        currentSelected.includes(item.symbol)
                          ? styles.rowSelected
                          : ""
                      }
                    >
                      <td>
                        <Typography>
                          <strong>{item.ticker}</strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>{item.name}</Typography>
                      </td>
                      <td>
                        <Typography>{item.type}</Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
          <Box className={styles.menuFooter}>
            {rowsToShow.length < foundedItems ? (
              <Typography>
                Found <strong>{rowsToShow.length}</strong> of{" "}
                <strong>{foundedItems}</strong> results corresponding to{" "}
                <span style={{ fontStyle: "italic" }}>
                  '{inputRef.current?.value ?? ""}'
                </span>
              </Typography>
            ) : (
              <Typography>
                Found <strong>{foundedItems}</strong> results corresponding to{" "}
                <span style={{ fontStyle: "italic" }}>
                  '{inputRef.current?.value ?? ""}'
                </span>
              </Typography>
            )}

            <Button
              size="small"
              disabled={foundedItems < 1}
              onClick={selectAllFounded}
            >
              {multipleSelection ? "Show selected" : "Select All"}
            </Button>
          </Box>
        </Menu>
      </Box>,
      node
    );
  } else {
    return (
      <Box className={styles.searchBox} sx={{ width: showInput ? "100%" : 0 }}>
        <button
          className={`${styles.button} ${showInput ? styles.button__open : ""}`}
          title="Find inside results"
          onClick={openInput}
        >
          <span className="i-search"></span>
        </button>
        <Box
          ref={inputContainerRef}
          className={`${styles.searchInput} ${
            showInput ? styles.searchInput__open : ""
          }`}
        >
          <input
            title={"Press enter to select all found rows"}
            type="text"
            placeholder="Ticker, Name, ISIN"
            ref={inputRef}
            onFocus={applyFocusOnCancelIcon}
            onBlur={onLoosingFocus}
            onChange={handleChangeInput}
            onKeyDown={handleInputPressEnter}
          />
          <button
            ref={closeBtnRef}
            className={styles.closeBtn}
            onClick={hideAndClear}
          >
            <CancelIcon fontSize="small" />
          </button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchor}
          open={isMenuOpen}
          onClose={closeMenu}
          disableAutoFocus={true}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-list": {
              padding: 0,
            },
          }}
        >
          <Box minWidth={"25vw"}>
            <table className={styles.resultsTable} ref={tableNodeRef}>
              <tbody>
                {rowsToShow.map((item) => {
                  return (
                    <tr
                      data-id={item.symbol}
                      title="Click to select, Ctrl+Click to select more than one"
                      key={uuidv4()}
                      onClick={handleRowSelection}
                      className={
                        currentSelected.includes(item.symbol)
                          ? styles.rowSelected
                          : ""
                      }
                    >
                      <td>
                        <Typography>
                          <strong>{item.ticker}</strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>{item.name}</Typography>
                      </td>
                      <td>
                        <Typography>{item.type}</Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
          <Box className={styles.menuFooter}>
            {rowsToShow.length < foundedItems ? (
              <Typography>
                Found <strong>{rowsToShow.length}</strong> of{" "}
                <strong>{foundedItems}</strong> results corresponding to{" "}
                <span style={{ fontStyle: "italic" }}>
                  '{inputRef.current?.value ?? ""}'
                </span>
              </Typography>
            ) : (
              <Typography>
                Found <strong>{foundedItems}</strong> results corresponding to{" "}
                <span style={{ fontStyle: "italic" }}>
                  '{inputRef.current?.value ?? ""}'
                </span>
              </Typography>
            )}

            <Button
              size="small"
              disabled={foundedItems < 1}
              onClick={selectAllFounded}
            >
              {multipleSelection ? "Show selected" : "Select All"}
            </Button>
          </Box>
        </Menu>
      </Box>
    );
  }
}
