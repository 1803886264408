import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnSet } from "../components/table/tableUtilities/ColumnSet";
import { deepClone } from "../deepClone";
import { useActionModal } from "../js/app/utils/useActionModal";
import ReportWizzard from "../js/app/widgets/app-infrastructure/workflowBar/actions/report/ReportWizzard";
import { Section } from "../js/trendrating-report/widgets/Section";
import { useEnvironment } from "./useEnvironment";
import style from "./useReport.module.scss";
import { FormOptions } from "../js/trendrating-widgets/form/FormOptions";

type ReportProps = {
  page: string;
  target: any;
  rankingCache?: any;
  strategyCache?: any;
  widgets?: any;
  storage?: any;
  usage?: any;
  timeframe?: any;
  trimOutliers?: any;
  dispersionByConstraints?: any;
  segment?: any;
  sortState?: any;
  title?: any;
  subject?: any;
};

export const useReport = ({ page }: { page: string }) => {
  const { t } = useTranslation();
  const actionModal = useActionModal();
  const environment = useEnvironment();
  const [reportParams, setReportParams] = useState<ReportProps>({
    page: page,
    target: null,
    rankingCache: null,
    widgets: null,
    storage: null,
    usage: null,
    timeframe: null,
    trimOutliers: null,
    dispersionByConstraints: null,
    segment: null,
    sortState: null,
    title: null,
    subject: null,
  });

  const setup = useMemo(() => environment.get("setup"), [environment]);
  const productFlavourCode = useMemo(
    () => setup["configuration"].get("product")["flavourCode"],
    [setup]
  );
  const http = useMemo(() => environment.get("http"), [environment]);

  const [columnsAvailable, setColumnsAvailable] = useState<any>(null);
  const [wysiwygState, setWysiwygState] = useState<any>(null);

  const httpTemplates = useMemo(() => {
    switch (reportParams?.page) {
      case "dispersionBasketTab":
      case "analysisList":
        return http["portfolioReportTemplates"];
      case "analysisMarkets":
        return http["peerReportTemplates"];
      case "analysisMarketsDetail":
        return http["peerReportTemplates"];
      case "ranking":
        return http["rankingReportTemplates"];
      case "screening":
        return http["screeningReportTemplates"];
      case "strategyBuilder":
        return http["strategyReportTemplates"];
      case "strategyLongShort":
        return http["strategyLongShortReportTemplates"];
      case "systematicPortfolios":
        return http["systematicPortfoliosReportTemplates"];
      default:
        return null;
    }
  }, [http, reportParams?.page]);

  const _formatSize = useCallback(
    (value) => {
      if (value === "microLarge") {
        return t("All_cap");
      }

      const sizes = FormOptions.get("SIZE");
      const sizesMap = {};
      for (let i = 0, length = sizes.length; i < length; i++) {
        const item = sizes[i];
        sizesMap[item.value] = item;
      }

      // single interval
      let valueEnd = value;
      let valueStart = value;
      // multiple interval
      const cutoffIndex = value.search(/[A-Z]/g);
      if (cutoffIndex !== -1) {
        valueEnd = value.substring(cutoffIndex).toLowerCase();
        valueStart = value.substring(0, cutoffIndex);
      }

      if (valueStart === valueEnd) {
        return sizesMap[valueStart]["label"] + " " + t("cap");
      }

      return [
        sizesMap[valueStart]["label"],
        "-",
        sizesMap[valueEnd]["label"],
        t("cap"),
      ].join(" ");
    },
    [t]
  );

  const getDefaultTemplates = useCallback(() => {
    const _section = new Section(setup);
    var defaultTemplatesPromise = _section.getDefaultTemplate(
      reportParams?.page
    );
    return defaultTemplatesPromise.then((defaultTemplates) => {
      // Prepare correct structure
      var preparedDefaultTemplates: any[] = [];

      if (reportParams?.page === "analysisMarketsDetail") {
        let marketDetailDefault = defaultTemplates[0]["detailed"];
        preparedDefaultTemplates.push(
          httpTemplates.template(
            httpTemplates.decode(httpTemplates.migrate(marketDetailDefault))
          )
        );

        marketDetailDefault = defaultTemplates[0]["dispersion"];
        preparedDefaultTemplates.push(
          httpTemplates.template(
            httpTemplates.decode(httpTemplates.migrate(marketDetailDefault))
          )
        );

        marketDetailDefault = defaultTemplates[0]["trendsTracker"];
        preparedDefaultTemplates.push(
          httpTemplates.template(
            httpTemplates.decode(httpTemplates.migrate(marketDetailDefault))
          )
        );
      } else {
        for (var i = 0, length = defaultTemplates.length; i < length; i++) {
          preparedDefaultTemplates.push(
            httpTemplates.template(
              httpTemplates.decode(httpTemplates.migrate(defaultTemplates[i]))
            )
          );
        }
      }

      return preparedDefaultTemplates;
    });
  }, [httpTemplates, reportParams?.page, setup]);

  const _getUiState = useCallback(() => {
    // TODO long short strategy is part of strategyBuilder
    // temporary change of page type
    let _page = page;
    if (_page === "strategyLongShort") {
      _page = "strategyBuilder";
    }

    if (reportParams.target == null) {
      return;
    }

    let _reportParams = { ...reportParams };
    var columnsAvailable: any[] = [];
    var wysiwygState: any = {
      actions: {
        constraints: null,
        rank: null,
        sortBy: null,
      },
      columns: null,
      dataTotalCount: 0,
      promiseCache: null,

      // 2020-12-10 Markets: new way to do stuff using storages
      storage: null,

      target: null,
      targetType: null,
    };

    var environmentSetup = environment.get("setup");
    var pageConfiguration = environmentSetup["configuration"].get(_page);
    var target = deepClone(_reportParams.target);
    switch (_page) {
      case "dispersionBasketTab": {
        // columnsAvailable =
        //     pageConfiguration.tabs[1].widgets.viewer[
        //         "columns_available"
        //     ];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "LIST";

        const dispersionTabWidget =
          _reportParams.widgets?.["dispersion"] ?? null;

        if (dispersionTabWidget != null) {
          const dispersionParams = dispersionTabWidget.get("wysiwygState");

          const constraints = {
            intervals: dispersionParams?.intervals ?? 25,
            performanceTimeframe: dispersionParams?.period ?? "3_months",
          };
          wysiwygState.trimOutliers = dispersionParams?.trimOutliers ?? false;
          wysiwygState.dispersionByConstraints = constraints;
          wysiwygState.segment = dispersionParams?.segment ?? "Country";
          wysiwygState.segmentEtf = dispersionParams?.etfSegment ?? "etfgeo";
          wysiwygState.title = target["name"].replace(",", " - ");
        } else {
          const constraints = {
            intervals: 25,
            performanceTimeframe: "3_months",
          };
          wysiwygState.trimOutliers = false;
          wysiwygState.dispersionByConstraints = constraints;
          wysiwygState.segment = "Country";
          wysiwygState.segmentEtf = "etfgeo";
        }

        break;
      }
      case "analysisList": {
        const holdingTabConfig = pageConfiguration.tabs.find(
          (tab) => tab.id === "holdings"
        );
        columnsAvailable = holdingTabConfig.widgets.viewer["columns_available"];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "LIST";

        break;
      }
      case "analysisMarkets": {
        if (target["name"] === "__ROOT__") {
          target["name"] = t("World_wide");
        }
        target["name"] = target["name"].replace(",", " - ");

        wysiwygState["storage"] = _reportParams.storage;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "PEER";

        break;
      }
      case "analysisMarketsDetail": {
        if (target["name"] === "__ROOT__") {
          target["name"] = t("World_wide");
        }
        target["name"] =
          _reportParams.subject ?? target["name"]?.replace(",", " - ");

        if (target.size !== "microLarge") {
          const peerSize = target?.size;
          const sizeLabel = _formatSize(peerSize);

          target["name"] += ` ${sizeLabel}`;
        }

        wysiwygState["storage"] = _reportParams.storage;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "PEER";

        // Prepare data passed to action for use in wysiwyg
        wysiwygState.timeframe = _reportParams.timeframe;
        wysiwygState.trimOutliers = _reportParams.trimOutliers;
        wysiwygState.dispersionByConstraints =
          _reportParams.dispersionByConstraints;
        wysiwygState.segment = _reportParams.segment;
        wysiwygState.sortState = _reportParams.sortState;
        wysiwygState.title = _reportParams.title;
        wysiwygState.subject = _reportParams.subject;

        break;
      }
      case "ranking": {
        columnsAvailable =
          pageConfiguration.widgets.viewer["columns_available"];

        // Note from Elia
        //
        // ranking (the target) is a container of other targets.
        // At the moment (2020-04-10) I do not have other ideas
        // to manage it in a coherent way with others kind of
        // targets
        var _target = deepClone(target["target"]["object"]);
        _target["name"] = target["name"];

        wysiwygState["target"] = _target;
        wysiwygState["targetType"] =
          target["target"]["type"] === "list" ? "LIST" : "SCREENING";

        break;
      }
      case "screening": {
        columnsAvailable =
          pageConfiguration.widgets.viewer["columns_available"];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "SCREENING";

        break;
      }
      case "strategyBuilder":
      case "strategyLongShort": {
        columnsAvailable =
          pageConfiguration?.widgets?.viewer["columns_available"];

        // TODO - hard coded
        wysiwygState["actions"]["sortBy"] = {
          descending: true,
          property: "weight",
        };

        var columnSet = new ColumnSet({
          elements: {
            columns: pageConfiguration.widgets.viewer["columns"],
            columns_available:
              pageConfiguration.widgets.viewer["columns_available"],
          },
          properties: environmentSetup["properties"],
          customConfiguration: null,
        });
        wysiwygState["columns"] = columnSet.generate(null);

        wysiwygState["promiseCache"] = _reportParams.strategyCache;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "STRATEGY"; // "COMBINED_STRATEGY"
        wysiwygState["storage"] = _reportParams.storage;

        break;
      }
      case "systematicPortfolios": {
        columnsAvailable =
          pageConfiguration.tabs[1].widgets.viewer["columns_available"];

        columnSet = new ColumnSet({
          elements: {
            columns: pageConfiguration.tabs[1].widgets.viewer["columns"],
            columns_available:
              pageConfiguration.tabs[1].widgets.viewer["columns_available"],
          },
          properties: environmentSetup["properties"],
          customConfiguration: null,
        });
        wysiwygState["columns"] = columnSet.generate(null);

        // TODO - hard coded
        wysiwygState["actions"]["sortBy"] = {
          descending: true,
          property: "weight",
        };

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "SYSTEMATIC";

        break;
      } // no default
    }

    // constraints, sortBy and columns
    if (
      _reportParams.widgets != null &&
      _reportParams.widgets["table"] != null
    ) {
      wysiwygState["actions"]["constraints"] =
        _reportParams.widgets["table"].get("constraints");

      wysiwygState["actions"]["avgRow"] =
        _reportParams.widgets["table"].get("avgRow");
      wysiwygState["actions"]["universedispersionActive"] =
        _reportParams.widgets["table"].get("dispersionActive");

      // Check for tables like holdings in portfolio analysis
      // Only if constraints are empty
      if (
        wysiwygState["actions"]["constraints"] == null &&
        _reportParams.widgets["table"] != null &&
        _reportParams.widgets["table"].widgetDataFilter != null
      ) {
        wysiwygState["actions"]["constraints"] =
          _reportParams.widgets["table"].widgetDataFilter.get("value");
      }

      let sortBy = _reportParams.widgets["table"].get("sort");
      //
      // 2021-06-11
      //
      // TODO This is legacy, maybe is not used anymore?
      if (sortBy == null) {
        sortBy = _reportParams.widgets["table"].get("sortBy");
      }
      wysiwygState["actions"]["sortBy"] = sortBy;

      wysiwygState["columns"] = _reportParams.widgets["table"].get("columns");
      wysiwygState["dataTotalCount"] =
        _reportParams.widgets["table"].get("dataTotalCount");
    }

    // TODO remove and refactor it with only wysiwygState["rankingCache"]
    if (_reportParams.rankingCache != null) {
      wysiwygState["actions"]["rank"] = {
        date: _reportParams.rankingCache["rankingParams"]["fromDate"],
        list: _reportParams.rankingCache["rankingParams"]["list"],
        rules: _reportParams.rankingCache["rankingParams"]["rules"],
        universe:
          _reportParams.rankingCache["rankingParams"]["againstUniverse"],
      };
    }

    // TODO patch, used in report Generator.js, please remove it later
    // and use only actions/rank
    wysiwygState["rankingCache"] = _reportParams.rankingCache;

    var uiState = {
      columnsAvailable: columnsAvailable,
      wysiwygState: wysiwygState,
    };

    if (appConfig.isDebug === true) {
      console.log("uiState", uiState);
    }

    return uiState;
  }, [_formatSize, environment, page, reportParams, t]);

  useEffect(() => {
    const _uiState = _getUiState();
    if (_uiState?.columnsAvailable) {
      setColumnsAvailable(_uiState.columnsAvailable);
    }
    if (_uiState?.wysiwygState) {
      setWysiwygState(_uiState.wysiwygState);
    }
  }, [_getUiState]);

  const directGetUIState = useCallback(
    (params) => {
      // TODO long short strategy is part of strategyBuilder
      // temporary change of page type
      let _page = page;
      if (_page === "strategyLongShort") {
        _page = "strategyBuilder";
      }

      if (params.target == null) {
        return;
      }

      let _reportParams = { ...params };
      var columnsAvailable: any[] = [];
      var wysiwygState: any = {
        actions: {
          constraints: null,
          rank: null,
          sortBy: null,
        },
        columns: null,
        dataTotalCount: 0,
        promiseCache: null,

        // 2020-12-10 Markets: new way to do stuff using storages
        storage: null,

        target: null,
        targetType: null,
      };

      var environmentSetup = environment.get("setup");
      var pageConfiguration = environmentSetup["configuration"].get(_page);
      var target = deepClone(_reportParams.target);
      switch (_page) {
        case "dispersionBasketTab": {
          // columnsAvailable =
          //     pageConfiguration.tabs[1].widgets.viewer[
          //         "columns_available"
          //     ];

          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "LIST";

          const dispersionTabWidget =
            _reportParams.widgets?.["dispersion"] ?? null;

          if (dispersionTabWidget != null) {
            const dispersionParams = dispersionTabWidget.get("wysiwygState");

            const constraints = {
              intervals: dispersionParams?.intervals ?? 25,
              performanceTimeframe: dispersionParams?.period ?? "3_months",
            };
            wysiwygState.trimOutliers = dispersionParams?.trimOutliers ?? false;
            wysiwygState.dispersionByConstraints = constraints;
            wysiwygState.segment = dispersionParams?.segment ?? "Country";
            wysiwygState.segmentEtf = dispersionParams?.etfSegment ?? "etfgeo";
            wysiwygState.title = target["name"].replace(",", " - ");
          } else {
            const constraints = {
              intervals: 25,
              performanceTimeframe: "3_months",
            };
            wysiwygState.trimOutliers = false;
            wysiwygState.dispersionByConstraints = constraints;
            wysiwygState.segment = "Country";
            wysiwygState.segmentEtf = "etfgeo";
          }

          break;
        }
        case "analysisList": {
          const holdingTabConfig = pageConfiguration.tabs.find(
            (tab) => tab.id === "holdings"
          );
          columnsAvailable =
            holdingTabConfig.widgets.viewer["columns_available"];

          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "LIST";

          break;
        }
        case "analysisMarkets": {
          if (target["name"] === "__ROOT__") {
            target["name"] = t("World_wide");
          }
          target["name"] = target["name"].replace(",", " - ");

          wysiwygState["storage"] = _reportParams.storage;
          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "PEER";

          break;
        }
        case "analysisMarketsDetail": {
          if (target["name"] === "__ROOT__") {
            target["name"] = t("World_wide");
          }
          target["name"] =
            _reportParams.subject ?? target["name"]?.replace(",", " - ");

          wysiwygState["storage"] = _reportParams.storage;
          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "PEER";

          // Prepare data passed to action for use in wysiwyg
          wysiwygState.timeframe = _reportParams.timeframe;
          wysiwygState.trimOutliers = _reportParams.trimOutliers;
          wysiwygState.dispersionByConstraints =
            _reportParams.dispersionByConstraints;
          wysiwygState.segment = _reportParams.segment;
          wysiwygState.sortState = _reportParams.sortState;
          wysiwygState.title = _reportParams.title;
          wysiwygState.subject = _reportParams.subject;

          break;
        }
        case "ranking": {
          columnsAvailable =
            pageConfiguration.widgets.viewer["columns_available"];

          // Note from Elia
          //
          // ranking (the target) is a container of other targets.
          // At the moment (2020-04-10) I do not have other ideas
          // to manage it in a coherent way with others kind of
          // targets
          var _target = deepClone(target["target"]["object"]);
          _target["name"] = target["name"];

          wysiwygState["target"] = _target;
          wysiwygState["targetType"] =
            target["target"]["type"] === "list" ? "LIST" : "SCREENING";

          break;
        }
        case "screening": {
          columnsAvailable =
            pageConfiguration.widgets.viewer["columns_available"];

          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "SCREENING";

          break;
        }
        case "strategyBuilder":
        case "strategyLongShort": {
          columnsAvailable =
            pageConfiguration?.widgets?.viewer["columns_available"];

          // TODO - hard coded
          wysiwygState["actions"]["sortBy"] = {
            descending: true,
            property: "weight",
          };

          var columnSet = new ColumnSet({
            elements: {
              columns: pageConfiguration.widgets.viewer["columns"],
              columns_available:
                pageConfiguration.widgets.viewer["columns_available"],
            },
            properties: environmentSetup["properties"],
            customConfiguration: null,
          });
          wysiwygState["columns"] = columnSet.generate(null);

          wysiwygState["promiseCache"] = _reportParams.strategyCache;
          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "STRATEGY"; // "COMBINED_STRATEGY"
          wysiwygState["storage"] = _reportParams.storage;

          break;
        }
        case "systematicPortfolios": {
          columnsAvailable =
            pageConfiguration.tabs[1].widgets.viewer["columns_available"];

          columnSet = new ColumnSet({
            elements: {
              columns: pageConfiguration.tabs[1].widgets.viewer["columns"],
              columns_available:
                pageConfiguration.tabs[1].widgets.viewer["columns_available"],
            },
            properties: environmentSetup["properties"],
            customConfiguration: null,
          });
          wysiwygState["columns"] = columnSet.generate(null);

          // TODO - hard coded
          wysiwygState["actions"]["sortBy"] = {
            descending: true,
            property: "weight",
          };

          wysiwygState["target"] = target;
          wysiwygState["targetType"] = "SYSTEMATIC";

          break;
        } // no default
      }

      // constraints, sortBy and columns
      if (
        _reportParams.widgets != null &&
        _reportParams.widgets["table"] != null
      ) {
        wysiwygState["actions"]["constraints"] =
          _reportParams.widgets["table"].get("constraints");

        // Check for tables like holdings in portfolio analysis
        // Only if constraints are empty
        if (
          wysiwygState["actions"]["constraints"] == null &&
          _reportParams.widgets["table"] != null &&
          _reportParams.widgets["table"].widgetDataFilter != null
        ) {
          wysiwygState["actions"]["constraints"] =
            _reportParams.widgets["table"].widgetDataFilter.get("value");
        }

        let sortBy = _reportParams.widgets["table"].get("sort");
        //
        // 2021-06-11
        //
        // TODO This is legacy, maybe is not used anymore?
        if (sortBy == null) {
          sortBy = _reportParams.widgets["table"].get("sortBy");
        }
        wysiwygState["actions"]["sortBy"] = sortBy;

        wysiwygState["columns"] = _reportParams.widgets["table"].get("columns");
        wysiwygState["dataTotalCount"] =
          _reportParams.widgets["table"].get("dataTotalCount");
      }

      // TODO remove and refactor it with only wysiwygState["rankingCache"]
      if (_reportParams.rankingCache != null) {
        wysiwygState["actions"]["rank"] = {
          date: _reportParams.rankingCache["rankingParams"]["fromDate"],
          list: _reportParams.rankingCache["rankingParams"]["list"],
          rules: _reportParams.rankingCache["rankingParams"]["rules"],
          universe:
            _reportParams.rankingCache["rankingParams"]["againstUniverse"],
        };
      }

      // TODO patch, used in report Generator.js, please remove it later
      // and use only actions/rank
      wysiwygState["rankingCache"] = _reportParams.rankingCache;

      var uiState = {
        columnsAvailable: columnsAvailable,
        wysiwygState: wysiwygState,
      };

      if (appConfig.isDebug === true) {
        console.log("uiState", uiState);
      }

      return uiState;
    },
    [environment, page, t]
  );

  const doAction = useCallback(() => {
    httpTemplates.get().then(
      //! get saved templates
      (response) => {
        const ctx = actionModal;
        let obj = _templateDataPrepare(
          response,
          httpTemplates,
          productFlavourCode
        );
        let defaultTemplatesPromise = getDefaultTemplates();
        //!get default templates
        defaultTemplatesPromise.then((defaultTemplates) => {
          //! set the content of modal and showing it
          ctx.setContent(
            <ReportWizzard
              data={{
                columnsAvailable: columnsAvailable,
                defaultTemplates: defaultTemplates,
                templates: obj.templates,
                lastUsedTemplate: obj.lastUsedTemplate,
                lastUsedTemplateName: "LAST_USED_TEMPLATE",
                page: reportParams?.page!,
                wysiwygState: wysiwygState,
              }}
              httpTemplates={httpTemplates}
              refresh={() => {
                doAction();
              }}
              rankingCache={reportParams?.rankingCache}
            />
          );
          ctx.setModalTitle(
            wysiwygState?.target?.name != null
              ? `Create a PDF report for ${wysiwygState?.target?.name}`
              : "Create a PDF report"
          );
          ctx.setShowModal(true);
          ctx.setOptions({
            stylesOptions: {
              customCss: { maxWidth: "60%", height: "100%" },
              bodyCustomClass: style["full-height"],
            },
          });
        });
      },
      (error) => console.log(error)
    );
  }, [
    actionModal,
    columnsAvailable,
    getDefaultTemplates,
    httpTemplates,
    productFlavourCode,
    reportParams?.page,
    reportParams?.rankingCache,
    wysiwygState,
  ]);

  const directDoAction = useCallback(
    (args) => {
      const _uiState: any = directGetUIState(args);
      const _wysiwygState: any = _uiState?.wysiwygState;
      setWysiwygState(_wysiwygState);
      const _columnsAvailable: any = _uiState?.columnsAvailable;
      setColumnsAvailable(_columnsAvailable);

      httpTemplates.get().then(
        //! get saved templates
        (response) => {
          const ctx = actionModal;
          let obj = _templateDataPrepare(
            response,
            httpTemplates,
            productFlavourCode
          );
          let defaultTemplatesPromise = getDefaultTemplates();
          //!get default templates
          defaultTemplatesPromise.then((defaultTemplates) => {
            //! set the content of modal and showing it
            ctx.setContent(
              <ReportWizzard
                data={{
                  columnsAvailable: _columnsAvailable,
                  defaultTemplates: defaultTemplates,
                  templates: obj.templates,
                  lastUsedTemplate: obj.lastUsedTemplate,
                  lastUsedTemplateName: "LAST_USED_TEMPLATE",
                  page: reportParams?.page!,
                  wysiwygState: _wysiwygState,
                }}
                httpTemplates={httpTemplates}
                refresh={() => {
                  doAction();
                }}
                rankingCache={reportParams?.rankingCache}
              />
            );
            ctx.setModalTitle(
              `Create a PDF report for ${_wysiwygState?.target.name}`
            );
            ctx.setShowModal(true);
            ctx.setOptions({
              stylesOptions: {
                customCss: { maxWidth: "60%", height: "100%" },
                bodyCustomClass: style["full-height"],
              },
            });
          });
        },
        (error) => console.log(error)
      );
    },
    [
      actionModal,
      directGetUIState,
      doAction,
      getDefaultTemplates,
      httpTemplates,
      productFlavourCode,
      reportParams?.page,
      reportParams?.rankingCache,
    ]
  );

  return { doAction, directDoAction, reportParams, setReportParams };
};

const _templateDataPrepare = (response, httpTemplates, productFlavourCode) => {
  let templates: any[] = [];
  let lastUsedTemplate = null; // clean state
  if (response != null && response.length > 0) {
    for (let i = 0; i < response.length; i++) {
      var template = httpTemplates.template(deepClone(response[i])); // Normalize
      if (template["name"] === "LAST_USED_TEMPLATE") {
        template["isEditable"] = false; // Disable save because this object does not exist in template list

        // Clean template from invalid widgets
        if (
          template["configuration"] != null &&
          template["configuration"]["sections"] != null
        ) {
          var sections: any[] = [];
          for (
            var j = 0, lengthJ = template["configuration"]["sections"].length;
            j < lengthJ;
            j++
          ) {
            var section = template["configuration"]["sections"][j];
            // Filter out unsupported widgets
            if (
              productFlavourCode !== "SYSTEMATIC_ENGINE" &&
              section["type"] === "REPORT_STRATEGY_SUMMARY"
            ) {
              continue;
            }

            sections.push(section);
          }
          template["configuration"]["sections"] = sections;
        }

        lastUsedTemplate = template;
      } else {
        templates.push(template);
      }
    }
  }
  return { templates, lastUsedTemplate };
};
