import { ClusterAnalytics } from "../../../api/compute/ClusterAnalytics";
import { getTaxonById } from "../../../api/compute/Taxon";
import { AppEnvironment } from "../../../types/Defaults";
import { MarketsStorage } from "../../app/storage/MarketsStorage";
import { Peer } from "./Peer";

export class RequestsManager {
  peer: Peer;
  apiCluster: ClusterAnalytics;
  environment: AppEnvironment;

  constructor(environment: AppEnvironment) {
    const marketsStorage = new MarketsStorage(environment);
    this.peer = new Peer(marketsStorage);
    this.apiCluster = environment?.http?.clusterAnalytics;
    this.environment = environment;
  }

  getAssetType(target) {
    if (target == null) {
      return;
    }

    const assetType = target["assetType"];

    if (assetType != null && Object.keys(assetType).length) {
      const acceptedTypes = [
        "Stock",
        "ETF",
        "Commodity",
        "Index",
        "Sector",
        "Currency",
      ];

      const constituentsTypes: any = [];

      for (const key of Object.keys(assetType)) {
        if (acceptedTypes.includes(key)) {
          constituentsTypes.push(key);
        }
      }

      return constituentsTypes;
    }

    return [];
  }

  getDimensionForClusterApi(segment) {
    switch (segment) {
      case "Area":
      case "Region":
      case "Country":
        return "country";

      case "3 Sector":
      case "1 Industry":
        return "icb";

      case "etfgeo":
        return "etfgeo";

      case "etfclass":
        return "etfclass";
      case "size":
        return "3 Level";
    }
  }

  getAnalytic(params) {
    let analytic = "pq";

    switch (params["performanceTimeframe"]) {
      case "1_week":
        analytic = "pw";
        break;
      case "1_month":
        analytic = "pm";
        break;
      case "3_months":
        analytic = "pq";
        break;
      case "6_months":
        analytic = "ps";
        break;
      case "12_months":
        analytic = "py";
        break;
      default:
        throw new Error(`Unknown performance ${params["timeframe"]}`);
    }

    return analytic;
  }

  /**
   *
   * @param type "cumulative" | "segmentOnly" | "dispersionDrillDown"
   *
   * Returns the cluster configuration to get all data needed to print dispersion widgets.
   * We need this method because we have to make 3 calls to the cluster API. The reason
   * is that as well as dispersion, that is the result of a clusterization on a specific dimension,
   * we want to get the dispersion of the whole portfolio/basket. In addition to this we want to show
   * in dispersion's widgets, titles that cannot be clusterized, and to do that we call the cluster API to
   * get back the cardinality of this titles.
   */
  buildDispListClusters(
    {
      dimension,
      isEtfOnly,
      etfLevel,
      segment,
      analytic,
      intervals,
      trimOutliers,
    },
    type: "cumulative" | "segmentOnly" | "dispersionDrillDown"
  ) {
    let cluster: any = [];

    const level = isEtfOnly ? etfLevel : segment;

    switch (type) {
      case "dispersionDrillDown": {
        cluster = [
          {
            dimension,
            transform: {
              function: "taxonomy",
              params: {
                level: level === "all" ? "1 Industry" : level,
              },
            },
          },
          {
            dimension: analytic,
            transform: {
              function: "topbottom",
              params: {
                perc: intervals,
                trimOutliers: trimOutliers,
              },
            },
          },
        ];

        /**
         * If segment is equal to size is needed to change cluster semantic
         */
        if (segment === "3 Level") {
          const sizeCLuster: any = {
            dimension: "marketcap",
            transform: {
              function: "size",
            },
          };

          cluster.splice(0, 1, sizeCLuster);
        }

        break;
      }

      case "cumulative": {
        cluster = [
          {
            dimension: analytic,
            transform: {
              function: "topbottom",
              params: {
                perc: intervals,
                trimOutliers: trimOutliers,
              },
            },
          },
        ];
        break;
      }

      case "segmentOnly": {
        cluster = [
          {
            dimension,
            transform: {
              function: "taxonomy",
              params: {
                level: level === "all" ? "1 Industry" : level,
              },
            },
          },
        ];

        if (segment === "3 Level") {
          const sizeCLuster: any = {
            dimension: "marketcap",
            transform: {
              function: "size",
            },
          };

          cluster.splice(0, 1, sizeCLuster);
        }

        break;
      }

      default:
        throw new Error("Cluster type doen't match the correct configurations");
    }

    return cluster;
  }

  mergeDispDataForLists({ dispersion, cumulative, segment }, list) {
    let data = { ...dispersion };

    const cumulativeDispersion = {
      dispersion: cumulative ?? {},
      name: list?.type === "BASKET" ? "Basket" : "Portfolio",
      cardinality:
        cumulative?.top?.cardinality +
        cumulative?.middle?.cardinality +
        cumulative?.bottom?.cardinality,
    };

    /**
     * Check if the dispersion of the portfolio is an empty object, if the check is passed
     * the cumulative dispersion is pushed into data.
     */
    if (Object.keys(cumulativeDispersion.dispersion).length) {
      data[list?.type === "BASKET" ? "Basket" : "Portfolio"] =
        cumulativeDispersion;
    }

    const rawTaxonomies = this.environment.taxonomies;
    const txFields = this.environment.taxonomyFields;
    const txWhere = rawTaxonomies[txFields["security"]["country"]];
    const txWhereEtf = rawTaxonomies[txFields["ETF"]["etfgeo"]];
    const txWhat = rawTaxonomies[txFields["security"]["sector"]];
    const txWhatEtf = rawTaxonomies[txFields["ETF"]["etfclass"]];

    const taxonomies = [
      txWhere,
      txWhat,
      txWhereEtf,
      txWhatEtf,
      rawTaxonomies["SizeClassification"],
    ];
    const normalizeCluster = {};

    for (const [key, value] of Object.entries(segment)) {
      const countryName = getTaxonById(key, taxonomies)["name"];
      normalizeCluster[countryName] = value;
    }

    for (const [key, value] of Object.entries<any>(normalizeCluster)) {
      if (!(key in data)) {
        data[key] = {
          dispersion: {
            top: {
              average: null,
              max: null,
              min: null,
              cardinality: value.cardinality,
              dataTotalCount: value.dataTotalCount,
              data: value.data,
            },
            bottom: {
              average: null,
              max: null,
              min: null,
              cardinality: value.cardinality,
              dataTotalCount: value.dataTotalCount,
              data: value.data,
            },
            middle: {
              average: null,
              max: null,
              min: null,
              cardinality: value.cardinality,
              dataTotalCount: value.dataTotalCount,
              data: value.data,
            },
          },
          cardinality: value.cardinality,
          name: key,
        };
      } else {
        data[key] = {
          ...data[key],
          cardinality:
            data[key]?.dispersion?.top?.cardinality +
            data[key]?.dispersion?.middle?.cardinality +
            data[key]?.dispersion?.bottom?.cardinality,
        };
      }
    }

    return data;
  }

  async getPortfolioDispersion(wysiwyg, section) {
    const assetType = this.getAssetType(wysiwyg?.target ?? {});
    const isListMultiType = assetType.length > 1;

    const isEtfOnly = !isListMultiType && assetType[0] === "ETF";

    const intervalsDict = {
      4: 25,
      10: 10,
      20: 5,
    };

    const n_iles = intervalsDict[section?.content?.intervals];

    const listId =
      typeof wysiwyg?.target?.id === "string"
        ? wysiwyg?.target?.id
        : JSON.stringify(wysiwyg?.target?.id);
    const params = {
      segment: isEtfOnly
        ? wysiwyg?.segmentEtf ?? "etfgeo"
        : wysiwyg?.segment ?? "Country",
      performanceTimeframe: section?.content?.timeframe ?? "3_months",
      trimOutliers: wysiwyg?.trimOutliers ?? false,
      intervals: n_iles ?? 25,
    };

    // If segment value 'all' is a UI parameter and when is specified has to be treated as "icb" of "etfclass"
    if (params.segment === "all") {
      params.segment = "1 Industry";
    }

    if (params.segment === "all_etf") {
      params.segment = "etfclass";
    }

    const analytic = this.getAnalytic(params);

    const analytics = [
      `${analytic}#avg#false`,
      `${analytic}#max#false`,
      `${analytic}#min#false`,
      "cardinality",
    ];
    const { segment, intervals, trimOutliers } = params;
    const etfLevel = segment === "etfgeo" ? "Country" : "1 Industry";

    const dimension = this.getDimensionForClusterApi(segment);

    const clusterBuilderParams = {
      dimension,
      isEtfOnly,
      etfLevel,
      segment,
      analytic,
      intervals,
      trimOutliers,
    };

    const clusterCumulative = this.buildDispListClusters(
      clusterBuilderParams,
      "cumulative"
    );

    const getConfig = (cluster) =>
      this.apiCluster
        .createConfiguration()
        .analytics(analytics)
        .clusters(cluster)
        .method("DRILL_DOWN")
        .universeFromList([listId], wysiwyg?.target?.type);

    const config = {
      cumulativeConfig: getConfig(clusterCumulative),
    };

    const cumulativeResponse = await config[
      "cumulativeConfig"
    ].fetchAnalytics();

    return this.apiCluster.decodeAnalyticsList(cumulativeResponse);
  }

  async getDispersionForLists(wysiwyg, section) {
    const assetType = this.getAssetType(wysiwyg?.target ?? {});
    const isListMultiType = assetType.length > 1;

    const isEtfOnly = !isListMultiType && assetType[0] === "ETF";
    const useWysiwyg = section?.content?.useWysiwyg ?? true;

    const intervalsDict = {
      4: 25,
      10: 10,
      20: 5,
    };

    const wysiwygParams = {
      segment: isEtfOnly
        ? wysiwyg?.segmentEtf ?? "etfgeo"
        : wysiwyg?.segment ?? "Country",
      performanceTimeframe:
        wysiwyg?.dispersionByConstraints?.performanceTimeframe ?? "3_months",
      trimOutliers: wysiwyg?.trimOutliers ?? false,
      intervals: wysiwyg?.dispersionByConstraints?.intervals ?? 25,
    };

    const sectionConfigParams = {
      segment: section?.content?.segment,
      performanceTimeframe: section?.content?.timeframe,
      trimOutliers: section?.content?.trimOutliers,
      intervals: intervalsDict[section?.content?.intervals ?? 4],
    };

    const getParams = (key) => {
      if (useWysiwyg === true) {
        return wysiwygParams[key];
      } else {
        return sectionConfigParams[key];
      }
    };

    const listId =
      typeof wysiwyg?.target?.id === "string"
        ? wysiwyg?.target?.id
        : JSON.stringify(wysiwyg?.target?.id);

    const params = {
      segment: getParams("segment"),
      performanceTimeframe: getParams("performanceTimeframe"),
      trimOutliers: getParams("trimOutliers"),
      intervals: getParams("intervals"),
    };

    // If segment value 'all' is a UI parameter and when is specified has to be treated as "icb" of "etfclass"
    if (params.segment === "all") {
      params.segment = "1 Industry";
    }

    if (params.segment === "all_etf") {
      params.segment = "etfclass";
    }

    const analytic = this.getAnalytic(params);

    const analytics = [
      `${analytic}#avg#false`,
      `${analytic}#max#false`,
      `${analytic}#min#false`,
      "cardinality",
    ];
    const { segment, intervals, trimOutliers } = params;
    const etfLevel = segment === "etfgeo" ? "Country" : "1 Industry";

    const dimension = this.getDimensionForClusterApi(segment);

    const clusterBuilderParams = {
      dimension,
      isEtfOnly,
      etfLevel,
      segment,
      analytic,
      intervals,
      trimOutliers,
    };

    const clusterDispersion = this.buildDispListClusters(
      clusterBuilderParams,
      "dispersionDrillDown"
    );
    const clusterCumulative = this.buildDispListClusters(
      clusterBuilderParams,
      "cumulative"
    );
    const clusterSegmentOnly = this.buildDispListClusters(
      clusterBuilderParams,
      "segmentOnly"
    );

    const getConfig = (cluster) =>
      this.apiCluster
        .createConfiguration()
        .analytics(analytics)
        .clusters(cluster)
        .method("DRILL_DOWN")
        .universeFromList([listId], wysiwyg?.target?.type);

    const config = {
      dispersionConfig: getConfig(clusterDispersion),
      cumulativeConfig: getConfig(clusterCumulative),
      segmentOnlyConfig: getConfig(clusterSegmentOnly),
    };

    const dispersionResponse = await config[
      "dispersionConfig"
    ].fetchAnalytics();
    const cumulativeResponse = await config[
      "cumulativeConfig"
    ].fetchAnalytics();
    const segmentReponse = await config["segmentOnlyConfig"].fetchAnalytics();

    const response = {
      dispersion: this.apiCluster.decodeAnalyticsList(dispersionResponse),
      cumulative: this.apiCluster.decodeAnalyticsList(cumulativeResponse),
      segment: this.apiCluster.decodeAnalyticsList(segmentReponse),
    };

    const list = wysiwyg?.target;
    return this.mergeDispDataForLists(response, list);
  }

  getDispersionSerieForChart(data, section) {
    const values = this.peer.prepareDispersionBySegment(data, section);
    const peerSerie: any = [];

    for (const [key, peer] of Object.entries<any>(values)) {
      if (
        peer?.dispersion?.top?.average === 0 &&
        peer?.dispersion?.middle?.average === 0 &&
        peer?.dispersion?.bottom?.average === 0
      ) {
        continue; // Ignore if there is no data
      }
      const name = peer?.label ?? peer?.name ?? key;
      // Filter "Any" field
      if (name.toLowerCase() === "any") {
        continue;
      }
      peerSerie.push({
        name: name,
        _s_label: peer?._s_label ?? name,
        top: peer?.dispersion?.top?.average,
        topCardinality: peer?.dispersion?.top?.dataTotalCount,
        middle: peer?.dispersion?.middle?.average,
        middleCardinality: peer?.dispersion?.middle?.dataTotalCount,
        bottom: peer?.dispersion?.bottom?.average,
        bottomCardinality: peer?.dispersion?.bottom?.dataTotalCount,
        maxBottom: peer?.dispersion?.bottom?.max,
        maxTop: peer?.dispersion?.top?.max,
        minBottom: peer?.dispersion?.bottom?.min,
        minTop: peer?.dispersion?.top?.min,
        id: key,
      });
    }

    return peerSerie;
  }
}
