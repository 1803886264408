import { InputAdornment, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from "react";

type Props = {
  managementOrderThresholdHandler: Function;
  orderThreshold: number | undefined;
};

const OrderThreshold = forwardRef(
  ({ managementOrderThresholdHandler, orderThreshold }: Props, ref) => {
    const [orderThresholdIsValid, setOrderThresholdIsValid] = useState(true);
    const validateOrderThreshold = useCallback((ot) => {
      if (ot?.length > 0) {
        let _ot = parseFloat(ot);
        if (_ot < 0 /*|| _ot > 2*/) {
          setOrderThresholdIsValid(false);
          return false;
        } else {
          setOrderThresholdIsValid(true);
          return true;
        }
      } else {
        setOrderThresholdIsValid(false);
        return false;
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        validateOrderThreshold: (value: any) => {
          const isValid = validateOrderThreshold(value);
          setOrderThresholdIsValid(isValid);
          return isValid;
        },
      }),
      [validateOrderThreshold]
    );

    return (
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"}>
          <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
            Order threshold
          </Typography>
        </Box>
        <StyledInput
          feeIsValid={orderThresholdIsValid}
          validateFee={validateOrderThreshold}
          managementFeesHandler={managementOrderThresholdHandler}
          expenseRatio={orderThreshold}
        />
      </Box>
    );
  }
);

export default OrderThreshold;

const StyledInput = styled((props: any) => (
  <TextField
    helperText={!props.feeIsValid && "insert a valid threshold (min:0)"}
    error={!props.feeIsValid}
    onChange={(e) => {
      props.validateFee(e.target.value);
      props.managementFeesHandler(e);
    }}
    sx={{ fontSize: "0.7vw", pt: 1, width: 100 }}
    className={props.className}
    fullWidth={false}
    size="small"
    type="number"
    inputProps={{ step: 0.1, min: 0 /* max: 2*/ }}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    value={
      props.expenseRatio === null
        ? ""
        : props.expenseRatio === 0
        ? (0).toFixed(2)
        : (props.expenseRatio * 100).toFixed(2)
    }
  />
))({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});
