import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

type Props = {
  managementFeesHandler: any;
  expenseRatio: any;
};

const StyledInput = styled((props: any) => (
  <TextField
    helperText={!props.feeIsValid && "insert a valid fee"}
    error={!props.feeIsValid}
    onChange={(e) => {
      props.validateFee(e.target.value);
      props.managementFeesHandler(e);
    }}
    sx={{ fontSize: "0.7vw", pt: 1, width: 100 }}
    className={props.className}
    fullWidth={false}
    size="small"
    type="number"
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    inputProps={{ step: 0.1}}

    value={
      props.expenseRatio === null
        ? ""
        : props.expenseRatio === 0
        ? (0).toFixed(2)
        : (props.expenseRatio * 100).toFixed(2)
    }
  />
))({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

const Fees = forwardRef(
  ({ managementFeesHandler, expenseRatio }: Props, ref) => {
    const [feeIsValid, setFeeIsValid] = useState(true);
    const validateFee = useCallback((fee) => {
      if (fee.length > 0) {
        setFeeIsValid(true);
        return;
      }
      setFeeIsValid(false);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        validateFee: (isFeeValid: boolean) => {
          setFeeIsValid(isFeeValid);
        },
      }),
      []
    );

    return (
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
          Management Fees
        </Typography>
        <StyledInput
          feeIsValid={feeIsValid}
          validateFee={validateFee}
          managementFeesHandler={managementFeesHandler}
          expenseRatio={expenseRatio}
        />
        {/* <TextField
          helperText={!feeIsValid && "insert a valid fee"}
          type="number"
          size="small"
          sx={{ width: 100 }}
          inputMode="numeric"
          inputProps={{ step: 0.1 }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(e) => {
            validateFee(e.target.value);
            managementFeesHandler(e);
          }}
          value={
            expenseRatio === null
              ? ""
              : expenseRatio === 0
              ? 0
              : expenseRatio * 100
          }
        /> */}
      </Box>
    );
  }
);

export default Fees;
