import {
  ColumnComponent,
  ColumnDefinition,
  ColumnLookup,
  Options,
  RowComponent,
  SortDirection,
  Sorter,
  TabulatorFull,
} from "tabulator-tables";
import { ClusterAnalytics } from "../../../api/compute/ClusterAnalytics";
import { deepClone } from "../../../deepClone";
import { Environment } from "../../../Environment";
import { AppEnvironment } from "../../../types/Defaults";
import { TableHelpers } from "./Helpers/TableHelpers";
import { SecurityTooltipForTables } from "../../SecurityTooltip/SecurityTooltip";
import { cloneElement } from "react";
import { createRoot } from "react-dom/client";

export type TableEventsV2 = {
  headerSort?: (value: { field: string; direction: "desc" | "asc" }) => void;
  rowClick?: (e, row: RowComponent) => void;
  rowSelected?: (row: RowComponent) => void;
  rowDeselected?: (row: RowComponent) => void;
  rowMouseEnter?: (e, row: RowComponent) => void;
  rowMouseLeave?: (e, row: RowComponent) => void;
  dataLoaded?: (data: any) => void;
  rowSelectionChanged?: (
    data: any,
    rows: any,
    selected: any,
    deselected: any
  ) => void;
  menuHeaderClick?: (e, col: ColumnComponent, actionTag: string) => void;
  onTableBuilt?: () => void;
  columnsLoaded?: (columns: ColumnComponent[]) => void;
  onTableDestroyed?: () => void;
  onDataSorted?: (sorters: Sorter[], rows: RowComponent[]) => void;
};

type TablePlugins = {
  showRowIndex?: boolean;
  addRowCheckbox?: boolean;
  tableTooltipParams?: {
    environment: Environment;
    enabled: boolean;
    handleClickChart: () => any;
    handleSecurityInfo: (security) => any;
  };
  enableAvgMenu?: boolean;
};

export type TableV2Options = Options & TablePlugins;

type Rows = RowComponent[];

export type TableParamsV2 = {
  node: any;
  environment: AppEnvironment;
  events?: TableEventsV2;
  tableOption?: TableV2Options;
};

type HeaderMenu = {
  label: string;
  action: (e, column: ColumnComponent) => void;
}[];

type AddOns = {
  status?: TableAvgExtension;
};

export function reactFormatter(JSX: any) {
  return function customFormatter(
    cell: any,
    formatterParams: any,
    onRendered: (callback: () => void) => void
  ) {
    // cell - the cell component
    // formatterParams - parameters set for the column
    // onRendered - function to call when the formatter has been rendered
    const renderFn = () => {
      const cellEl = cell.getElement();
      if (cellEl) {
        const formatterCell = cellEl.querySelector(".formatterCell");
        if (formatterCell) {
          const CompWithMoreProps = cloneElement(JSX, { cell });
          const root = createRoot(formatterCell);
          root.render(CompWithMoreProps);
        }
      }
    };

    if (onRendered) {
      onRendered(renderFn); // initial render only.
    }

    setTimeout(() => {
      renderFn(); // render every time cell value changed.
    }, 0);
    return '<div class="formatterCell"></div>';
  };
}

export const unmountReactFromTable = (tableRef) => {
  setTimeout(() => {
    const table = tableRef.element;
    const reactNodes = table.querySelectorAll(".formatterCell");
    let root: any = null;

    for (const node of reactNodes) {
      root = createRoot(node);
      root.unmount();
    }
  });
};

export class TableV2 {
  private columnsPlugin: TableHelpers;
  private columnsDefinitionUI;
  private tableOptions?: TableV2Options;
  private table: TabulatorFull & AddOns;
  private menuActionBroadcast?: (
    e,
    column: ColumnComponent,
    actionTag: string
  ) => void;
  private highlightRankColumns: boolean;
  private searchContext: any;

  constructor({ node, environment, events, tableOption }: TableParamsV2) {
    this.columnsPlugin = new TableHelpers(environment);
    this.highlightRankColumns = false;
    this.tableOptions = tableOption;

    if (tableOption && "columns" in tableOption) {
      tableOption.columns = this.columnsTransformer(tableOption.columns as any);
    }

    this.table = new TabulatorFull(node, tableOption);
    const avgExtension = new TableAvgExtension(this.table, environment);
    this.table["status"] = avgExtension;

    if (events) {
      this.registerEvents(events);
    }
  }

  public emptyColumns() {
    this.table.setColumns([]);
  }

  public insertColumns(columns) {
    const columnsDefinition = this.columnsTransformer(columns);

    this.table.setColumns(columnsDefinition);

    if ((this.tableOptions as TableParamsV2["tableOption"])?.addRowCheckbox) {
      this.addRowCheckbox();
    }

    if ((this.tableOptions as TableParamsV2["tableOption"])?.showRowIndex) {
      this.addRowIdx();
    }
  }

  public getFields() {
    const fields: string[] = [];
    let field = "";

    for (const column of this.table.getColumns()) {
      field = column.getField();

      if (field && field !== "idx") {
        fields.push(field);
      }
    }

    return fields;
  }

  public registerEvents(events: TableEventsV2) {
    this.handleEventSubscriptions(events, true);
  }

  public removeEvents(events: TableEventsV2) {
    this.handleEventSubscriptions(events, false);
  }

  public async insertData(data: any) {
    await this.table.setData(data);

    this.table.redraw(true);
  }

  public changeRankHighlight(value: boolean) {
    this.highlightRankColumns = value;
    const uiColumns = this.getUiColsDefinitionConfig();
    this.insertColumns(uiColumns);
  }

  public redraw(force?: boolean) {
    this.table.redraw(force);
  }

  public destroy() {
    unmountReactFromTable(this.table);
    this.table.destroy();
  }

  public getRows(): Rows {
    return this.table.getRows();
  }

  public getSelected(): Rows {
    return this.table.getSelectedRows();
  }

  public addMenu(column: string | ColumnDefinition, menuItems: HeaderMenu) {
    if (typeof column === "string") {
      const definitions = this.table.getColumnDefinitions();

      for (const col of definitions) {
        if (col.field === column) {
          const menu: any = {
            headerMenu: menuItems,
          };
          this.table.updateColumnDefinition(col.field, menu);

          break;
        }
      }
    } else {
      column["headerMenu"] = menuItems;
    }
  }

  public getColumns() {
    return this.table.getColumns();
  }

  public addColumns(columns, before?: boolean, target?: ColumnLookup) {
    const columnsDefinitions = this.columnsTransformer(columns);
    let tgt = target;

    for (const definition of columnsDefinitions) {
      this.table.addColumn(definition, before, tgt);
      // To insert all columns after the raget but keeping the insertion order
      tgt = definition.field;
    }
  }

  public removeColumns(columns) {
    if (!columns.length) {
      return;
    }

    const currentFields = this.getFields().reduce((prev, current) => {
      prev[current] = true;

      return prev;
    }, {});

    const currentSort = this.table?.getSorters();

    if (currentSort) {
      const field = currentSort?.[0]?.field;

      if (field) {
        const sortColumn = columns.find((col) => col.field === field);

        if (sortColumn) {
          this.table?.clearSort();
        }
      }
    }

    for (const col of columns) {
      if (col.field in currentFields) {
        this.table.deleteColumn(col.field);
      }
    }
  }

  // To see operators available refers to: https://tabulator.info/docs/6.3/filter#main-contents
  public filterRows(
    field: string,
    operator: string,
    values: string,
    matchAll?: boolean
  ) {
    this.table.setFilter(field, operator, values, {
      matchAll: matchAll ?? false,
    });
  }

  public clearFilter() {
    this.table.clearFilter(true);
  }

  public freezeRow(rowNumber) {
    const rows = this.getRows();

    const row = rows?.[rowNumber];

    if (row) {
      row.freeze();
    }
  }

  public freezeColumn(columnField) {
    const columns = this.getColumns();

    const column = columns.find(
      (col) => col.getDefinition().field === columnField
    );

    if (column) {
      column.updateDefinition({ frozen: true } as any);
    }
  }

  public getAvgStatus() {
    return this.table.status;
  }

  public sort(sortList: string | Sorter[], dir?: SortDirection) {
    this.table.setSort(sortList, dir);
  }

  public getSorter() {
    return this.table?.getSorters() ?? null;
  }

  public removeSort() {
    this.table.clearSort();
  }

  public scrollToRow(row: RowComponent) {
    this.table.scrollToRow(row, "top", false);
  }

  public updateSearchContext(universeConstraints) {
    this.searchContext = universeConstraints;
  }

  public clearSearchContext() {
    this.searchContext = undefined;
  }

  public getSearchContext() {
    return JSON.parse(JSON.stringify(this.searchContext));
  }

  public updateColumn(field: string, config: any) {
    return this.table.updateColumnDefinition(field, config);
  }

  private handleEventSubscriptions(events: TableEventsV2, subscribe: boolean) {
    for (const eventKey in events) {
      switch (eventKey) {
        case "headerSort": {
          // Tabulator don't provide an header sort event
          // This is a Trendrating logic, our tables sort on headerClick events
          this.table.off("headerClick");
          if (subscribe) {
            this.table.on("headerClick", (tabulatorEvent, column) =>
              this.wrapHeaderClickIntoHeaderSort(
                tabulatorEvent,
                column,
                events[eventKey] as any
              )
            );
          }
          break;
        }
        case "onDataSorted": {
          this.table.off("dataSorted");
          if (subscribe) {
            this.table.on("dataSorted", events[eventKey] as any);
          }
          break;
        }

        case "columnsLoaded":
        case "rowSelectionChanged":
        case "dataLoaded":
        case "rowSelected":
        case "rowDeselected":
        case "rowMouseEnter":
        case "rowMouseLeave":
        case "rowClick": {
          this.table.off(eventKey);
          if (subscribe) {
            this.table.on(eventKey, events[eventKey] as any);
          }
          break;
        }
        case "menuHeaderClick": {
          if (subscribe) {
            this.menuActionBroadcast = events[eventKey];
          } else {
            this.menuActionBroadcast = undefined;
          }
          break;
        }

        case "onTableBuilt": {
          this.table.off("tableBuilt");

          if (subscribe) {
            this.table.on("tableBuilt", events[eventKey]);
          }

          break;
        }

        case "onTableDestroyed": {
          this.table.off("tableDestroyed");

          if (subscribe) {
            this.table.on("tableDestroyed", events[eventKey]);
          }

          break;
        }

        default: {
          console.warn(
            `The event: ${eventKey} was not registered because is not supported by the TableV2 class`
          );
        }
      }
    }
  }

  private addAvgMenu(columns?: ColumnDefinition[]) {
    const tableColumns = columns ?? this.table.getColumnDefinitions() ?? [];
    const flatProperties = this.columnsPlugin.get("columns").flatProperties;

    if (tableColumns.length) {
      let property: any = null;

      for (const col of tableColumns) {
        property = flatProperties?.[col.field!] ?? null;

        if (property && "computable" in property && property?.computable) {
          const menu = [
            {
              label: "&#956; Average",
              actionTag: "avg",
              action: (e, col) => this.menuCallbackWrapper(e, col, "avg"),
            },
            {
              label: "&#8593;	Sort Ascending",
              actionTag: "sortAsc",
              action: (e, col) => this.menuCallbackWrapper(e, col, "sortAsc"),
            },
            {
              label: "&#8595;	Sort Descending",
              actionTag: "sortDesc",
              action: (e, col) => this.menuCallbackWrapper(e, col, "sortDesc"),
            },
          ];

          if (columns == null) {
            this.table.updateColumnDefinition(col.field!, {
              headerMenu: menu,
              headerMenuIcon: `<span style="font-size: 0.8vw">&#9776;</span>`,
            } as any);
          } else {
            col["headerMenu"] = menu;
            col[
              "headerMenuIcon"
            ] = `<span style="font-size: 0.8vw">&#9776;</span>`;
          }
        }
      }
    }
  }

  private menuCallbackWrapper(e, col: ColumnComponent, actionTag: string) {
    if (this.menuActionBroadcast) {
      this.menuActionBroadcast(e, col, actionTag);
    }
  }

  private wrapHeaderClickIntoHeaderSort(
    event,
    column: ColumnComponent,
    callback: (value: { field: string; direction: string }) => void
  ) {
    const sorters = column.getTable().getSorters();

    let actualDirection =
      column.getDefinition().headerSortStartingDir ?? "desc";
    if (sorters.length > 0 && sorters[0].field === column.getField()) {
      actualDirection = sorters[0].dir === "asc" ? "desc" : "asc";
    }

    const colField = column.getField();

    if (colField && colField !== "idx") {
      callback({ direction: actualDirection, field: colField });
    }
  }

  private addRowIdx() {
    const currentCols = this.table.getColumns();
    const exists = currentCols.some((col) => col.getField() === "idx");

    if (!exists) {
      this.table.addColumn(
        {
          title: "",
          field: "idx",
          formatter: "rownum",
          headerSort: false,
        },
        true,
        currentCols[0].getField()
      );
    }
  }

  private addRowCheckbox() {
    const currentCols = this.table.getColumns();
    const exists = currentCols.some((col) => {
      const def = col.getDefinition();

      return def.formatter === "rowSelection";
    });

    if (currentCols.length) {
      if (!exists) {
        this.table.addColumn(
          {
            title: "",
            formatter: "rowSelection",
            widthGrow: 0.5,
            hozAlign: "center",
            headerHozAlign: "center",
            headerSort: false,
          },
          true,
          currentCols[0].getField()
        );
      }
    }
  }

  private storeUiColsDfinitionConfig(rankColumnsDefinition) {
    this.columnsDefinitionUI = rankColumnsDefinition;
  }

  private getUiColsDefinitionConfig() {
    return this.columnsDefinitionUI;
  }

  private columnsTransformer(
    cols: ({ field: string } & Record<string, any>)[]
  ): ColumnDefinition[] {
    const rankColumns = this.columnsPlugin.get("rank");
    const common = this.columnsPlugin.get("columns");

    this.storeUiColsDfinitionConfig(cols);

    let columnsToSet: any = [];

    if (cols) {
      const tableColumns = cols;

      columnsToSet = [];

      for (const col of tableColumns) {
        if ("customColConfiguration" in col) {
          switch (col.customColConfiguration) {
            case "rankConfiguration": {
              rankColumns.configureAsRankColV2(
                col,
                columnsToSet,
                this.highlightRankColumns ?? false
              );

              break;
            }
            default:
              console.warn(
                `${col.customColConfiguration} is not a valid configuration for columns`
              );
          }
        } else {
          columnsToSet.push(common.tabulatorColumnV2(col));
        }
      }
    }

    if (
      this.tableOptions &&
      "tableTooltipParams" in this.tableOptions &&
      this.tableOptions?.["tableTooltipParams"]?.["enabled"]
    ) {
      this.addTooltip(columnsToSet);
    }

    if (
      this.tableOptions &&
      "enableAvgMenu" in this.tableOptions &&
      this.tableOptions["enableAvgMenu"]
    ) {
      this.addAvgMenu(columnsToSet);
    }

    return columnsToSet;
  }

  private addTooltip(columns) {
    for (const col of columns) {
      // The tooltip goes on ticker or field column
      if (col.field === "ticker" || col.field === "name") {
        if (this.tableOptions != null) {
          const originalFmt = col.formatter;

          col.formatter = reactFormatter(
            <SecurityTooltipForTables
              environment={
                this.tableOptions!["tableTooltipParams"]!["environment"]
              }
              chartButtonHandler={
                this.tableOptions!["tableTooltipParams"]!["handleClickChart"]
              }
              enableAddTo={false}
              setSecurity={
                this.tableOptions!["tableTooltipParams"]!["handleSecurityInfo"]
              }
              labelKey={col.field}
              customFormatter={originalFmt}
            />
          );
        }

        break;
      }
    }
  }
}

class TableAvgExtension {
  tableInstance: TabulatorFull;
  clustersAPI: ClusterAnalytics;
  state: any = {};

  constructor(
    table: TabulatorFull,
    environment: AppEnvironment,
    initialState?: any
  ) {
    this.tableInstance = table;
    this.clustersAPI = new ClusterAnalytics(environment);

    if (initialState != null) {
      this.state = initialState;

      if ("avgRow" in this.state) {
        this.tableInstance.on("tableBuilt", () => {
          this.addAvgRow();
        });
      }
    }
  }

  public updateState(key, value) {
    this.state[key] = value;
  }

  public get(key) {
    return this.state?.[key] ?? undefined;
  }

  public clear(key) {
    delete this.state[key];
  }

  public getState() {
    return deepClone(this.state);
  }

  async updateAvgRow(field, universeDefinition) {
    const universe =
      universeDefinition ?? this.get("currentUniverse") ?? undefined;

    if (universe) {
      const analytic = `${field}#avg#false`;
      let response: any = this.clustersAPI
        .createConfiguration()
        .analytics([analytic])
        .universeFromConstraints(universe);

      try {
        response = await response.fetchAnalytics();

        const avgValue = response?.clustersStats?.stats?.["ANY"]?.[analytic];
        const avgRow = this.get("avgRow");

        if (avgRow != null) {
          this.state["avgRow"][field] = avgValue;
        } else {
          this.state["avgRow"] = {};
          this.state["avgRow"][field] = avgValue;
        }

        const rows = this.tableInstance.getRows();
        const firstRow = rows[0];

        if (firstRow.isFrozen()) {
          // The frozen row already exists
          firstRow.update({ [field]: avgValue }).then(() => {
            this.removeCheckbox(firstRow);
          });
        } else {
          this.addAvgRow();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  clearAvgCell(col: ColumnComponent) {
    const avgRow = this.get("avgRow");
    const definition = col!.getDefinition();
    const field = definition.field!;

    if (!avgRow || !(field in avgRow)) {
      return;
    }

    delete avgRow[field];

    let i = 0;

    for (const key in avgRow) {
      if (avgRow[key] !== "Average") {
        i++;
      } else {
        continue;
      }
    }

    const isRowEmpty = i === 0;
    this.tableInstance = col.getTable();

    if (isRowEmpty) {
      this.removeAvgRow();
    } else {
      const cells = col.getCells();
      let row: any = null;

      for (const cell of cells) {
        row = cell.getRow();

        if (row.isFrozen()) {
          cell.setValue("");

          break;
        }
      }
    }
  }

  removeAvgRow() {
    let rowToDelete = this.get("avgRow");

    if (rowToDelete) {
      this.clear("avgRow");
    }

    const rows = this.tableInstance.getRows();

    if (rows.length) {
      for (const r of rows) {
        // is the one to remove
        if (r.isFrozen()) {
          r.delete();
        }
      }
    }
  }

  addAvgRow() {
    const avgRow = {};

    const rows = this.tableInstance.getRows();
    if (rows.length) {
      const rowCells = rows[0].getCells();
      let cField: any = null;
      let cell: any = null;
      const state = this.get("avgRow");

      for (let i = 0; i < rowCells.length; i++) {
        cell = rowCells[i];
        cField = cell.getField();

        if (cField in avgRow) {
          continue;
        }

        if (cField != null) {
          if (i === 0) {
            avgRow[cField] = "Average";
          } else if (cField in state) {
            avgRow[cField] = state[cField];
          } else {
            avgRow[cField] = undefined;
          }
        } else {
          cField = rowCells[i + 1].getField();
          avgRow[cField] = "Average";
        }
      }

      const avgRowState = {};

      for (const key in avgRow) {
        if (avgRow[key] != null) {
          avgRowState[key] = avgRow[key];
        }
      }

      this.updateState("avgRow", avgRowState);

      this.tableInstance.addRow(avgRow, true).then((row) => {
        row.freeze();
        this.removeCheckbox(row);
      });
    }
  }

  removeCheckbox(row) {
    const cells = row.getCells();

    const firstCell = cells[0].getElement();

    const checkbox = firstCell.querySelector("input");

    if (checkbox) {
      checkbox.style.display = "none";
    }
  }
}
