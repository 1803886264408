import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Report } from "../../../../../../../../../../api/report/Report";
import {
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { clone, deepClone } from "../../../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { Formatter } from "../../../../../../../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../../../../../../../types/Defaults";
import { themeBlueAndGrey } from "../../../../../../../../widgets/dialog/report/themeBlueAndGrey-ts";
import { InsightsContext } from "./Insights";

type InsightsExtendedProps = {
  askBeforeRedirect: boolean;
  setShowModal: (value) => void;
  setModalOkClickHandler: (callback) => void;
  loading: boolean;
  dashboard: DashboardV2;
};

type DashboardStrategies = {
  analytics: {
    "r#null,null,FULL,null,null,FULL,DIFF,YEARLY": number;
    "r#null,null,FULL,null,null,FULL,H,YEARLY": number;
    "r#null,null,FULL,YEARLY,1,SOLAR,DIFF,YEARLY": number;
    "r#null,null,FULL,YEARLY,1,SOLAR,H,YEARLY": number;
    "r#null,null,FULL,YEARLY,3,SOLAR,DIFF,YEARLY": number;
    "r#null,null,FULL,YEARLY,3,SOLAR,H,YEARLY": number;
    "sharpe#null,null,FULL,null,null,null,H": number;
    "turnover#NULL,NULL,FULL,NULL,NULL,FULL,YEARLY": number;
  };
  json: any;
  label: string[];
  name: string;
  type: string[];
  tags: {
    [key: string]: string[];
  };
};

export type DashboardV2 = {
  date: number;
  strategies: DashboardStrategies[];
  tagDictionary: {
    [key: string]: { field: string | null; label: string };
  };
};

type FilterCardProps = {
  title: string;
  field: string;
  filterFn: (id: string, field: string, checked: boolean) => void;
  checkboxList: { id: string; label: string }[];
};

type FilterSummaryRowProps = {
  activeFilters: { name: string; id: string; field: string }[];
  releaseFilter: (id: string, field: string, checked: boolean) => void;
  isUniverseEmpty: boolean;
};

const ANALYTICS_DICT = {
  year_3_perf_DIFF: "r#null,null,FULL,YEARLY,3,SOLAR,DIFF,YEARLY",
  year_10_perf_DIFF: "r#null,null,FULL,null,null,FULL,DIFF,YEARLY",
  year_3_perf_H: "r#null,null,FULL,YEARLY,3,SOLAR,H,YEARLY",
  year_10_perf_H: "r#null,null,FULL,null,null,FULL,H,YEARLY",
  year_1_perf_H: "r#null,null,FULL,YEARLY,1,SOLAR,H,YEARLY",
  year_1_perf_DIFF: "r#null,null,FULL,YEARLY,1,SOLAR,DIFF,YEARLY",
  sharpeRatio: "sharpe#null,null,FULL,null,null,FULL,H",
};

export function InsightsExtended({
  askBeforeRedirect,
  setShowModal,
  setModalOkClickHandler,
  loading,
  dashboard,
}: InsightsExtendedProps) {
  const ctx = useContext(InsightsContext);

  const filtersDefault = useMemo(() => {
    const currentState = ctx.get();

    return currentState?.insightsV2State.activeFilters ?? undefined;
  }, [ctx]);

  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: string[];
  }>(filtersDefault);
  const environment = useEnvironment();
  const formatter = useFormatter();

  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );

  const taxonomiesFields = useMemo(
    () => environment.get("taxonomyFields"),
    [environment]
  );

  const filter = useCallback((row, filtersActive) => {
    let strategyTags = row["tags"];

    for (const filterField in filtersActive) {
      let passFilter = false;
      for (const value of strategyTags[filterField]) {
        if (filtersActive[filterField].indexOf(value) >= 0) {
          passFilter = true;

          break;
        }
      }

      if (!passFilter) {
        return false;
      }
    }

    return true;
  }, []);

  const resolveTaxonomyId = useCallback(
    (id, field) => {
      const taxonSetId = taxonomiesFields["security"][field];
      const taxonSet = taxonomies[taxonSetId];

      return taxonSet?.[id] ?? id;
    },
    [taxonomies, taxonomiesFields]
  );

  const getRootNode = useCallback(
    (field) => {
      const taxonSetId = taxonomiesFields["security"][field];
      const taxonSet = taxonomies[taxonSetId];

      return Object.values(taxonSet).find((el: any) => el.parent == null);
    },
    [taxonomies, taxonomiesFields]
  );

  const dataAsOfDate = useMemo(() => {
    return formatter.custom("date", {
      options: {
        format: ["M", "D", "Y"],
        notAvailable: {
          input: null,
          output: "-",
        },
      },
      output: "HTML",
      value: dashboard?.date,
      valueHelper: null,
    });
  }, [dashboard?.date, formatter]);

  const strategies = useMemo(() => {
    return dashboard?.strategies;
  }, [dashboard?.strategies]);

  const tagsDictionary = useMemo(() => {
    return dashboard?.tagDictionary;
  }, [dashboard?.tagDictionary]);

  const filteredStrategies = useMemo(() => {
    if (strategies) {
      const strategiesCopy: DashboardStrategies[] = clone(strategies);

      if (activeFilters && Object.keys(activeFilters ?? {}).length > 0) {
        const filteredList: DashboardStrategies[] = [];

        for (const strategy of strategiesCopy) {
          if (filter(strategy, activeFilters)) {
            filteredList.push(strategy);
          }
        }
        return filteredList;
      } else {
        return strategiesCopy;
      }
    }

    return [];
  }, [activeFilters, filter, strategies]);

  const getAvailableFilters = useCallback(
    (dataSource) => {
      const filtersMap = clone(tagsDictionary);

      for (const key in filtersMap) {
        filtersMap[key]["values"] = {};
      }

      let isTaxonNode = false;

      for (const strategy of dataSource) {
        for (const tag in strategy.tags) {
          if (tagsDictionary![tag].field == null) {
            isTaxonNode = false;
          } else {
            isTaxonNode = true;
          }

          const rootNode: any = isTaxonNode ? getRootNode(tag) : undefined;

          for (const value of strategy.tags[tag]) {
            if (!rootNode || value !== rootNode.id) {
              filtersMap[tag]["values"][value] = true;
            }
          }
        }
      }

      return filtersMap;
    },
    [getRootNode, tagsDictionary]
  );

  const buildFilters = useCallback(
    (filtersMap) => {
      const filters: {
        label: string;
        availableOptions: { id: string; label: string }[];
        field: string;
      }[] = [];

      let value: any = null;

      for (const key in filtersMap) {
        value = filtersMap[key];

        const filter: {
          label: string;
          availableOptions: { id: string; label: string }[];
          field: string;
        } = {
          label: "",
          availableOptions: [],
          field: key,
        };

        filter["label"] = value["label"];

        if (value["field"] != null) {
          filter["availableOptions"] = Object.keys(value["values"]).map(
            (voice) => {
              const field = filtersMap[key]["field"];
              const node = resolveTaxonomyId(voice, field);

              if (node?.name) {
                return { id: voice, label: node.name };
              }

              return { id: voice, label: voice };
            }
          );
        } else {
          filter["availableOptions"] = Object.keys(value["values"]).map(
            (voice) => ({ id: voice, label: voice })
          );
        }

        filters.push(filter);
      }

      return filters;
    },
    [resolveTaxonomyId]
  );

  const filterMapOrigin = useMemo(() => {
    if (strategies && tagsDictionary) {
      return getAvailableFilters(strategies);
    }
  }, [getAvailableFilters, strategies, tagsDictionary]);

  const filtersAvailable = useMemo(() => {
    if (filteredStrategies && filteredStrategies.length && tagsDictionary) {
      const filtersMap = getAvailableFilters(filteredStrategies);
      let filterNodesNumber: any = null;

      for (const key in filtersMap) {
        filterNodesNumber = Object.keys(filtersMap[key]?.values ?? {}).length;

        if (filterNodesNumber > 0) {
          if (key in filterMapOrigin) {
            filtersMap[key] = filterMapOrigin[key];
          }
        } else {
          delete filtersMap[key];
        }
      }

      return buildFilters(filtersMap);
    } else {
      // Empty universe show all filters
      return buildFilters(filterMapOrigin);
    }
  }, [
    buildFilters,
    filterMapOrigin,
    filteredStrategies,
    getAvailableFilters,
    tagsDictionary,
  ]);

  const pdf = useMemo(() => {
    return new PDF(filteredStrategies, environment.get("setup"), () => {
      const currentState = deepClone(ctx.get());
      const currentSort = currentState.insightsV2State.sorter;

      return {
        activeFilters: currentState.insightsV2State.activeFilters,
        sorter: {
          field: currentSort.field,
          direction: currentSort.rev === false ? "desc" : "asc",
        },
        selectedColumn: currentState.insightsV2State.selectedColumn,
        tagsDictionary,
      };
    });
  }, [ctx, environment, filteredStrategies, tagsDictionary]);

  const strategyNumber = useMemo(
    () => filteredStrategies.length,
    [filteredStrategies.length]
  );

  const filterChips = useMemo(() => {
    let result: { id: string; name: string; field: string }[] = [];

    if (tagsDictionary) {
      for (const key in activeFilters) {
        result = result.concat(
          activeFilters[key].map((item) => {
            if (tagsDictionary[key].field != null) {
              return {
                id: item,
                name: resolveTaxonomyId(item, key)?.name ?? "",
                field: key,
              };
            } else {
              return {
                name: item,
                id: item,
                field: key,
              };
            }
          })
        );
      }
    }

    return result;
  }, [activeFilters, resolveTaxonomyId, tagsDictionary]);

  const isReady = useMemo(() => dashboard != null, [dashboard]);

  const saveFilters = useCallback(
    (filters) => {
      const currentState = deepClone(ctx.get());
      currentState.insightsV2State.activeFilters = filters;
      ctx.set(currentState);
    },
    [ctx]
  );

  const handleFilter = useCallback(
    (id, field, checked) => {
      if (checked) {
        // Add filter
        setActiveFilters((currentState) => {
          const newState = clone(currentState ?? {});

          if (field in newState) {
            // Avoid copy in the array
            newState[field].push(id);
            newState[field] = [...new Set(newState[field])];
          } else {
            newState[field] = [id];
          }

          saveFilters(newState);
          return newState;
        });
      } else {
        setActiveFilters((currentState) => {
          const newState = clone(currentState ?? {});

          if (field in newState) {
            newState[field] = newState[field].filter((items) => items !== id);

            if (newState[field].length === 0) {
              delete newState[field];
            }
          }

          saveFilters(newState);
          return newState;
        });
      }
    },
    [saveFilters]
  );

  const downLoadPdf = useCallback(() => {
    pdf.print();
  }, [pdf]);

  const openWarningModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  return loading ? (
    <Box
      flex={1}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <CircularProgress sx={{ color: "#2a7090" }} />{" "}
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  ) : (
    <Box display={"flex"} height={"95%"} p={1}>
      {isReady ? (
        <Box display={"flex"} width={"100%"} gap={1}>
          <FiltersColumn
            handleFilter={handleFilter}
            filtersAvailable={filtersAvailable}
          />
          <Box display={"flex"} width={"80%"} flexDirection={"column"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                minWidth={0}
                flex={1}
              >
                <Typography>
                  <strong>{strategyNumber}</strong> Strategies analytics as of{" "}
                  <strong>{dataAsOfDate}</strong>. * (All performances are
                  annualized)
                </Typography>
                <FilterSummaryRow
                  activeFilters={filterChips ?? []}
                  releaseFilter={handleFilter}
                  isUniverseEmpty={!filteredStrategies.length}
                />
              </Box>
              {ctx.get().usedIn !== "RISE" && (
                <Button onClick={downLoadPdf}>Download PDF</Button>
              )}
            </Box>
            <StrategiesTable
              openWarningModal={openWarningModal}
              askBeforeRedirect={askBeforeRedirect}
              taxonResolver={resolveTaxonomyId}
              data={filteredStrategies}
              tagsDictionary={tagsDictionary}
              setOkHandler={setModalOkClickHandler}
            />
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

const FilterSummaryRow = ({
  activeFilters,
  releaseFilter,
  isUniverseEmpty,
}: FilterSummaryRowProps) => {
  const ctx = useContext(InsightsContext);

  const handleReleaseFilter = useCallback(
    (id, field) => {
      const currentState = deepClone(ctx.get());

      if (currentState.insightsV2State.hidedFields.indexOf(field) >= 0) {
        currentState.insightsV2State.hidedFields =
          currentState.insightsV2State.hidedFields.filter(
            (item) => item !== field
          );
        ctx.set(currentState);
      }

      releaseFilter(id, field, false);
    },
    [ctx, releaseFilter]
  );

  return (
    <Stack direction={"row"} spacing={1} sx={{ minWidth: 0, overflow: "auto" }}>
      {activeFilters.map((filter) => (
        <Chip
          sx={!isUniverseEmpty ? { border: "none!important" } : {}}
          key={uuidv4()}
          label={filter.name}
          variant="outlined"
          onDelete={() => handleReleaseFilter(filter.id, filter.field)}
        />
      ))}
    </Stack>
  );
};

const FiltersColumn = memo(({ filtersAvailable, handleFilter }: any) => {
  const ctx = useContext(InsightsContext);

  const filters = useMemo(() => {
    const currentState = deepClone(ctx.get());

    const filters = filtersAvailable.filter(
      (filter) =>
        currentState.insightsV2State.hidedFields.indexOf(filter.field) < 0
    );

    return filters;
  }, [ctx, filtersAvailable]);

  return (
    <Box
      display={"flex"}
      gap={1}
      p={1}
      width={"20%"}
      flexDirection={"column"}
      minHeight={0}
      overflow={"auto"}
    >
      {filters?.map((filter) => (
        <FilterCard
          key={uuidv4()}
          title={filter.label}
          checkboxList={filter.availableOptions}
          filterFn={handleFilter}
          field={filter.field}
        />
      ))}
    </Box>
  );
});

const FilterCard = ({
  title,
  filterFn,
  checkboxList,
  field,
}: FilterCardProps) => {
  const [collapse, setCollapse] = useState(true);
  const ctx = useContext(InsightsContext);

  const defaultActiveCheckbox = useMemo(() => {
    const state = deepClone(ctx.get());
    const filters = state.insightsV2State.activeFilters;

    if (filters != null) {
      return filters?.[field] ?? [];
    }

    return [];
  }, [ctx, field]);

  const [activeCheckboxes, setActiveCheckboxes] = useState<string[]>(
    defaultActiveCheckbox
  );

  const voices = useMemo(() => {
    checkboxList.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      }

      return 0;
    });

    return checkboxList;
  }, [checkboxList]);

  const onCheckboxChange = useCallback(
    (event, checked, id) => {
      if (checked) {
        setActiveCheckboxes((current) => {
          return [...current, id];
        });
      } else {
        setActiveCheckboxes((current) => current.filter((item) => item !== id));
      }

      filterFn(id, field, checked);
    },
    [field, filterFn]
  );

  const toggleCollapse = useCallback(() => {
    setCollapse(!collapse);
  }, [collapse]);

  return (
    <Card sx={{ overflow: "visible" }}>
      <CardContent sx={{ display: "flex", padding: 1 }}>
        <Box display={"flex"} flex={1} flexDirection={"column"}>
          <Box display={"flex"} flex={1} alignItems={"center"}>
            <Typography sx={{ color: "#2a7090", fontWeight: "bold" }} gap={1}>
              {title}
            </Typography>
            {collapse ? (
              <KeyboardArrowDownIcon
                onClick={toggleCollapse}
                sx={{ color: "#2a7090", cursor: "pointer" }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={toggleCollapse}
                sx={{ color: "#2a7090", cursor: "pointer" }}
              />
            )}
          </Box>

          <Collapse in={collapse}>
            <Box display={"flex"} flexDirection={"column"}>
              {voices.map((checkbox) => {
                return (
                  <FormControlLabel
                    key={uuidv4()}
                    control={
                      <Checkbox
                        checked={activeCheckboxes.indexOf(checkbox.id) >= 0}
                        size={"small"}
                        onChange={(event, checked) =>
                          onCheckboxChange(event, checked, checkbox.id)
                        }
                      />
                    }
                    label={checkbox.label}
                  />
                );
              })}
            </Box>
          </Collapse>
        </Box>
      </CardContent>
    </Card>
  );
};

const StrategiesTable = ({
  data,
  tagsDictionary,
  taxonResolver,
  askBeforeRedirect,
  openWarningModal,
  setOkHandler,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const formatter = useFormatter();
  const [tableStatus, setTableStatus] = useState({
    columns: false,
    built: false,
  });

  const tableRef = useRef<{ getInstance: () => TableV2 }>();

  const ctx = useContext(InsightsContext);

  const customColDefault = useMemo(() => {
    const currentState = ctx.get();
    return currentState.insightsV2State.selectedColumn ?? "sector";
  }, [ctx]);

  const tableReady = useMemo(() => {
    return tableStatus.columns && tableStatus.built;
  }, [tableStatus.built, tableStatus.columns]);

  const getDefaultSorter = useCallback(() => {
    const currentState = ctx.get();

    const sorter = currentState.insightsV2State.sorter ?? {
      field: "name",
      rev: false,
    };

    return {
      field: sorter.field,
      direction: sorter.rev === false ? "desc" : "asc",
    };
  }, [ctx]);

  const [customColField, setCustomColField] = useState(customColDefault);

  const openEditMenu = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const dynamicColumnsList = useMemo(() => {
    const options: {
      field: string;
      label: string;
    }[] = [];

    for (const key in tagsDictionary) {
      options.push({ field: key, label: tagsDictionary[key]?.label });
    }

    return options;
  }, [tagsDictionary]);

  const onRowClick = useCallback(
    (e, eventValue) => {
      const data = eventValue.getData();
      const strategyJson = data.json;
      const strategyName = data?.["nameUnformatted"] ?? "Strategy";
      const globalState = ctx.get();
      const handler = globalState.insightsV2State.handleRowClick;
      const table = eventValue.getTable();
      const rows = table.getRows();

      let rowData: any = null;

      for (const r of rows) {
        rowData = r.getData();

        if (rowData["nameUnformatted"] === data?.["nameUnformatted"]) {
          r.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
        } else {
          r.getElement().style.backgroundColor = "white";
        }
      }

      if (askBeforeRedirect) {
        setOkHandler(() => {
          return () => handler(strategyJson, strategyName);
        });
        openWarningModal();
      } else {
        handler(strategyJson, strategyName);
      }

      const currentState = deepClone(ctx.get());
      currentState.selectedStrategy = {
        type: "V2",
        id: data?.["nameUnformatted"],
      };
      ctx.set(currentState);
    },
    [askBeforeRedirect, ctx, openWarningModal, setOkHandler]
  );

  const nameFormatter = useCallback(
    (cell) => {
      const data = cell.getData();
      const name = data["name"];

      //#region - highlighting the current strategy
      let id = data?.["nameUnformatted"] ?? null;
      const currentState = ctx.get();
      const selectedStrategy = currentState.selectedStrategy?.id ?? null;
      if (id) {
        let selectedID: string | number | null = selectedStrategy;
        if (selectedID != null) {
          if (id === selectedID) {
            const row = cell.getRow();
            row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
          }
        }
      }
      //#endregion

      const nameSplitted = name.split("__");

      if (nameSplitted?.[1] != null) {
        return `${nameSplitted[0]}</br>${nameSplitted[1]}`;
      }

      return nameSplitted[0];
    },
    [ctx]
  );

  const renderCell = useCallback(
    (cell) => {
      const value = cell.getValue();
      const field = cell.getField();

      if (field === "sharpeRatio") {
        return formatter.custom("number", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            decimals: 4,
          },
          output: "HTML",
          value: cell.getValue(),
        });
      } else {
        let innerHTML = "";

        innerHTML = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: value,
          valueHelper: {
            normalizationThreshold: 1,
          },
        });

        return innerHTML;
      }
    },
    [formatter]
  );

  const formatEditableCellTitle = useCallback((cell, params, onRendered) => {
    const node = cell.getElement();
    const titleNode = document.createElement("span");
    const titleTextValue = cell.getValue();
    const editIcon = document.createElement("span");

    editIcon.classList.add("i-edit");
    editIcon.style.marginLeft = "5px";
    editIcon.style.padding = "5px";

    titleNode.textContent = titleTextValue;
    titleNode.append(editIcon);

    node.style.cursor = "pointer";
    node.append(titleNode);

    onRendered(() => {
      const node: HTMLElement = cell.getElement();
      let icon: any = node.getElementsByClassName("i-edit");
      icon = icon?.[0];

      if (icon) {
        icon.addEventListener("click", (e) => {
          e.stopPropagation();
          setAnchorEl(icon);
        });
        node.addEventListener("mouseenter", () => {
          icon.style.color = "#2a7090";
        });
        node.addEventListener("mouseleave", () => {
          icon.style.color = "black";
        });
      }
    });

    return node.innerHTML;
  }, []);

  const customColformatter = useCallback(
    (cell) => {
      const fields: string[] = [];
      const row = cell.getData();
      const field = cell.getField();

      const listToFormat = row?.tags?.[field] ?? null;

      if (!listToFormat) {
        return "";
      }

      for (const id of listToFormat) {
        if (tagsDictionary[field].field != null) {
          fields.push(taxonResolver(id, field)?.name ?? "");
        } else {
          fields.push(id);
        }
      }

      return fields.join("<br>");
    },
    [tagsDictionary, taxonResolver]
  );

  const sorterCallback = useCallback(
    (event) => {
      const sorter = event.value;
      const newState = deepClone(ctx.get());

      newState.insightsV2State.sorter = {
        field: sorter.field,
        rev: sorter.direction === "desc",
      };

      ctx.set(newState);
    },
    [ctx]
  );

  const prepareCustomColumn = useCallback(
    (colField) => {
      return {
        title: tagsDictionary[colField].label,
        field: colField,
        formatter: customColformatter,
        hozAlign: "left",
        titleFormatter: formatEditableCellTitle,
        // headerClick: prepareHeaderClickTapListener(sorterCallback),
        sorter: (a, b, rowA, rowB) => {
          const aData = rowA.getData()?.tags?.[colField]?.[0];
          const bData = rowB?.getData().tags?.[colField]?.[0];

          const aValue =
            tagsDictionary[colField].field != null
              ? taxonResolver(aData, colField)?.name ?? ""
              : aData;
          const bValue =
            tagsDictionary[colField].field != null
              ? taxonResolver(bData, colField)?.name ?? ""
              : bData;

          if (aValue > bValue) {
            return 1;
          } else if (aValue < bValue) {
            return -1;
          }

          return 0;
        },
      };
    },
    [customColformatter, formatEditableCellTitle, tagsDictionary, taxonResolver]
  );

  const columns: any = useMemo(() => {
    const analyticsColWidth = "10%";

    const width = {
      type: "10%",
      name: "20%",
      strategyPerf10: analyticsColWidth,
      benchmarkPerf10: analyticsColWidth,
      strategyPerf3: analyticsColWidth,
      benchmarkPerf3: analyticsColWidth,
      strategyPerf1: analyticsColWidth,
      benchmarkPerf1: analyticsColWidth,
      sharpe: analyticsColWidth,
    };
    const columns =
      ctx.get().usedIn !== "RISE"
        ? [
            {
              field: "name",
              title: "Selection Criteria",
              formatter: nameFormatter,
              minWidth: width.name,
              sorter: "string",
            },
            {
              field: "type",
              title: "Type",
              width: width.type,
              hozAlign: "left",
              sorter: "string",
              titleFormatter: formatEditableCellTitle,
            },
            {
              title: "10 YEARS RETURN",
              columns: [
                {
                  field: "year_10_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  sorter: "number",
                  minWidth: width.strategyPerf10,
                },

                {
                  field: "year_10_perf_DIFF",
                  title: `Excess Return </br> vs benchmark`,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.benchmarkPerf10,
                  formatter: renderCell,
                },
              ],
            },
            {
              title: "3 YEARS RETURN",
              columns: [
                {
                  field: "year_3_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.strategyPerf3,
                },

                {
                  field: "year_3_perf_DIFF",
                  title: `Excess Return <br> vs benchmark`,
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.benchmarkPerf3,
                },
              ],
            },
            {
              title: "1 YEAR RETURN",
              columns: [
                {
                  field: "year_1_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.strategyPerf1,
                },

                {
                  field: "year_1_perf_DIFF",
                  title: `Excess Return <br> vs benchmark`,
                  headerHozAlign: "right",
                  sorter: "number",
                  formatter: renderCell,
                  minWidth: width.benchmarkPerf1,
                },
              ],
            },
            {
              title: "",

              columns: [
                {
                  field: "sharpeRatio",
                  title: "Sharpe Ratio",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.sharpe,
                },
              ],
            },
          ]
        : [
            {
              field: "name",
              title: "Selection Criteria",
              formatter: nameFormatter,
              sorter: "string",
              minWidth: width.name,
            },
            {
              field: "type",
              title: "Type",
              width: width.type,
              sorter: "string",
              hozAlign: "left",
              titleFormatter: formatEditableCellTitle,
            },
            {
              title: "10 YEARS RETURN",
              columns: [
                {
                  field: "year_10_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  sorter: "number",
                  minWidth: width.strategyPerf10,
                },

                {
                  field: "year_10_perf_DIFF",
                  title: `Excess Return </br> vs benchmark`,
                  headerHozAlign: "right",
                  sorter: "number",
                  minWidth: width.benchmarkPerf10,
                  formatter: renderCell,
                },
              ],
            },
          ];

    if (customColField !== "type") {
      const customCol: any = prepareCustomColumn(customColField);

      columns.splice(1, 1, customCol);
    }

    return columns;
  }, [
    ctx,
    customColField,
    formatEditableCellTitle,
    nameFormatter,
    prepareCustomColumn,
    renderCell,
  ]);

  const getAnalytic = useCallback(
    (obj: any, key: keyof typeof ANALYTICS_DICT) => {
      const analyticValue = obj?.analytics?.[ANALYTICS_DICT?.[key]] ?? null;

      if (analyticValue) {
        return analyticValue;
      }

      return null;
    },
    []
  );

  const getName = useCallback((strategy) => {
    return strategy.label.join("</br>");
  }, []);

  const getType = useCallback((strategy) => {
    let type = "";

    if (strategy?.type != null && strategy?.type.length > 0) {
      type = strategy.type.join(" + ");
    }

    return type;
  }, []);

  const wrapData = useCallback(() => {
    const rows: {
      name: string;
      nameUnformatted: string;
      json: any;
      year_3_perf_H: number | null;
      year_3_perf_DIFF: number | null;
      year_10_perf_H: number | null;
      year_10_perf_DIFF: number | null;
      year_1_perf_H: number | null;
      year_1_perf_DIFF: number | null;
      sharpeRatio: number | null;
      type: string;
      tags: any;
    }[] = [];

    const strategies = data;

    for (const strategy of strategies!) {
      rows.push({
        name: getName(strategy),
        nameUnformatted: strategy.name,
        json: strategy.json,
        type: getType(strategy),
        year_3_perf_H: getAnalytic(strategy, "year_3_perf_H"),
        year_3_perf_DIFF: getAnalytic(strategy, "year_3_perf_DIFF"),
        year_10_perf_H: getAnalytic(strategy, "year_10_perf_H"),
        year_10_perf_DIFF: getAnalytic(strategy, "year_10_perf_DIFF"),
        year_1_perf_DIFF: getAnalytic(strategy, "year_1_perf_DIFF"),
        year_1_perf_H: getAnalytic(strategy, "year_1_perf_H"),
        sharpeRatio: getAnalytic(strategy, "sharpeRatio"),
        tags: strategy.tags,
      });
    }

    return rows;
  }, [data, getAnalytic, getName, getType]);

  const formattedData = useMemo(() => wrapData(), [wrapData]);

  const closeMenu = useCallback(() => setAnchorEl(undefined), []);

  const handleChangeColumn = useCallback(
    ({ label, field }: { label: string; field: string }) => {
      const currentState = deepClone(ctx.get());
      currentState.insightsV2State.selectedColumn = field;
      ctx.set(currentState);

      setCustomColField(field);
      closeMenu();
    },
    [closeMenu, ctx]
  );

  const tableSetup: any = useMemo(() => {
    return {
      tableOption: {
        layout: "fitData",
      },
    };
  }, []);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      onTableBuilt: () =>
        setTableStatus((current) => ({ ...current, built: true })),
      onTableDestroyed: () =>
        setTableStatus((current) => ({ ...current, built: false })),
      columnsLoaded: (columns) => {
        if (columns.length) {
          setTableStatus((current) => ({ ...current, columns: true }));
        }
      },
      rowClick: onRowClick,
      headerSort: ({ field, direction }) => {
        sorterCallback({
          type: "sort",
          value: {
            direction: direction,
            field: field,
          },
        });
      },
    };
  }, [onRowClick, sorterCallback]);

  useEffect(() => {
    // Insert Columns
    if (tableStatus.built) {
      const table = tableRef.current?.getInstance();
      table?.insertColumns(columns);
      const initialSort = getDefaultSorter();

      table?.sort(initialSort.field, initialSort.direction as any);
    }
  }, [columns, getDefaultSorter, tableStatus.built]);

  useEffect(() => {
    if (tableReady) {
      const table = tableRef.current?.getInstance();
      table?.insertData(formattedData);
    }
  }, [formattedData, tableReady]);

  const MemoizedTable = useMemo(() => {
    return (
      <TrendratingTableV2
        ref={tableRef}
        tableOptions={tableSetup}
        tableEvents={tableEvents}
      />
    );
  }, [tableEvents, tableSetup]);

  return (
    <Card sx={{ width: "100%", height: "100%", display: "flex" }}>
      <Menu
        open={openEditMenu}
        anchorEl={anchorEl}
        elevation={3}
        onClose={closeMenu}
      >
        {dynamicColumnsList.map((option) => {
          return (
            <MenuItem
              key={uuidv4()}
              disabled={option.field === customColField}
              onClick={() => handleChangeColumn(option)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <CardContent
        sx={{
          paddingY: 1,
          flex: 1,
          minWidth: 0,
          height: "100%",
          display: "flex",
        }}
      >
        {MemoizedTable}
      </CardContent>
    </Card>
  );
};

class PDF {
  rawData: DashboardStrategies[];
  environment: AppEnvironment;
  getWysiwyg: () => {
    sorter: { field: string; direction: "desc" | "asc" };
    selectedColumn: string;
    tagsDictionary: any;
    activeFilters: any;
  };
  formatter: Formatter;
  reportAPI: Report;
  widgets: any;

  constructor(
    jsonData: DashboardStrategies[],
    environment: AppEnvironment,
    getUIParams: () => {
      sorter: { field: string; direction: "desc" | "asc" };
      selectedColumn: string;
      tagsDictionary: any;
      activeFilters: any;
    }
  ) {
    this.rawData = jsonData;
    this.environment = environment;
    this.getWysiwyg = getUIParams;
    this.formatter = new Formatter(this.environment);
    this.reportAPI = new Report(this.environment);
    const benchmarkName = "Benchmark";
    const customColumnField = this.getWysiwyg().selectedColumn;
    const customColumnName = this.resolveCustomColumnField(customColumnField);

    this.widgets = {
      title: {
        data: {
          text: "Strategy Insights",
        },
        type: "title",
      },
      pageBreak: {
        data: null,
        type: "pageBreak",
      },
      space: {
        data: {
          text: "<br/><br/>",
        },
        type: "text",
      },
      tableTitle: {
        data: {
          text: "SCREENING",
        },
        type: "header1",
      },
      table: {
        data: {
          colWidths: [4, null, null, null, null, null, null, null, null],
          body: [[]],
          head: [
            [
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "10 Years" },
              { style: null, value: "" },
              { style: null, value: "3 Years" },
              { style: null, value: "" },
              { style: null, value: "1 Year" },
              { style: null, value: "" },
              { style: null, value: "" },
            ],
            [
              {
                style: { width: "1000px" },
                value: "Name",
              },
              {
                style: null,
                value: customColumnName,
              },
              {
                style: null,
                value: "Annualized return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Annualized return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Sharpe Ratio",
              },
            ],
          ],
        },
        type: "table",
      },
      disclaimer: {
        data: {
          text: "",
        },
        type: "text",
      },
    };
  }

  resolveCustomColumnField(field) {
    const dictionary = this.getWysiwyg().tagsDictionary;

    return dictionary?.[field]?.label ?? field;
  }

  getUserPreference() {
    const preferences =
      this.environment.account.user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = themeBlueAndGrey;

    return userPreference;
  }

  async preparePrintParams() {
    let hasCover = false;
    let title = "Insights on key fundamental parameters";

    const fileName = "Trendrating - " + title.replace("/", "_") + ".pdf";

    const params = {
      content: await this.buildReportContent(),
      cover: hasCover,
      fileName: fileName,
      meta: {
        author: "Trendrating",
        title: title,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      userPreference: this.getUserPreference(),
    };

    return params;
  }

  async buildReportContent() {
    const content: any = [];

    this.addSpace(content);
    this.addTitle(content);
    this.addSpace(content);
    this.addTable(content);
    this.addSpace(content);
    await this.addDisclaimer(content);

    return content;
  }

  addPageBreak(content) {
    content.push(this.widgets.pageBreak);
  }

  addSpace(content) {
    content.push(deepClone(this.widgets.space));
  }

  addTitle(content) {
    const titleWget = deepClone(this.widgets.title);

    const wysiwyg = this.getWysiwyg();

    const filters = wysiwyg.activeFilters;

    const marketFilters = filters?.country ?? null;
    const sectorFilters = filters?.sector ?? null;

    if (marketFilters != null && marketFilters.length) {
      const countries = this.filtersToNames(marketFilters, "country");

      titleWget.data.text += " - (" + countries.join(", ") + ")";
    } else {
      titleWget.data.text += " - (Any market)";
    }

    if (sectorFilters != null && sectorFilters.length) {
      const sectors = this.filtersToNames(sectorFilters, "sector");

      titleWget.data.text += " - (" + sectors.join(", ") + ")";
    } else {
      titleWget.data.text += " - (Any sector)";
    }

    content.push(titleWget);
  }

  filtersToNames(filters: string[], taxonField: "country" | "sector") {
    const taxonomies = this.environment.taxonomies;
    const fields = this.environment.taxonomyFields;
    const taxonMap = taxonomies[fields["security"][taxonField]];

    let result: string[] = [];
    let node: any = null;

    for (const id of filters) {
      node = taxonMap?.[id];

      if (node) {
        result.push(node?.name ?? "");
      }
    }

    return result;
  }

  addSubTitle(content) {
    content.push(deepClone(this.widgets.subtitle));
  }

  addTableTitle(content, label) {
    const tableTitleWget = deepClone(this.widgets.tableTitle);

    tableTitleWget.data.text = label;

    content.push(deepClone(tableTitleWget));
  }

  addTable(content) {
    const data = this.rawData;
    const wysiwyg = this.getWysiwyg();

    if (data) {
      let tableBody: any = [];
      let row: any = [];
      let tableWget: any = null;

      tableWget = deepClone(this.widgets.table);
      const customColumnName = this.resolveCustomColumnField(
        wysiwyg.selectedColumn
      );
      // Update column field on prind based on what selected
      tableWget.data.head[1][1]["value"] = customColumnName;
      tableBody = [];
      const strategies = data.map((strategy) => this.wrapData(strategy));

      this.applySort(strategies);

      for (const strategy of strategies) {
        row = [];

        row.push({ style: null, value: this.nameFormatter(strategy.name) });
        row.push({ style: null, value: strategy[wysiwyg.selectedColumn] });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_10_perf_H),
        });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_10_perf_DIFF),
        });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_3_perf_H),
        });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_3_perf_DIFF),
        });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_1_perf_H),
        });
        row.push({
          style: null,
          value: this.formatPerf(strategy.year_1_perf_DIFF),
        });
        row.push({
          style: null,
          value: this.formatSharpe(strategy.sharpeRatio),
        });

        tableBody.push([...row]);
      }

      tableWget.data.body = [...tableBody];
      content.push(deepClone(tableWget));

      this.addPageBreak(content);
    }
    // }
  }

  async addDisclaimer(content) {
    const disclaimerWget = deepClone(this.widgets.disclaimer);

    let disclaimerText: any = this.getUserPreference().disclaimer;

    if (disclaimerText == null) {
      disclaimerText = await this.reportAPI.disclaimer();
    }

    disclaimerWget.data.text = disclaimerText;

    content.push(disclaimerWget);
  }

  getAnalytic(obj: any, key: keyof typeof ANALYTICS_DICT) {
    const analyticObj = obj?.analytics?.[ANALYTICS_DICT?.[key]] ?? null;

    return analyticObj ?? null;
  }

  nameFormatter(name) {
    //#endregion

    const nameSplitted = name.split("__");

    if (nameSplitted?.[1] != null) {
      return `${nameSplitted[0]} ${nameSplitted[1]}`;
    }

    return nameSplitted[0];
  }

  formatPerf(value) {
    return this.formatter.custom("number", {
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
    });
  }

  formatSharpe(value) {
    return this.formatter.custom("number", {
      options: {
        notAvailable: {
          input: null,
          output: "",
        },
        decimals: 4,
      },
      output: "HTML",
      value: value,
    });
  }

  getName(strategy) {
    return strategy.label.join("<br/>");
  }

  getType(strategy) {
    let type = "";

    if (strategy?.type != null && strategy?.type.length > 0) {
      type = strategy.type.join(" + ");
    }

    return type;
  }

  wrapData(strategy) {
    const row: {
      name: string;
      year_3_perf_H: number | null;
      year_3_perf_DIFF: number | null;
      year_10_perf_H: number | null;
      year_10_perf_DIFF: number | null;
      year_1_perf_H: number | null;
      year_1_perf_DIFF: number | null;
      sharpeRatio: number | null;
      type: string;
      fundamentals: string;
      country: string;
      sector: string;
    } = {
      name: "",
      year_3_perf_H: null,
      year_3_perf_DIFF: null,
      year_10_perf_H: null,
      year_10_perf_DIFF: null,
      year_1_perf_H: null,
      year_1_perf_DIFF: null,
      sharpeRatio: null,
      type: "",
      fundamentals: "",
      country: "",
      sector: "",
    };

    row["name"] = this.getName(strategy);
    row["type"] = this.getType(strategy);
    row["year_3_perf_H"] = this.getAnalytic(strategy, "year_3_perf_H");
    row["year_3_perf_DIFF"] = this.getAnalytic(strategy, "year_3_perf_DIFF");
    row["year_10_perf_H"] = this.getAnalytic(strategy, "year_10_perf_H");
    row["year_10_perf_DIFF"] = this.getAnalytic(strategy, "year_10_perf_DIFF");
    row["year_1_perf_H"] = this.getAnalytic(strategy, "year_1_perf_H");
    row["year_1_perf_DIFF"] = this.getAnalytic(strategy, "year_1_perf_DIFF");
    row["sharpeRatio"] = this.getAnalytic(strategy, "sharpeRatio");
    row["country"] = strategy?.["tags"]?.["country"]
      ? this.getTaxonmy(strategy["tags"]["country"], "country")
      : "";
    row["fundamentals"] = strategy?.["tags"]["fundamentals"].join("<br>") ?? "";
    row["sector"] = strategy?.["tags"]?.["sector"]
      ? this.getTaxonmy(strategy["tags"]["sector"], "sector")
      : "";

    return row;
  }

  getTaxonmy(value, field) {
    const taxonomies = this.environment.taxonomies;
    const fields = this.environment.taxonomyFields;

    const taxonMap = taxonomies[fields["security"][field]];

    if (value.length) {
      return value.map((id) => taxonMap?.[id]?.name ?? "-").join("<br>");
    }

    return "";
  }

  async print() {
    try {
      const params = await this.preparePrintParams();

      const report = this.reportAPI;

      const response = await report.print(params);
      this.download(response, params.fileName);
    } catch (error: any) {
      console.log(error);
    }
  }

  download(response, fileName) {
    const blob = new Blob([response], {
      type: "application/pdf",
    });

    let downloadLink: HTMLAnchorElement | null = null;

    if ((navigator as any).msSaveBlob) {
      // IE 11
      downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", "#" + fileName);
      document.body.appendChild(downloadLink);

      downloadLink.addEventListener(
        "click",
        function (event) {
          (navigator as any).msSaveBlob(blob, fileName);
        },
        false
      );
    } else {
      downloadLink = document.createElement("a");

      const attributes = [
        { attr: "class", value: "a11y" },
        { attr: "href", value: window.URL.createObjectURL(blob) },
        { attr: "target", value: "_blank" },
      ];

      attributes.forEach((attribute) => {
        downloadLink?.setAttribute(attribute.attr, attribute.value);
      });

      document.body.appendChild(downloadLink);

      downloadLink.click();
      downloadLink.remove();
    }
  }

  applySort(target) {
    let sortField = "name";
    let direction = "desc";

    const wysiwyg = this.getWysiwyg();

    const sorter = wysiwyg?.sorter ?? null;

    if (sorter) {
      sortField = sorter.field;
      direction = sorter.direction;

      target.sort((a, b) => {
        a = a[sortField];
        b = b[sortField];

        if (a > b) {
          return direction === "asc" ? 1 : -1;
        } else if (a < b) {
          return direction === "asc" ? -1 : 1;
        }

        return 0;
      });
    }
  }
}
