import { Box, Card, CardContent } from "@mui/material";
import { Analytics } from "../Analytics/Analytics";
import KeyFacts from "../KeyFacts/KeyFacts";

type ProductKeyFactsProps = {
  keyFactsProps: {
    performance: {
      cumulative: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      annualized: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      yearlyAverage: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriods: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    risk: {
      maxDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      avgYearlyDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      monthlyStdDev: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      maxConsecutiveLoosingPeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    keyRatios: {
      avgTurnover: {
        strategy: number;
        benchmark: number | null;
        delta: null | number;
      };
      sharpeRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sterlingRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sortinoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      beta: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      trackingError: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      infoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      treynorRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      percentagePositivePeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      winningAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
    };
    period: string;
    hasBenchmark: boolean;
  };
  startDate?: number;
  endDate: number;
  onChangePeriod: (period) => any;

  analyticsProps: any;
};

export function ProductKeyFacts({
  analyticsProps,
  keyFactsProps,
  startDate,
  endDate,
  onChangePeriod,
}: ProductKeyFactsProps) {
  return (
    <Box display={"flex"} gap={1} minHeight={0} height={"100%"}>
      <Box flex={1} display={"flex"}>
        <KeyFacts
          showChart={false}
          value={keyFactsProps}
          onChangePeriod={onChangePeriod}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>
      <Box
        flex={2}
        display={"flex"}
        p={1}
        paddingLeft={0}
        minWidth={0}
        minHeight={0}
      >
        <Card
          sx={{
            flex: 1,
            display: "flex",
            minWidth: 0,
            minHeight: 0,
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              display: "flex",
              minWidth: 0,
              minHeight: 0,
            }}
          >
            <Analytics data={analyticsProps} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
