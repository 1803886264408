import { Box } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import DndList from "../../../../components/DragAndDrop/DndList";
import { deepClone } from "../../../../deepClone";
import SettingsFactory from "./ComponentFactory";
type Props = {
  listContentObjBuilder: any;
  columnsAvailable: any;
  setter: Function;
  wysiwygState: any;
  addField: Function;
  isCustomizing: boolean;
  fullHeightBg?: boolean;
};

export default function DragAndDropList({
  listContentObjBuilder,
  columnsAvailable,
  setter,
  wysiwygState,
  addField,
  isCustomizing,
  fullHeightBg = false,
}: Props) {
  const operativeContentObjBuilder = useMemo(
    () => listContentObjBuilder,
    [listContentObjBuilder]
  );

  const updateOperativeContentObjBuilder = useCallback(
    (val) => {
      let temp = deepClone(val);
      temp.forEach((element, index) => {
        if (element.widgetValue["type"] == null) {
          element.widgetValue["type"] = element.widgetType;
        }
        element.widgetValue["id"] = index;
      });
      setter(temp);
    },
    [setter]
  );

  const [state, setState] = useState(
    widgetFactory(
      operativeContentObjBuilder,
      columnsAvailable,
      updateOperativeContentObjBuilder,
      wysiwygState,
      isCustomizing
    )
  );

  return (
    <Box
      sx={{
        // backgroundColor: "#f2f2f2",
        height: fullHeightBg ? "100%" : "auto",
      }}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      overflow={"auto"}
      paddingY={1}
      paddingX={2}
    >
      <Box sx={{ boxShadow: 5, padding: 2 }}>
        <DndList
          addField={addField}
          listcustomStyle={{ listItempPadding: 5 }}
          isDragable={isCustomizing}
          showHandlers={isCustomizing}
          isSortable={isCustomizing}
          setOutterState={(val) => {
            const arr = val.map((item) => item.ref.current.getState());
            const tempState = state.map((item) => item.props.configObj);
            if (JSON.stringify(tempState) !== JSON.stringify(arr)) {
              setState(
                widgetFactory(
                  arr,
                  columnsAvailable,
                  updateOperativeContentObjBuilder,
                  wysiwygState,
                  isCustomizing
                )
              );
              updateOperativeContentObjBuilder(arr);
            }
          }}
          listContentObjBuilder={state}
        />
      </Box>
    </Box>
  );
}

const widgetFactory = (
  listContentObjBuilder,
  columnsAvailable,
  stateSetter: Function,
  wysiwygState,
  isCustomizing: boolean
) => {
  // widgetFactory returns an array of widgets
  let baseURL = window.location.origin;
  let arrElements = listContentObjBuilder.map((element, index) => (
    <Widget
      settingsEnabled={isCustomizing}
      ref={React.createRef()}
      key={index}
      imgURL={baseURL + element["fieldPreview"]["imageUrl"]}
      label={element.widgetLabel}
      configObj={element}
      wysiwygState={wysiwygState}
      columnsAvailable={columnsAvailable}
      updateOutterState={(obj) => {
        const temp = deepClone(listContentObjBuilder);
        temp[index] = obj;
        stateSetter(temp);
      }}
    />
  ));
  return arrElements;
};

type WydgetProps = {
  imgURL: string;
  label: string;
  configObj: any;
  wysiwygState: any;
  updateOutterState: Function;
  columnsAvailable: any;
  settingsEnabled: boolean;
};
const Widget = forwardRef(
  (
    {
      imgURL,
      label,
      configObj,
      columnsAvailable,
      updateOutterState,
      wysiwygState,
      settingsEnabled,
    }: WydgetProps,
    ref
  ) => {
    const [state, setState] = useState(configObj);
    useImperativeHandle(ref, () => ({
      getState: () => {
        return state;
      },
      state: state,
    }));

    useEffect(() => {
      if (JSON.stringify(state) !== JSON.stringify(configObj)) {
        updateOutterState(state);
      }
    }, [configObj, label, state, updateOutterState]);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    return state != null ? (
      <>
        <div
          style={{ position: "relative", backgroundColor: "#fff" }}
          onClick={() => {
            if (
              state.widgetValue.content != null &&
              settingsEnabled === true &&
              state.widgetType !== "REPORT_DISPERSION_OVERVIEW" &&
              state.widgetType !== "REPORT_SCREENING_DISPERSION_TABLE" &&
              state.widgetType !== "REPORT_PEER_TRENDS_TRACKER"
            ) {
              setShowSettingsModal(true);
            }
          }}
        >
          <p style={{ pointerEvents: "none" }}>{label}</p>
          {/* -- */}
          {state.widgetType === "REPORT_COMMON_DISCLAIMER" && (
            <p style={{ pointerEvents: "none" }}>
              Disclaimer can be set in your Preferences
            </p>
          )}
          {/* -- */}
          <img
            width="100%"
            style={{ pointerEvents: "none", zIndex: -1 }}
            draggable="false"
            src={`${imgURL}`}
            alt={label}
          />
        </div>
        {/***************************/}
        {/*------- SETTINGS --------*/}
        {/***************************/}
        {showSettingsModal && state != null && (
          <SettingsFactory
            setOutterState={(val) => {
              setState(val);
              setShowSettingsModal(false);
            }}
            wysiwygState={wysiwygState}
            columnsAvailable={columnsAvailable}
            onCancelFunc={() => setShowSettingsModal(false)}
            configObj={state}
            panelID={state.widgetType}
          />
        )}
        {/***************************/}
        {/***************************/}
      </>
    ) : null;
  }
);
