/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Instruments
 *
 * @summary Requests for instruments
 *
 */

import { deepClone } from "../../deepClone";
import { SectionProperty } from "../../js/trendrating-report/configuration/sectionProperties";
import { ObjectType } from "../../trendrating/core/ObjectType";
import { Instrument, StoredObjectType, Timeframe } from "../../types/Api";
import { AppEnvironment } from "../../types/Defaults";
import { _Base } from "../_Base";
import { _StoredObjects } from "../_StoredObjects";
import { endpoints } from "../endpoints";
import Highcharts from "highcharts/highstock";

type FilterParams = {
  filters?: { dimension: string; segments: any[] }[];
  ranges?: { dimension: string; segments: any[] }[];
  sort?: { dimension: string; rev: boolean }[];
  page: { page: number; rows: number };
  injestion?: {
    data: { symbol: string; value: string }[];
    field: string;
    type: string;
  };
  relations?: { domain: string[]; range: "PORTFOLIO" | "BASKET" }[];
  justInTimeTops?: { dimension: string; n: string; rev: boolean }[];
};

type ScreeningConstraints = {
  dimension: string;
  operator: "equals" | "range" | "top" | "relation";
  logicalOperator?: "not";
  segments?:
    | string[]
    | number[]
    | { [key in "<" | "<=" | ">" | ">="]: number }[];
  n?: number;
  rev?: boolean;
  transform?: {
    function: string;
    params: {
      n: number;
      trimOutlier: boolean;
      withOutlierQuantile: boolean;
    };
  };
};

export type ScreeningParams = {
  constraints: ScreeningConstraints[][];
  sort?: { dimension: string; rev: boolean }[];
  page: { page: number; rows: number };
  injestion?: {
    data: { symbol: string; value: string }[];
    field: string;
    type: string;
  };
  justInTimeTops?: { dimension: string; n: string; rev: boolean }[];
};
export class Instruments extends _Base {
  // App API     | Compute API
  // ------------|------------
  // security    | securities
  // peer        | peer
  // peerHistory | peerhistory
  static apiType = {
    security: "securities",
    peer: "peer",
    peerHistory: "peerhistory",
    cube: "securities-cube",
  } as const;

  // dependencies are not recursive, that's why rc contains rrr and its
  // dependencies (new formatter code, 2019-10-03)
  static dependecies: Record<string, readonly string[]> = {
    duration: ["lduration"],
    magnitude: ["lmagnitude"],
    rc: ["dr", "drr", "lr", "pr", "prr", "rrr"],
    rrr: ["drr", "prr"],
    upi: ["lupi"],
    vc: ["currency", "dc"],
  } as const;

  http: any;
  properties: any;
  propertiesMap: any;

  static INSTRUMENT_TYPE = {
    // UI to Server
    commodity: "Commodity",
    currency: "Currency",
    etf: "ETF",
    index: "Index",
    sector: "Sector",
    stock: "Stock",
    crypto: "Crypto",
  } as const;

  storedObject: _StoredObjects;

  // It overwrites _Base constructor because of extra initialization
  // operations are needed
  constructor(environment: AppEnvironment) {
    super(environment);

    const flattened = ObjectType.getFlattenedProperties(
      environment["properties"]
    );
    this.properties = [];
    for (const property in flattened) {
      this.properties.push({
        backendProperty: flattened[property]["backendProperty"],
        backendPropertySort: flattened[property]["backendPropertySort"],
        property: property,
        additionalFetch: flattened[property]["additionalFetch"],
      });
    }

    this.propertiesMap = {};
    for (let i = 0; i < this.properties.length; i++) {
      const item = this.properties[i];
      this.propertiesMap[item["property"]] = item;
    }

    const storedObject = new _StoredObjects(environment);
    this.storedObject = storedObject;
  }

  /**
   * Create screening templates preferences
   */
  async create(params: any, type: StoredObjectType) {
    try {
      await this.storedObject.create(params, type);
    } catch (error) {
      throw new Error("An error occured");
    }
  }

  /**
   * Updates screening templates preferences
   */
  async update(params: any, type: StoredObjectType) {
    try {
      await this.storedObject.update(params, type);
    } catch (error) {
      throw new Error("An error occured");
    }
  }

  async getScreeningTemplates(id?: number) {
    return await this.storedObject.get(id, "SCREENING");
  }

  async removeScreeningTemplate(id?: number) {
    if (!id) {
      throw new Error("Id cannot be null");
    }
    return await this.storedObject.remove({ id });
  }

  /**
   * Get alerts for instruments
   *
   * @param {object} params
   * @param {array<string>} params.timeframes - one or more of
   *      "today" (default), "lastWeek", "lastMonth" or "last3Months"
   * @param {array<string>} params.instrumentTypes - one or
   *      more of INSTRUMENT_TYPE for which fetch alerts
   */
  async alerts({
    timeframes,
    instrumentTypes,
  }: {
    instrumentTypes: (keyof typeof Instruments.INSTRUMENT_TYPE)[];
    timeframes: Timeframe[];
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.alerts;

    const _params: any = {
      filters: [],
      page: {
        page: 1,
        rows: 1,
      },
      requestIds: [],
    };
    // user preferences

    const marketsPreferences =
      this.environment.account.user?.preferences?.preferences?.screening
        ?.markets;

    if (marketsPreferences?.ids != null && marketsPreferences.ids.length > 0) {
      const taxonField = this.environment.taxonomyFields["security"]["country"];
      const taxonomies = this.environment.taxonomies;

      let rootNode: any = null;

      for (let key in taxonomies[taxonField]) {
        if (taxonomies[taxonField][key]["parent"] == null) {
          rootNode = taxonomies[taxonField][key];

          break;
        }
      }

      let segments = marketsPreferences["ids"];

      // Remove always the root node from segments
      if (rootNode != null) {
        segments = segments.filter((item) => item !== rootNode.id);
      }

      if (segments.length) {
        _params["filters"].push({
          dimension: "country",
          segments: segments,
        });
      }
    }

    for (const timeframe of timeframes) {
      const keyTimeframe = this._getAlertServerTimeframe(timeframe);
      for (const instrumentType of instrumentTypes) {
        _params["requestIds"].push(
          [
            keyTimeframe,
            Instruments.INSTRUMENT_TYPE[instrumentType],
            "Downgrades",
            "All",
            "Rows",
          ].join("_")
        );
        _params["requestIds"].push(
          [
            keyTimeframe,
            Instruments.INSTRUMENT_TYPE[instrumentType],
            "Upgrades",
            "All",
            "Rows",
          ].join("_")
        );
      }
    }

    const response = await this.preparePost(url, _params, null);
    return this._alerts(
      {
        timeframes,
        instrumentTypes,
      },
      response
    );
  }

  /**
   * Get the alternative instruments A rated (rc = 2) and a positive
   * performance since rated (pr > 0)
   *
   * @param {object} params - parameters
   * @param {object} params.instrument - the instrument
   * @param {string} params.instrument.symbol - the symbol
   * @param {string} params.instrument.type - the type
   * @param {object} params.constraints - same as filter
   * @param {object} params.top - optional, set a limit
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  alternativesTo(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.closestTo;

    const _params = {
      searchContext: deepClone(params.constraints),
      symbol: params.instrument.symbol,
      top: params.top ?? 20, // default number
      type: params.instrument.type,
    };

    return this.preparePost(url, _params, null);
  }

  // DEPRECATED
  /**
   * Retrieves instruments chart
   *
   * TODO return a Promise instead of a string
   *
   * @param {object} instrument - request parameters
   * @param {string} instrument.symbol - the instrument symbol
   *
   * @returns {string} the URI of the chart
   */
  // async chart(instrument: any) {
  //   const symbol = instrument.symbol;

  //   if (!symbol) {
  //     return;
  //   }

  //   const svg = await this.getSvgs([symbol], false);

  //   return svg[symbol];
  //   // // TODO console.log("Migrate old chart method!");
  //   // const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
  //   // const url = endPointRoot + endpoints.instruments.charts;

  //   // // TODO with new api, ts is already part of the call
  //   // const ts = `&request.preventCache=${new Date().getTime()}`;
  //   // const encodedSymbol = encodeURIComponent(instrument["symbol"]);

  //   // return `${url}?symbol=${encodedSymbol}${ts}`;
  // }

  // DEPRECATED
  // /**
  //  * Retrieves instruments chart
  //  *
  //  * @param {object} params - request parameters
  //  * @param {string} params.symbol - the instrument symbol
  //  *
  //  * @returns {Promise} a promise fulfilled with the
  //  *       handled data of the response
  //  */
  // chartAsPromise(params: any) {
  //   const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
  //   const url = endPointRoot + endpoints.instruments.charts;

  //   return this.prepareGetText(url, params, null);
  // }

  async getSvgs(symbols: string[]) {
    let svgs: any = {};

    try {
      const response = await this.fetch({
        type: "security",
        properties: [{ date: null, property: "chart" }],
        symbols,
      });

      if (response.data) {
        console.time("All");
        // Create an invisible node used to render a chart
        const chartNode = document.createElement("div");
        chartNode.style.display = "none";
        chartNode.style.width = "380px";
        chartNode.style.height = "240px";
        document.body.append(chartNode);

        const color = {
          default: "#2F7ED8",
          // blue

          A: "#008000",
          // green
          B: "#8bbc00",
          // light-green
          C: "#f48400",
          // orange
          D: "#f00000",
          // red
          equity: "#0da760",
          // green              blue #3da0ff
          benchmark: "#4572a7",
          // blue-dark
          security: "#f48400",
          // orange
          yearUp: "#FAF2CB", // light-yellow
        };

        const chart = new Highcharts.StockChart({
          xAxis: {
            //tickPixelInterval : 1,
            lineWidth: 1,
            tickWidth: 1,
            showFirstLabel: true,
            startOnTick: false,
            endOnTick: false,
            labels: {
              /*formatter : function() {
          return Highcharts.dateFormat("%b '%y", this.value);
            },*/
              enabled: true,
              style: {
                fontSize: "10px",
              },
            },
          },
          yAxis: [
            {
              id: "yCurve",
              lineWidth: 1,
              tickWidth: 1,
              //lineColor : 'transparent',
              tickPixelInterval: 40,
              gridLineColor: "transparent",
              showLastLabel: true,
              opposite: false,
              startOnTick: false,
              endOnTick: false,
              labels: {
                enabled: true,
                //x : -3,
                style: {
                  fontSize: "10px",
                },
              },
            },
          ],

          chart: {
            height: 240,
            width: 380,
            renderTo: chartNode,
            animation: false,

            //marginBottom : 0, // The margin between the bottom outer edge of the chart and the plot area. Use this to set a fixed pixel value for the margin as opposed to the default dynamic margin.
            spacingBottom: 5,
            spacingRight: 20,
            spacingLeft: 5,
            spacingTop: 20,
          },
          rangeSelector: {
            enabled: false,
          },
          navigator: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },

          tooltip: {
            enabled: false,
          },
          scrollbar: {
            enabled: false,
          },
          plotOptions: {
            series: {
              animation: false,
              marker: {
                enabled: true,
              },

              enableMouseTracking: false,
            },
          },

          series: [
            {
              name: "STOCK1",
              id: "STOCK1-ID",
              data: [],
              // predefined JavaScript array
              marker: {
                enabled: false,
              },
              color: color["default"],
              lineWidth: 1,
              type: "line",
            },
            {
              type: "flags",
              data: [],
              onSeries: "STOCK1-ID",
              shape: "circlepin",
              width: 12,
              y: -22,
              //				fillColor : Highcharts.getOptions().colors[2],
              style: {
                // text style
                color: "white",
                fontSize: "10px",
                "vertical-align": "middle",
              },
              color: color["default"],
            },
          ],
        });

        let graph: any = null;
        let value: any = null;

        for (let i = 0, N = response.data.length; i < N; i++) {
          console.time("single");
          value = response.data[i];
          graph = value?.chart;

          if (!graph) {
            continue;
          } else {
            graph?.data?.sort((arrA, arrB) => {
              if (arrA[0] > arrB[0]) {
                return 1;
              } else if (arrA[0] > arrB[0]) {
                return -1;
              }

              return 0;
            });
            graph?.markers?.sort((a, b) => {
              if (a["x"] > b["x"]) {
                return 1;
              } else if (a["x"] > b["x"]) {
                return -1;
              }

              return 0;
            });
          }

          chart.series[0].update({ data: graph.data } as any, false);
          if (graph.markers) {
            for (let index = 0; index < graph.markers.length; ++index) {
              var markerH = graph.markers[index];
              markerH.text = 'Shape: "circlepin"';
              markerH.fillColor = color[markerH.title];
            }
            chart.series[1].update({ data: graph.markers } as any, false);
          } else {
            chart.series[1].update({ data: [] } as any, false);
          }
          chart.redraw();

          const svg = chart.getSVG();

          console.timeEnd("single");
          svgs[value.symbol] = svg;
        }

        document.body.removeChild(chartNode);

        console.timeEnd("All");
        return svgs;
      }
    } catch (error) {
      console.log(error);

      return svgs;
    }
  }

  /**
   * Adds to the fetch properties the fields that are requiref from the formatters
   *
   * @param {object}   params - request parameters
   *
   * @returns {Array} fields to be fetched
   */
  addDependenciesFetch(properties) {
    if (!properties) {
      return undefined;
    }
    // Used by formatter
    let hasTypeProperty = false;
    let q = [...properties];
    let f, a, i, N, j, M;
    const propertiesMap = this.propertiesMap;

    for (i = 0, N = properties.length; i < N; i++) {
      f = properties[i].property;
      if (f === "type") {
        hasTypeProperty = true;
      }

      if (propertiesMap?.[f] != null) {
        if (propertiesMap[f].additionalFetch !== undefined) {
          for (j = 0, M = propertiesMap[f].additionalFetch.length; j < M; j++) {
            a = propertiesMap[f].additionalFetch[j];
            if (q.indexOf(a) < 0) {
              q.push({ date: propertiesMap[a]["date"] ?? null, property: a });
            }
          }
        }
      }
    }

    if (hasTypeProperty === false) {
      // add type if not available (used by formatter)
      q.push({
        date: null,
        property: "type",
      });
    }

    return q;
  }

  /**
   * Retrieves instrument data according to given properties
   *
   * If params.type is peer only params.peers is considered.
   *
   * If params.type is instrument params.peers is ignored.
   *
   * @param {object}   params - request parameters
   * @param {object[]} params.peers - list of peers to retrieve
   * @param {object[]} params.properties - list of properties to retrieve for each symbol
   * @param {string}   params.properties[].property - name of the property, e.g rc, vc ...
   * @param {string}   params.properties[].date - compute the property value at this date (point in time) yyyy-mm-dd
   * @param {array}    params.symbols - list of instrument symbols must be retrieved
   * @param {string}   params.type - type of object, e.g. security | peer | peerHistory
   * @param {number}   params.date - date at which fetch data expressed in milliseconds, e.g. 11344
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async fetch(
    {
      peers,
      properties,
      symbols,
      type,
      date,
      multiDates,
    }: {
      peers?: any[];
      properties: SectionProperty[];
      symbols?: string[];
      type: keyof typeof Instruments.apiType;
      date?: number;
      multiDates?: number[];
    },
    noMergeProperties?: boolean
  ): Promise<Instrument> {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.details;

    let resolvedProperties: any = noMergeProperties
      ? properties
      : this.addDependenciesFetch(properties) ?? this.properties;

    // if (noMergeProperties == null || noMergeProperties === false) {
    //   resolvedProperties =
    //     properties != null
    //       ? this.resolveDependecies(properties)
    //       : this.properties;
    // } else {
    //   resolvedProperties = properties;
    // }

    const backedResolvedProperties =
      this._uiToBackendProperties(resolvedProperties);

    if (appConfig.isDebug && type != null && type !== "security") {
      this.deprecated("Instruments", type, "Peers methods");
    }

    let fetchDate = null;
    const formatter = this.environment["formatter"];

    if (date != null) {
      const formattedDate = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "-",
          },
        },
        output: "HTML",
        value: date,
        valueHelper: null,
      });

      fetchDate = formattedDate;
    }

    let multiDatesFormatted: any = null;

    if (multiDates) {
      let formatted: string[] = [];

      for (const d of multiDates) {
        const formattedDate = formatter.custom("date", {
          options: {
            notAvailable: {
              input: null,
              output: "-",
            },
          },
          output: "HTML",
          value: d,
          valueHelper: null,
        });

        formatted.push(formattedDate);
      }

      multiDatesFormatted = formatted;
    }

    let _params: any = null;
    const onDemandResult: any = [];
    switch (type) {
      case "cube":
        _params = [];

        const payload = {
          classType: "securities-cube",
          fields: backedResolvedProperties.map((p) => p.property),
          dates: multiDatesFormatted ?? [],
          symbols,
        };

        _params = [payload];

        break;

      case "peer":
      case "peerHistory":
        _params = peers;

        break;
      case "security":
      default: {
        for (const property of backedResolvedProperties) {
          const item: { date: string | null; dimension: string } = {
            date: null,
            dimension: property.property,
          };
          if ("date" in property && property.date !== undefined) {
            item.date = property.date;
          }
          onDemandResult.push(item);
        }

        const payload = {
          classType: Instruments.apiType[type],
          date: fetchDate,
          extendedRequest: {
            onDemandResult: onDemandResult,
          },
          symbol: symbols,
        };

        if (fetchDate != null) {
          payload.extendedRequest["lastAvailable"] = true;
        }

        _params = [payload];
        break;
      }
    }

    const response = await this.preparePost(url, _params, null);

    switch (type) {
      case "cube": {
        const cube = response?.data?.[0]?.cube;

        return cube;
      }
      case "peer": {
        return {
          data: response.data,
        };
      }
      case "peerHistory": {
        return {
          data: response.data,
        };
      }
      case "security":
      default: {
        return {
          data: response.data[0].rows,
        };
      }
    }
  }

  /**
   * Retrieves instrument symbols that match given criteria
   *
   * @param {object}   params - request parameters
   * @param {object[]} params.filters - filters for property values
   * @param {string}   params.filters[].dimension - the property on which filter must be applied
   * @param {object[]} params.filters[].segments - values of the filter
   * @param {object}   params.page - pagination parameters
   * @param {number}   params.page.page - page to be retrived
   * @param {number}   params.page.rows - number of items per page
   * @param {object[]} params.ranges - ranges for property values
   * @param {string}   params.ranges[].dimension - the property on which range filter must be applied
   * @param {object[]} params.ranges[].segments - values of the range
   * @param {number}   params.ranges[].segments[].max - maximum value
   * @param {number}   params.ranges[].segments[].min - minimum value
   * @param {object[]} params.relations - domain from which instruments must be retrieved
   * @param {array}    params.relations[].domain - ids of domains
   * @param {string}   params.relations[].range - type of domains, e.g. 'PORTFOLIO' or 'BASKET'
   * @param {object}   params.sort - sorting parameters
   * @param {string}   params.sort.dimension - property to sort by
   * @param {boolean}  params.sort.rev - if true sort descending. Default false
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async filter(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.subsets;

    const backendParams = this._constraintsUiToBackendPropertySort(params);

    // const constraintsSimulateJustInTimeTops =
    //     this._constraintsSimulateJustInTimeTops(backendParams);

    // //
    // // 2020-04-23 At the moment Compute API does not support
    // // justInTimeTops in select endpoint
    // //
    // // if backendParams contains justInTimeTops constraint an additional
    // // intermediate request is needed
    // //
    // if (constraintsSimulateJustInTimeTops != null) {
    //     const responseJustInTime = await this.preparePost(
    //         url,
    //         constraintsSimulateJustInTimeTops,
    //         null
    //     );
    //     // Prepare response
    //     const symbols =
    //         responseJustInTime["status"] === "OK"
    //             ? responseJustInTime["data"]["ids"]
    //             : [];

    //     if (!("filters" in backendParams)) {
    //         backendParams["filters"] = [];
    //     }

    //     backendParams["filters"].push({
    //         dimension: "symbol",
    //         segments: symbols,
    //     });

    //     delete backendParams["justInTimeTops"];
    //     //backendParams["isASimulatedJustInTimeTops"] = true;
    // }

    const response = await this.preparePost(url, backendParams, null);

    /**
     * TODO - server must return the correct result
     *
     * When some params are empty, server crashes
     */
    if (response.status === "KO") {
      return this.simulateHttpSuccess({
        dataTotalCount: 0,
        data: [],
      });
    }

    return {
      dataTotalCount: response.data.total,
      data: response.data.ids,
    };
  }

  /**
   * INAUGUATION OF NEW SELECTION SERVICE
   * Implement the selection syntax of the index builder in the securities selection
   * DATE: 16/01/2023
   *
   * Retrieves instrument symbols that match given criteria
   *
   * @param {object}   params - request parameters
   * @param {object[]} params.filters - filters for property values
   * @param {string}   params.filters[].dimension - the property on which filter must be applied
   * @param {object[]} params.filters[].segments - values of the filter
   * @param {object}   params.page - pagination parameters
   * @param {number}   params.page.page - page to be retrived
   * @param {number}   params.page.rows - number of items per page
   * @param {object[]} params.ranges - ranges for property values
   * @param {string}   params.ranges[].dimension - the property on which range filter must be applied
   * @param {object[]} params.ranges[].segments - values of the range
   * @param {number}   params.ranges[].segments[].max - maximum value
   * @param {number}   params.ranges[].segments[].min - minimum value
   * @param {object[]} params.relations - domain from which instruments must be retrieved
   * @param {array}    params.relations[].domain - ids of domains
   * @param {string}   params.relations[].range - type of domains, e.g. 'PORTFOLIO' or 'BASKET'
   * @param {object}   params.sort - sorting parameters
   * @param {string}   params.sort.dimension - property to sort by
   * @param {boolean}  params.sort.rev - if true sort descending. Default false
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async screening(params: any, isExpressedInNewSyntax?: boolean) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.screening;
    let backendParams = params;

    if (isExpressedInNewSyntax == null || isExpressedInNewSyntax === false) {
      backendParams = this.convertToIndexBuilderSyntax(params);
    }

    const response = await this.preparePost(url, backendParams, null);

    /**
     * TODO - server must return the correct result
     *
     * When some params are empty, server crashes
     */
    if (response.status === "KO") {
      return this.simulateHttpSuccess({
        dataTotalCount: 0,
        data: [],
      });
    }

    return {
      dataTotalCount: response.data.total,
      data: response.data.ids,
    };
  }

  /**
   * INAUGUATION OF NEW SCREENING SERVICE
   * Implement the selection syntax of the index builder in the securities selection
   * DATE: 16/01/2023
   *
   * Grab data performing both filter and fetch in one call
   *
   * @param {object}   params - request parameters
   * @param {object}   params.page - pagination parameters
   * @param {number}   params.page.page - page to be retrived
   * @param {number}   params.page.rows - number of items per page
   * @param {object[]} params.ranges - ranges for property values
   * @param {string}   params.ranges[].dimension - the property on which range filter must be applied
   * @param {object[]} params.ranges[].segments - values of the range
   * @param {number}   params.ranges[].segments[].max - maximum value
   * @param {number}   params.ranges[].segments[].min - minimum value
   * @param {object[]} params.relations - domain from which instruments must be retrieved
   * @param {array}    params.relations[].domain - ids of domains
   * @param {string}   params.relations[].range - type of domains, e.g. 'PORTFOLIO' or 'BASKET'
   * @param {object}   params.sort - sorting parameters
   * @param {string}   params.sort.dimension - property to sort by
   * @param {boolean}  params.sort.rev - if true sort descending. Default false
   *
   * @param {string}   objectType - type of object, e.g. security | peer | peerHistory
   *
   * @param {object[]} properties - list of properties to retrieve for each symbol
   * @param {object[]} properties[].property - name of the property, e.g rc, vc ...
   * @param {object[]} properties[].date - compute the property value at this date (point in time)
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async newFilterAndFetch(
    params: any,
    objectType: "peer" | "peerHistory" | "security",
    properties: any,
    mergeDependencies?: boolean
  ) {
    const resolvedProperties =
      properties != null
        ? this.resolveDependecies(properties)
        : this.properties;

    const screeningPrams = { ...params };

    // Date is a fetch param not a screening one
    if ("date" in screeningPrams) {
      delete screeningPrams["date"];
    }

    const responseFilter = await this.screening(screeningPrams);

    const dataTotalCount = responseFilter.dataTotalCount;
    const symbols = responseFilter.data;

    const fetchParams = {
      properties:
        mergeDependencies != null && mergeDependencies === true
          ? resolvedProperties
          : properties,
      symbols: symbols,
      type: objectType,
    };

    if ("date" in params) {
      fetchParams["date"] = params.date;
    }

    const responseFetch = await this.fetch(fetchParams);

    return {
      dataTotalCount: dataTotalCount,
      data: responseFetch.data,
    };
  }

  /**
   * Grab data performing both filter and fetch in one call
   *
   * @param {object}   params - request parameters
   * @param {object}   params.page - pagination parameters
   * @param {number}   params.page.page - page to be retrived
   * @param {number}   params.page.rows - number of items per page
   * @param {object[]} params.ranges - ranges for property values
   * @param {string}   params.ranges[].dimension - the property on which range filter must be applied
   * @param {object[]} params.ranges[].segments - values of the range
   * @param {number}   params.ranges[].segments[].max - maximum value
   * @param {number}   params.ranges[].segments[].min - minimum value
   * @param {object[]} params.relations - domain from which instruments must be retrieved
   * @param {array}    params.relations[].domain - ids of domains
   * @param {string}   params.relations[].range - type of domains, e.g. 'PORTFOLIO' or 'BASKET'
   * @param {object}   params.sort - sorting parameters
   * @param {string}   params.sort.dimension - property to sort by
   * @param {boolean}  params.sort.rev - if true sort descending. Default false
   *
   * @param {string}   objectType - type of object, e.g. security | peer | peerHistory
   *
   * @param {object[]} properties - list of properties to retrieve for each symbol
   * @param {object[]} properties[].property - name of the property, e.g rc, vc ...
   * @param {object[]} properties[].date - compute the property value at this date (point in time)
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async filterAndFetch(
    params: any,
    objectType: "peer" | "peerHistory" | "security",
    properties: any,
    mergeDependencies?: boolean
  ) {
    const resolvedProperties =
      properties != null
        ? this.resolveDependecies(properties)
        : this.properties;

    const responseFilter = await this.filter(params);

    const dataTotalCount = responseFilter.dataTotalCount;
    const symbols = responseFilter.data;

    const fetchParams = {
      properties:
        mergeDependencies != null && mergeDependencies === true
          ? resolvedProperties
          : properties,
      symbols: symbols,
      type: objectType,
    };

    const responseFetch = await this.fetch(fetchParams);

    return {
      dataTotalCount: dataTotalCount,
      data: responseFetch.data,
    };
  }

  /**
   * Compute point in time analytics (or today), simplified to not update weights
   *
   * @param {object} params - point in time parameters
   * @param {object} params.symbols - list of symbols
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  // async getWeightedAnalytics({ symbols }: { symbols: string[] }) {
  //   // const dateSource = tDate.daysToIso8601(response["today"]);
  //   // const dateTarget = params.asOf;
  //   const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
  //   const url = endPointRoot + endpoints["lists"]["pointsInTime"];

  //   let positions = symbols.map((symbol) => ({
  //     S: symbol,
  //     A: 1,
  //   }));

  //   const paramsWeightedAnalytics = {
  //     params_weights: {
  //       equalWeighted: true,
  //     },
  //     params_analytics: {
  //       perfMetricMode: "active",
  //       normalize: false,
  //     },
  //     v: positions,
  //   };

  //   const response: any = await this.preparePost(
  //     url,
  //     paramsWeightedAnalytics,
  //     null
  //   );
  //   const rawList = response.data;
  //   const rawHoldings = rawList.positions;

  //   const holdings: any = [];
  //   const holdingsIndex: any = {};
  //   for (let i = 0; i < rawHoldings.length; i++) {
  //     const rawHolding = rawHoldings[i];
  //     holdings.push({
  //       symbol: rawHolding.S,
  //       weight: rawHolding.A,
  //     });
  //     holdingsIndex[rawHolding.S] = i;
  //   }

  //   const listPointInTime: any = {
  //     cardinality: null,
  //     positions: null,
  //     positionsIndex: null,
  //     statistics: null,
  //   };
  //   listPointInTime.cardinality = rawHoldings.length;
  //   listPointInTime.positions = holdings;
  //   listPointInTime.positionsIndex = holdingsIndex;
  //   listPointInTime.statistics = this._normalizeInstrumentStatistics(
  //     rawList.statistics
  //   );
  //   // 2021-11-26 New field tcr outside .statistics
  //   if ("tcr" in rawList && rawList.tcr?.fact_rc != null) {
  //     listPointInTime.statistics.tcr = {
  //       today: toInt(rawList.tcr, "fact_rc", null),
  //       yesterday: toInt(rawList.tcr, "fact_rc_t_1", null),
  //       lastMonth: toInt(rawList.tcr, "fact_rc_t_20", null),
  //       lastWeek: toInt(rawList.tcr, "fact_rc_t_5", null),
  //     };
  //   }
  //   return listPointInTime;
  // }

  _normalizeInstrumentStatistics(rawStatistics: any) {
    // 2021-11-26
    // tcr will be filled after this method, because it is not inside
    // statistics field anymore
    const statistics = {
      tcr: {
        today: null,
        yesterday: null,
        lastMonth: null,
        lastWeek: null,
      },
      cardinalityPerRating: {
        2: 0,
        1: 0,
        0: 0,
        "-1": 0,
        "-2": 0,
      },
      weightPerRating: {
        2: 0,
        1: 0,
        0: 0,
        "-1": 0,
        "-2": 0,
      },
      weightTotal: 0,
    };

    if ("totalWeight" in rawStatistics && rawStatistics.totalWeight != null) {
      statistics.weightTotal = rawStatistics.totalWeight;
    }

    if ("cardinalityPerRating" in rawStatistics) {
      const rawCardinality = rawStatistics.cardinalityPerRating;
      if ("2" in rawCardinality) {
        statistics.cardinalityPerRating["2"] = rawCardinality["2"];
      }
      if ("1" in rawCardinality) {
        statistics.cardinalityPerRating["1"] = rawCardinality["1"];
      }
      if ("0" in rawCardinality) {
        statistics.cardinalityPerRating["0"] = rawCardinality["0"];
      }
      if ("-1" in rawCardinality) {
        statistics.cardinalityPerRating["-1"] = rawCardinality["-1"];
      }
      if ("-2" in rawCardinality) {
        statistics.cardinalityPerRating["-2"] = rawCardinality["-2"];
      }
    }

    if ("weightsPerRating" in rawStatistics) {
      const rawWeights = rawStatistics.weightsPerRating;
      if ("2" in rawWeights) {
        statistics.weightPerRating["2"] = rawWeights["2"];
      }
      if ("1" in rawWeights) {
        statistics.weightPerRating["1"] = rawWeights["1"];
      }
      if ("0" in rawWeights) {
        statistics.weightPerRating["0"] = rawWeights["0"];
      }
      if ("-1" in rawWeights) {
        statistics.weightPerRating["-1"] = rawWeights["-1"];
      }
      if ("-2" in rawWeights) {
        statistics.weightPerRating["-2"] = rawWeights["-2"];
      }
    }

    return statistics;
  }

  /**
   * Retrieves the history of the instrument
   *
   * @param {object} instrument - request parameters
   * @param {string} instrument.symbol - the instrument symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async historyOf(instrument: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.histories;

    const params = {
      symbols: [instrument.symbol],
    };

    const response = await this.prepareGet(url, params, null);
    return response.data[instrument.symbol];
  }

  /**
   *
   * @param history
   * @param currencyFrom
   * @param currencyTo
   *
   * @returns the history of a security converted to a specific currency
   */
  async convertHistoryCurrency(history, currencyFrom, currencyTo) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints["instruments"]["historiesConverted"];
    const _params = {
      H: history,
      currencyFrom,
      currencyTo,
    };

    const result = await this.preparePost(url, _params, null);
    const converted = result.data;
    return converted.sort(function (a: any, b: any) {
      return a.d > b.d ? 1 : b.d > a.d ? -1 : 0;
    });
  }

  logo(instrument: any) {
    const symbol = instrument.symbol;
    const folder = symbol.charAt(0).toUpperCase();
    console.log("Remove appConfig.baseUrlLogos from logo call");
    const url = [appConfig.baseUrlLogos, folder, symbol + ".jpg"].join("/");

    const request = this.prepareGetBlob(url, null, null);

    return request.then(function (response) {
      if (response.size === 0) {
        return null;
      }

      return URL.createObjectURL(response);
    });
  }

  /**
   * Check dependencies (used for formatting purposes) among properties
   *
   * @param {object[]} properties - list of properties to retrieve for each symbol
   * @param {object[]} properties[].property - name of the property, e.g rc, vc ...
   * @param {object[]} properties[].date - compute the property value at this date (point in time)
   *
   * @returns {array} properties with dependencies
   */
  resolveDependecies(iProperties: any) {
    const dependecies = Instruments.dependecies;
    const properties = deepClone(iProperties);
    const propertiesMap: any = {};

    // create a map for fast check
    for (const property of properties) {
      propertiesMap[property.property] = property;
    }

    // check if dependencies are satisfied
    for (const dependency in dependecies) {
      if (dependency in propertiesMap) {
        for (const property of dependecies[dependency]) {
          if (!(property in propertiesMap)) {
            properties.push({
              date: propertiesMap[dependency]["date"] ?? null,
              property: property,
            });
          }
        }
      }
    }

    // add type if not available (used by formatter)
    if (!("type" in propertiesMap)) {
      properties.push({
        date: null,
        property: "type",
      });
    }

    return properties;
  }

  /**
   * Search for instruments and peers in a specific universe
   *
   * @param {object}        params - request parameters
   * @param {object}        params.universe - search constraints
   * @param {string}        params.query - the query of the user
   *
   * @param {string}        objectType - type of object, e.g. security
   *      | peer | peerHistory
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async searchWithContext(
    {
      universe,
      query,
    }: {
      universe: any;
      query: string;
    },
    objectType: keyof typeof Instruments.apiType
  ) {
    if (objectType !== "security") {
      throw new Error("Use trendrating/api/compute/Peers instead");
    }

    const instrumentApiType = Instruments.apiType[objectType];
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.searches;

    const paramsSearch = {
      classType: instrumentApiType,
      fullScan: false,
      input: query,
      searchContext: universe,
    };

    const response = await this.preparePost(url, paramsSearch, null);

    const result = {
      data: response.data.ids,
      dataTotalCount: response.data.total,
    };

    return result;
  }

  /**
   * Search for instruments and peers
   *
   * @param {object}        params - request parameters
   * @param {object}        params.constraints - search constraints
   * @param {array<string>} params.constraints.instrumentType - types of
   *      instruments to search
   * @param {array<string>} params.constraints.markets - markets user
   *      is interesting in
   * @param {boolean}       params.hasDataTotalCount - if true server
   *      returns cardinality of serch result set (slow).
   *      Default false (fast)
   * @param {number}        params.page - the page to be retrieved
   * @param {number}        params.perPage - how may items per page
   * @param {string}        params.query - the query of the user
   *
   * @param {string}        objectType - type of object, e.g. security
   *      | peer | peerHistory
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async search(
    {
      constraints: { instrumentType, markets },
      hasDataTotalCount = false,
      page,
      perPage,
      query,
    }: {
      constraints: { instrumentType: string[]; markets: string[] };
      hasDataTotalCount: boolean;
      page: number;
      perPage: number;
      query: string;
    },
    objectType: keyof typeof Instruments.apiType
  ) {
    if (objectType !== "security") {
      throw new Error("Use trendrating/api/compute/Peers instead");
    }

    const instrumentApiType = Instruments.apiType[objectType];
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.instruments.searches;

    const paramsSearch = {
      classType: instrumentApiType,
      fullScan: hasDataTotalCount,
      input: query,
      searchContext: {
        filters: [
          {
            dimension: "type",
            segments: instrumentType,
          },
        ],
        page: {
          page: page,
          rows: perPage,
        },
      },
    };

    if (markets != null) {
      paramsSearch.searchContext.filters.push({
        dimension: "country",
        segments: markets,
      });
    }

    const response = await this.preparePost(url, paramsSearch, null);

    const result = {
      data: response.data.ids,
      dataTotalCount: response.data.total,
    };

    return result;
  }

  /**
   * Search and fetch info for instruments and peers
   *
   * @param {object}        params - request parameters
   * @param {object}        params.constraints - search constraints
   * @param {array<string>} params.constraints.instrumentType - types of
   *      instruments to search
   * @param {array<string>} params.constraints.markets - markets user
   *      is interesting in
   * @param {boolean}       params.hasDataTotalCount - if true server
   *      returns cardinality of serch result set (slow).
   *      Default false (fast)
   * @param {number}        params.page - the page to be retrieved
   * @param {number}        params.perPage - how may items per page
   * @param {string}        params.query - the query of the user
   *
   * @param {string}        objectType - type of object, e.g. security |
   *      peer | peerHistory
   *
   * @param {object[]}      properties - list of properties to retrieve
   *      for each symbol
   * @param {object[]}      properties[].property - name of the property
   *      e.g rc, vc ...
   * @param {object[]}      properties[].date - compute the property value
   *      at this date (point in time)
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async searchAndFetch(
    {
      constraints: { instrumentType, markets },
      hasDataTotalCount = false,
      page,
      perPage,
      query,
    }: {
      constraints: { instrumentType: string[]; markets: string[] };
      hasDataTotalCount: boolean;
      page: number;
      perPage: number;
      query: string;
    },
    objectType: any,
    properties: { date: string; property: string }[]
  ) {
    const resolvedProperties =
      properties != null
        ? this.resolveDependecies(properties)
        : this.properties;

    const response = await this.search(
      {
        constraints: { instrumentType, markets },
        hasDataTotalCount,
        page,
        perPage,
        query,
      },
      objectType
    );

    let paramsFetch: any = null;

    if (objectType === "peer") {
      paramsFetch = {
        peers: [],
        type: objectType,
      };
      for (const data of response.data) {
        const peer = {
          classType: "peer",
          country: data["country"] ?? null,
          icb: data["icb"] ?? null,
          type: "Stock",
        };
        paramsFetch["peers"].push(peer);
      }
    }

    if (objectType === "security") {
      paramsFetch = {
        properties: resolvedProperties,
        symbols: response.data,
        type: objectType,
      };
    }

    const responseFetch = await this.fetch(paramsFetch);

    return {
      dataTotalCount: response.dataTotalCount,
      data: responseFetch.data,
    };
  }

  uiToBackendProperty(uiProperty: any) {
    return this._uiToBackendProperty(uiProperty, "backendProperty");
  }

  // ----------------------------------------------------- private methods

  _alerts(
    {
      instrumentTypes,
      timeframes,
    }: {
      instrumentTypes: (keyof typeof Instruments.INSTRUMENT_TYPE)[];
      timeframes: Timeframe[];
    },
    response: any
  ) {
    const dataMap: any = {};
    for (const data of response.data) {
      dataMap[data["requestId"]] = data;
    }

    const postfix = "All_Rows";
    const _response: any = {};
    for (const instrumentType of instrumentTypes) {
      const keyServerInstrumentType =
        Instruments.INSTRUMENT_TYPE[instrumentType];

      _response[instrumentType] = {};

      for (const timeframe of timeframes) {
        const keyServerTimeframe = this._getAlertServerTimeframe(timeframe);

        const keyDowngrades = [
          keyServerTimeframe,
          keyServerInstrumentType,
          "Downgrades",
          postfix,
        ].join("_");
        const keyUpgrades = [
          keyServerTimeframe,
          keyServerInstrumentType,
          "Upgrades",
          postfix,
        ].join("_");

        _response[instrumentType][timeframe] = {
          downgrades: dataMap[keyDowngrades].total,
          upgrades: dataMap[keyUpgrades].total,
        };
      }
    }

    return _response;
  }

  //
  // if params contains a justInTimeTops property, returns params for the
  // intermediate request
  //
  _constraintsSimulateJustInTimeTops(params: any) {
    const _params = deepClone(params);

    if ("justInTimeTops" in _params) {
      if (_params.sort == null) {
        _params.sort = [
          {
            dimension: null,
            rev: false,
          },
        ];
      }

      // Fix issue if sort is not an array
      if (!Array.isArray(_params.sort)) {
        _params.sort = [_params.sort];
        if (appConfig.isDebug) {
          console.log("Wrong sort data structure: missing array");
        }
      }
      _params.sort[0].dimension = _params.justInTimeTops[0].dimension;
      _params.sort[0].rev = _params.justInTimeTops[0].rev;

      if (_params.page == null) {
        _params.page = {
          page: 1,
          rows: 25,
        };
      }
      _params.page.rows = _params.justInTimeTops[0].n;

      delete _params.justInTimeTops;

      return _params;
    }

    return null;
  }

  /**
   * Encode UI filter params (sort) to API filter params (backend)
   *
   * @param {object} params - see filter params documentation
   */
  _constraintsUiToBackendPropertySort(params: any) {
    const _params = deepClone(params);

    if ("sort" in _params) {
      for (let i = 0; i < params.sort.length; i++) {
        const sort = _params.sort[i];
        sort.dimension = this._uiToBackendProperty(
          sort.dimension,
          "backendPropertySort"
        );
      }
    }

    return _params;
  }

  _getAlertServerTimeframe(timeframe: any) {
    switch (timeframe) {
      case "last3Months":
        return "Quarter";
      case "lastMonth":
        return "Month";
      case "lastWeek":
        return "Week";
      case "today":
      default:
        return "Today";
    }
  }

  async _searchFilterFetchConstrained(
    searchResponse: { data: any[]; dataTotalCount: number },
    objectType: any,
    properties: any
  ) {
    const data = searchResponse.data;
    let dataTotalCount = searchResponse.dataTotalCount;

    switch (objectType) {
      case "peer":
        dataTotalCount = data.length;
        return {
          dataTotalCount: dataTotalCount,
          data: data,
        };
      case "security":
        const ingestion = data.map((data, index) => ({
          symbol: data,
          value: String(index),
        }));
        const property = new Date().getTime() + ":rank";
        const paramsFilter = {
          filters: [
            {
              dimension: "symbol",
              segments: data,
            },
          ],
          injestion: {
            data: ingestion,
            field: property,
            type: "number",
          },
          sort: {
            dimension: property,
            rev: false,
          },
        };

        const response = await this.filter(paramsFilter);
        const paramsFetch = {
          properties: properties,
          symbols: response.data,
          type: objectType,
        };

        const responseFetch = await this.fetch(paramsFetch);
        return {
          dataTotalCount: response.dataTotalCount,
          data: responseFetch.data,
        };
    }
  }

  /**
   *
   * @param {string} property - the UI property
   * @param {string} backend - backendProperty | backendPropertySort
   */
  _uiToBackendProperty(property: string, backend: string) {
    const propertiesMap = this.propertiesMap;

    const _property =
      propertiesMap[property] === undefined
        ? property
        : backend in propertiesMap[property]
        ? propertiesMap[property][backend]
        : property;

    return _property;
  }

  /**
   * Encode UI properties to API properties (backend)
   *
   * @param {object[]} properties
   * @param {string}   properties[].date - date ISO 8601 format
   * @param {string}   properties[].property - UI property
   */
  _uiToBackendProperties(properties: { date: string; property: string }[]) {
    const backendProperties = properties.map((property) => ({
      date: property.date,
      property: this._uiToBackendProperty(property.property, "backendProperty"),
    }));
    return backendProperties;
  }

  convertToIndexBuilderSyntax(params: FilterParams) {
    const constraintsList: any = [];
    const backendParams: ScreeningParams = {
      constraints: [],
      page: {
        page: params?.page?.page ?? 1,
        rows: params.page?.rows ?? 5000,
      },
    };

    if ("relations" in params) {
      const relations: any = params.relations?.map((rel) => ({
        dimension: rel.range,
        operator: "relation",
        segments: rel.domain.map((id) =>
          typeof id === "number" ? id : parseInt(id)
        ),
      }));

      constraintsList.push(...relations);
    }

    if ("injestion" in params) {
      backendParams.injestion = params.injestion;
    }

    if ("sort" in params) {
      let sortObject: any = params.sort;
      const properties = this.propertiesMap;

      if (!Array.isArray(sortObject)) {
        let property = (sortObject as any).dimension;

        if (
          property in properties &&
          properties[property].backendPropertySort != null
        ) {
          property = properties[property].backendPropertySort;
        }

        sortObject["dimension"] = property;
        sortObject = [sortObject];
      } else {
        sortObject = sortObject?.map(
          (sortCriteria: { dimension: string; rev: boolean }) => {
            // Graceful degradation: default use the field as sort criteria
            let property = sortCriteria.dimension;

            if (
              property in properties &&
              properties[property].backendPropertySort != null
            ) {
              property = properties[property].backendPropertySort;
            }

            return { ...sortCriteria, dimension: property };
          }
        );
      }

      backendParams.sort = sortObject;
    }

    if (params.filters) {
      const filters = params.filters;

      if (filters) {
        for (const filter of filters) {
          if (filter.segments.length) {
            const payload = {
              dimension: filter.dimension,
              operator: "equals",
              segments: filter.segments,
            };

            if ("logicalOperator" in filter) {
              payload["logicalOperator"] = filter["logicalOperator"];
            }

            constraintsList.push(payload);
          }
        }
      }
    }

    if (params.ranges) {
      const ranges = params.ranges;

      if (ranges) {
        for (const range of ranges) {
          if (range.segments.length) {
            constraintsList.push({
              dimension: range.dimension,
              operator: "range",
              segments: range.segments.map((r) => {
                const rangeDefinition: any = {};

                if ("min" in r && r["min"] != null) {
                  rangeDefinition[">="] = r["min"];
                }

                if ("max" in r && r["max"] != null) {
                  rangeDefinition["<="] = r["max"];
                }

                // ge: greater or equal
                // gt: grater than
                // le: less or equal
                // lt: less than
                if ("ge" in r && r["ge"] != null) {
                  rangeDefinition[">="] = r["ge"];
                }

                if ("gt" in r && r["gt"] != null) {
                  rangeDefinition[">"] = r["gt"];
                }

                if ("le" in r && r["le"] != null) {
                  rangeDefinition["<="] = r["le"];
                }

                if ("lt" in r && r["lt"] != null) {
                  rangeDefinition["<"] = r["lt"];
                }

                return rangeDefinition;
              }),
            });
          }
        }
      }
    }

    if ("justInTimeTops" in params) {
      const universeDefinition: any = params.justInTimeTops?.map(
        (constraint) => ({
          operator: "top",
          dimension: constraint.dimension,
          n: constraint.n,
          rev: constraint.rev,
        })
      );
      constraintsList.push(...universeDefinition);
    }

    backendParams.constraints = [constraintsList];

    return backendParams;
  }
}
