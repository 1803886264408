import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Box } from "@mui/material";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import Paginator from "../../Paginator/Paginator";
import SecurityChartModal from "../../SecurityChartModal/SecurityChartModal";
import { InstrumentsBoxListsV2 } from "./components/InstrumentsBoxListsV2/InstrumentsBoxListsV2";
import { TableEventsV2, TableParamsV2, TableV2 } from "./TableCoreV2";
import styles from "./TableV2.module.scss";
import { AddToButton } from "./Tools/AddToButton/AddToButton";
import {
  TableColumnsEditor,
  TableColumnsEditorProps,
} from "./Tools/ColumnsEditor/TableColumnsEditor";
import { RankDateSelect } from "./Tools/RankDateSelect/RankDateSelect";
import { RowNumberSelector } from "./Tools/RowNumberSelector/RowNumberSelector";
import { Search } from "./Tools/Search/Search";

type ToolsEvents = {
  onExpand?: () => void;
  onChangePage?: (page: number) => void;
  onChangeRowNumber?: (rowNmber: number) => void;
  onChangeRankDate?: (arg) => void;
  onColumnsEdit?: (columns) => void;
  onColumnsLoaded?: (columns) => void;
  onTableDestroyed?: () => void;
  handleSearchResult?: (symbol: string[]) => void;
  onClearSearch?: () => void;
  onClickCard?: (instrument) => void;
};

type TrendratingTableV2Props = {
  tools?: {
    addToButton?: boolean;
    expandTable?: {
      enabled: boolean;
      expandedIcon?: JSX.Element;
      collapsedIcon?: JSX.Element;
    };
    configurator?: Omit<TableColumnsEditorProps, "setTableColumns"> & {
      skipDefaultInsert?: boolean;
    };
    customTools?: {
      children?: JSX.Element;
    };
    search?: { enabled: boolean; renderTo?: string };
    pagination?: {
      dataTotalCount: number;
    };
    rowsNumberSelect?: {
      enabled: boolean;
      label?: string;
      initialValue?: number;
    };
    viewAsListButton?: boolean;
    rank?: {
      showRankTools: boolean;
      dateInitValue?:
        | "PREVIOUS_DAY"
        | "PREVIOUS_WEEK"
        | "PREVIOUS_2_WEEKS"
        | "PREVIOUS_MONTH"
        | "PREVIOUS_3_MONTHS";
    };
  };
  rowTooltipFormatter?: boolean;
  avgMenu?: boolean;
  checkboxOnRows?: boolean;
  showRowIndex?: boolean;
  tableEvents?: TableEventsV2;
  toolsEvents?: ToolsEvents;
  tableOptions?: Omit<TableParamsV2, "node" | "environment" | "events">;
};

const useHandlerObserver = (
  handlerFunc,
  table?: TableV2,
  eventName?: keyof TableEventsV2
) => {
  const [handler, setHandler] = useState(() => handlerFunc);

  useEffect(() => {
    setHandler(() => handlerFunc);
    if (handlerFunc && table && eventName) {
      table.registerEvents({ [eventName]: handlerFunc });
    }
  }, [eventName, handlerFunc, table]);

  return handler;
};

export const TrendratingTableV2 = memo(
  forwardRef<any, TrendratingTableV2Props>(
    (
      {
        tools,
        tableEvents = {},
        toolsEvents = {},
        rowTooltipFormatter,
        avgMenu,
        checkboxOnRows,
        showRowIndex,
        tableOptions,
      },
      ref
    ) => {
      const [tableReady, setTableReady] = useState(false);
      const [expanded, setExpanded] = useState(false);
      const [itemsPerPage, setItemsPerPage] = useState(
        tools?.rowsNumberSelect?.initialValue ?? 25
      );
      const [view, setView] = useState<"table" | "list">("table");
      const [paginatorRefreshTrigger, setPaginatorRefreshTrigger] = useState(
        Date.now()
      );
      const [searchRefreshTrigger, setSearchRefreshTrigger] = useState(
        Date.now()
      );
      const [selectedRows, setSelectedRows] = useState([]);
      const [security, setSecurity] = useState();
      const [showChartModal, setShowChartModal] = useState(false);
      const [highlightRank, setHighlightRank] = useState(false);

      const table = useRef<TableV2>();
      const nodeRef = useRef<HTMLDivElement>(null);

      const environment = useEnvironment();

      const renderTools = useMemo(() => tableReady, [tableReady]);

      // Detach handlers stored in object to avoid components props growing too much. This guarantees that handlers that changes
      // are always updated and registered singularly but most important if an handler change the others won't change if they don't
      // need it
      const expandHandler = useHandlerObserver(toolsEvents?.onExpand);
      const handleSearchResult = useHandlerObserver(
        toolsEvents?.handleSearchResult
      );
      const onClearSearch = useHandlerObserver(toolsEvents?.onClearSearch);
      const changePageHandler = useHandlerObserver(toolsEvents?.onChangePage);
      const changeRowNumHandler = useHandlerObserver(
        toolsEvents?.onChangeRowNumber
      );
      const changeRankDateHandler = useHandlerObserver(
        toolsEvents?.onChangeRankDate
      );
      const columnsEditedHandler = useHandlerObserver(
        toolsEvents?.onColumnsEdit
      );
      const onClickCardChart = useHandlerObserver(toolsEvents.onClickCard);

      const refreshWidget = useCallback((widget: string) => {
        if (widget === "paginator") {
          setPaginatorRefreshTrigger(Date.now());
        } else if (widget === "search") {
          setSearchRefreshTrigger(Date.now());
        }
      }, []);

      const hookedSort = useCallback(
        (sortInfo) => {
          if (tableEvents?.headerSort) {
            const currentSort = tableEvents.headerSort;

            currentSort(sortInfo);
          }

          refreshWidget("paginator");
        },
        [refreshWidget, tableEvents.headerSort]
      );

      const hookedChangeRowsSelection = useCallback(
        (data: any, rows: any, selected: any, deselected: any) => {
          if (tableEvents?.rowSelectionChanged) {
            const selectionHandler = tableEvents?.rowSelectionChanged;
            selectionHandler(data, rows, selected, deselected);
          }
          setSelectedRows(data);
        },
        [tableEvents?.rowSelectionChanged]
      );

      // Table Methods only has to be observed
      useHandlerObserver(hookedSort, table.current, "headerSort");
      useHandlerObserver(
        tableEvents?.onDataSorted,
        table.current,
        "onDataSorted"
      );
      useHandlerObserver(toolsEvents?.onColumnsLoaded);
      useHandlerObserver(tableEvents.rowClick, table.current, "rowClick");
      useHandlerObserver(
        tableEvents?.rowSelected,
        table.current,
        "rowSelected"
      );
      useHandlerObserver(
        tableEvents.rowMouseEnter,
        table.current,
        "rowMouseEnter"
      );
      useHandlerObserver(
        tableEvents.rowMouseLeave,
        table.current,
        "rowMouseLeave"
      );
      useHandlerObserver(
        tableEvents.rowDeselected,
        table.current,
        "rowDeselected"
      );
      useHandlerObserver(tableEvents.dataLoaded, table.current, "dataLoaded");
      useHandlerObserver(
        tableEvents.onTableDestroyed,
        table.current,
        "onTableDestroyed"
      );
      useHandlerObserver(
        hookedChangeRowsSelection,
        table.current,
        "rowSelectionChanged"
      );
      useHandlerObserver(
        tableEvents.menuHeaderClick,
        table.current,
        "menuHeaderClick"
      );
      useHandlerObserver(
        tableEvents.columnsLoaded,
        table.current,
        "columnsLoaded"
      );

      const expandTableHandler = useMemo(() => {
        if (expandHandler) {
          return () => {
            setExpanded((current) => !current);
            expandHandler();
          };
        }
      }, [expandHandler]);

      const editorColumnsChange = useCallback(
        (columns) => {
          const skipDefaultInsert =
            tools?.configurator?.skipDefaultInsert ?? false;
          if (!skipDefaultInsert)
            table.current?.insertColumns(
              columns.map((item) => ({ field: item.field }))
            );
        },
        [tools?.configurator?.skipDefaultInsert]
      );

      const onColumsEdited = useCallback(
        (columns) => {
          table.current?.insertColumns(
            columns.map((item) => ({ field: item.field }))
          );

          if (columnsEditedHandler) {
            columnsEditedHandler(columns);
          }
        },
        [columnsEditedHandler]
      );

      const onChangePage = useCallback(
        (pageNumber) => {
          refreshWidget("boxList");

          if (changePageHandler) {
            changePageHandler(pageNumber);
          }
        },
        [changePageHandler, refreshWidget]
      );

      const onRowNumberChange = useCallback(
        (rowNumber) => {
          setItemsPerPage(rowNumber);
          refreshWidget("boxList");
          refreshWidget("paginator");

          if (changeRowNumHandler) {
            changeRowNumHandler(rowNumber);
          }
        },
        [changeRowNumHandler, refreshWidget]
      );

      const onChangeRankPeriod = useCallback(
        (value) => {
          if (changeRankDateHandler) {
            changeRankDateHandler(value);
          }
        },
        [changeRankDateHandler]
      );

      const onChangeRankHighlight = useCallback(() => {
        setHighlightRank((current) => !current);
        table.current?.changeRankHighlight(!highlightRank);
      }, [highlightRank]);

      const onClickChartButtonOnTooltip = useCallback(() => {
        setShowChartModal(true);
      }, []);

      const onOpenTooltip = useCallback((value) => {
        setSecurity(value);
      }, []);

      const getPlugins = useCallback(() => {
        const tablePlugins: Partial<TableParamsV2["tableOption"]> = {};

        if (showRowIndex === true) {
          tablePlugins["showRowIndex"] = true;
        }

        if (avgMenu === true) {
          tablePlugins["enableAvgMenu"] = true;
        }

        if (checkboxOnRows === true) {
          tablePlugins["addRowCheckbox"] = true;
        }

        if (rowTooltipFormatter === true) {
          tablePlugins["tableTooltipParams"] = {
            enabled: true,
            environment,
            handleClickChart: onClickChartButtonOnTooltip,
            handleSecurityInfo: onOpenTooltip,
          };
        }

        return tablePlugins;
      }, [
        avgMenu,
        checkboxOnRows,
        environment,
        onClickChartButtonOnTooltip,
        onOpenTooltip,
        rowTooltipFormatter,
        showRowIndex,
      ]);

      const tableConfig: TableParamsV2 = useMemo(() => {
        const plugins = getPlugins();

        if (tools?.addToButton === true) {
          plugins["addRowCheckbox"] = true;
        }

        const tableParameters: TableParamsV2 = {
          node: nodeRef.current,
          environment: environment.get("setup"),
          events: {
            onTableBuilt: () => setTableReady(true),
          },
          tableOption: {
            ...tableOptions?.tableOption,
            layout: tableOptions?.tableOption?.layout ?? "fitColumns",
            ...plugins,
          },
        };

        return tableParameters;
      }, [
        environment,
        getPlugins,
        tableOptions?.tableOption,
        tools?.addToButton,
      ]);

      const renderTable = useCallback(
        (node) => {
          tableConfig["node"] = node;
          table.current = new TableV2(tableConfig);
        },
        [tableConfig]
      );

      useImperativeHandle(
        ref,
        () => ({
          getInstance: () => table.current,
          refreshSearch: () => refreshWidget("search"),
        }),
        [refreshWidget]
      );

      const toggleView = useCallback(() => {
        setView((currentView) => (currentView === "list" ? "table" : "list"));
      }, []);

      const onComponentUnmount = useCallback(() => {
        table.current?.destroy();
      }, []);

      useEffect(() => {
        return () => onComponentUnmount();
      }, [onComponentUnmount]);

      useEffect(() => {
        if (nodeRef.current) {
          table.current?.destroy();
          table.current = undefined;

          renderTable(nodeRef.current);
        }
      }, [renderTable]);

      useEffect(() => {
        if (tableReady) {
          const onTableBuilt = tableEvents?.onTableBuilt;

          if (onTableBuilt) {
            onTableBuilt();
          }
        }
      }, [tableEvents?.onTableBuilt, tableReady]);

      return (
        <Box
          flex={1}
          pb={2}
          minWidth={0}
          display={"flex"}
          flexDirection={"column"}
          sx={{ transition: "0.5s" }}
        >
          {renderTools && (
            <Box mb={1} display={"flex"} justifyContent={"space-between"}>
              <Box flex={1} gap={1} display={"flex"} alignItems={"center"}>
                {tools && "addToButton" in tools && (
                  <AddToButton
                    tableInstance={table.current!}
                    selectedRows={selectedRows}
                  />
                )}
                {tools && "expandTable" in tools && (
                  <div className="tableColumnConfigurator">
                    <button
                      className={styles.iconButtonWidget}
                      title="Expand table"
                      onClick={expandTableHandler as any}
                    >
                      {expanded
                        ? tools?.expandTable?.expandedIcon ?? (
                            <CloseFullscreenIcon sx={{ fontSize: "0.7vw" }} />
                          )
                        : tools?.expandTable?.collapsedIcon ?? (
                            <OpenInFullIcon sx={{ fontSize: "0.7vw" }} />
                          )}
                    </button>
                  </div>
                )}
                {tools && "configurator" in tools && (
                  <TableColumnsEditor
                    setTableColumns={onColumsEdited}
                    setInitCols={editorColumnsChange}
                    defaultTemplateNameBase={
                      tools.configurator!.defaultTemplateNameBase
                    }
                    hasToSkipLastApplied={
                      tools.configurator!.hasToSkipLastApplied
                    }
                    configurations={tools.configurator!.configurations}
                    securityType={tools.configurator!.securityType}
                  />
                )}
                {tools && "customTools" in tools && (
                  <>{tools.customTools?.children}</>
                )}
                {tools && "search" in tools && tools.search && (
                  <Search
                    key={searchRefreshTrigger}
                    selectRow={handleSearchResult}
                    tableInstance={table.current!}
                    onClear={onClearSearch}
                    renderNodeId={tools?.search?.renderTo}
                  />
                )}
              </Box>

              <Box flex={1} display={"flex"} alignItems={"center"}>
                {tools && "pagination" in tools && (
                  <Paginator
                    key={paginatorRefreshTrigger}
                    dataTotalCount={tools.pagination!.dataTotalCount}
                    itemsPerPage={itemsPerPage}
                    handlePaginationChange={onChangePage}
                  />
                )}
              </Box>

              <Box
                flex={1}
                justifyContent={"flex-end"}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                {tools?.rowsNumberSelect?.enabled && (
                  <RowNumberSelector
                    label={tools.rowsNumberSelect.label}
                    onChange={onRowNumberChange}
                    initialValue={tools.rowsNumberSelect.initialValue}
                  />
                )}
                {tools?.rank?.showRankTools && (
                  <>
                    <RankDateSelect
                      label={"Show Changes From:"}
                      onChange={onChangeRankPeriod}
                      dateInitValue={tools.rank.dateInitValue}
                    />
                    <div className="tableColumnConfigurator">
                      <button
                        className={
                          highlightRank
                            ? styles.iconButtonWidgetActive
                            : styles.iconButtonWidget
                        }
                        title="Highlighted view"
                        onClick={onChangeRankHighlight}
                      >
                        <span className="i-color"></span>
                      </button>
                    </div>
                  </>
                )}
                {tools?.viewAsListButton && (
                  <div className="tableColumnConfigurator">
                    <button
                      className={styles.iconButtonWidget}
                      title="Customize table fields"
                      onClick={toggleView}
                    >
                      <span className="i-chart"></span>
                    </button>
                  </div>
                )}
              </Box>
            </Box>
          )}
          <Box
            minHeight={0}
            flex={1}
            display={view === "table" ? "flex" : "none"}
            minWidth={0}
          >
            <SecurityChartModal
              security={security}
              showModal={showChartModal}
              environment={environment}
              onClose={() => setShowChartModal(false)}
            />
            <Box flex={1} minWidth={0} minHeight={0}>
              <div style={{ height: "100%" }} ref={nodeRef}></div>
            </Box>
          </Box>
          {tableReady && view === "list" && (
            <InstrumentsBoxListsV2
              tableInstance={table.current!}
              showCheckbox={tools?.addToButton ?? false}
              selectedRows={selectedRows}
              onClickCard={onClickCardChart}
            />
          )}
        </Box>
      );
    }
  )
);
